<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Ranking</p>
        <div class="container">
            <h2 class="page_header_title">Ranking</h2>
        </div>
    </div>
</div>

<div class="container">
    <div class="row align-items-center mt-5">
        <div class="col-md-4">
            <h3 class="mt-0 page-title" style="line-height: 40px">CONFIRA A CLASSIFICAÇÃO DA SUA REVENDA E FORÇA NO
                GÁS!</h3>
        </div>
        <div class="offset-md-2 col-md-5">
            <p class="text-muted">Fique atento a todos os critérios de avaliação da nossa fórmula e lembre-se que a participação de sua
                equipe nos treinamentos é fundamental para sua revenda pontuar na Mecânica de Engajamento “Time
                Preparado”.</p>
        </div>

        <div class="col-12">
            <div class="position-relative my-4">
                <div class="row h-100 mx-n1 justify-content-between align-content-center" style="row-gap: 30px">
                    <div class="px-1 position-relative" (click)="handlerTraining(x)"
                         *ngFor="let x of listOfMonths;let i = index">
                        <div class="home-training_btn" [ngClass]="{'active':x.id == rankingInput.month}">
                            <p class="home-training_lbl">{{x.name}}</p>
                            <p class="home-training_lbl home-training_lbl-sm">{{x.date}}</p>
                        </div>
                        <svg-icon class="home-training_arrow home-training_arrow-ranking"
                                  [ngClass]="{'active':x.id == rankingInput.month}"
                                  src="assets/svg/arrow-training-gray.svg"></svg-icon>
                    </div>
                </div>
                <div class="home-training_bar home-training_bar-ranking">&nbsp;</div>
            </div>
        </div>

    </div>

</div>

<div class="py-5 mb-4" style="margin-top: -6px;background-color: #efefef">
    <div class="container">
        <div class="row" style="row-gap: 20px">
            <div class="col-sm">
                <div class="engaged-ranking_card mb-4">
                    <div class="row">
                        <div class="col d-flex flex-column align-items-center">
                            <p class="engaged-ranking_lbl">Minha colocação</p>
                            <p class="engaged-ranking_value mb-0">{{rankingPosition.myPlacing}}°</p>
                        </div>
                        <div class="col d-flex flex-column align-items-center border-left border-white">
                            <p class="engaged-ranking_lbl">Total</p>
                            <p class="engaged-ranking_value mb-0">{{numberWithCommas(rankingPosition.finalScore)}}</p>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters align-items-center justify-content-between" style="gap: 15px">
                    <ng-container *ngIf="this.handlerRankingResaleExcelBool">
                        <div class="flex-grow-1">
                            <a (click)="getExcel()" class="d-flex justify-content-between btn btn-secondary">
                                <span>&nbsp;</span>
                                <span class="text-white">Exportar</span>
                                <svg-icon [svgStyle]="{'width': '14px'}" src="assets/svg/download.svg"></svg-icon>
                            </a>
                        </div>
                    </ng-container>
                    <ng-container
                            *ngIf="getModelUserView(user) === 'rv'||user.profile?.id == 'MA'||user.profile?.id == 'EC'">
                        <div class="flex-grow-1">
                            <a [routerLink]="'/ranking-detalhe'" class="mt-0 w-100 btn btn-secondary">Veja sua
                                pontuação detalhada</a>
                        </div>
                    </ng-container>

                </div>

                <div class="row my-4">
                    <div class="col text-muted">
                        <h3>Critérios de Desempate</h3>
                        <p>
                            <strong>1º Critério de Desempate:</strong> Maior pontuação no item Variação de Vendas (ΣΔV), sem o limite de 110% (cento e dez por cento).<br>
                            <strong>2º Critério de Desempate:</strong> Maior pontuação em Visita Técnica(VT).<br>
                            <strong>3º Critério de Desempate:</strong> Maior pontuação em Adimplência (Ad)
                        </p>
                        <p>
                            <strong>IMPORTANTE:</strong> A pontuação na Visita Técnica (VT) será aplicada somente ao final da campanha.
                        </p>
                    </div>
                </div>

            </div>

            <div class="col-sm offset-sm-1">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="input-material select mb-3">
                            <select [disabled]="!handlerRankingResaleBool" class="form-control"
                                    [(ngModel)]="this.rankingResaleInput.region" (ngModelChange)="onSearch()"
                                    id="region" name="region">
                                <option *ngFor="let x of regionFindAll" [value]="x.name">{{x.name}}</option>
                            </select>
                            <label class="disabled" for="region">Regiao</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-material select mb-3">
                            <select [disabled]="!handlerRankingResaleBoolRange" class="form-control"
                                    [(ngModel)]="this.rankingResaleInput.rangeId" (ngModelChange)="onSearch()"
                                    id="range" name="range">
                                <option *ngFor="let x of findAllRange" [value]="x.id">{{x.name}}</option>
                            </select>
                            <label class="disabled" for="range">Faixa</label>
                        </div>
                    </div>
                </div>
                <p *ngIf="isNullOrUndefined(rankingResales)" class="text-center py-4">Nenhum registro encontrado</p>
                <div class="engaged-ranking_list" *ngFor="let x of rankingResales">
                    <div class="row">
                        <div class="col-auto">
                            <p class="engaged-ranking_list_number">{{x.position}}º</p>
                        </div>
                        <div class="col">
                            <p class="engaged-ranking_list_name">{{x.companyName}}</p>
                        </div>
                        <div class="col-auto">
                            <p class="engaged-ranking_list_percentage">{{numberWithCommas(x.finalScore)}}</p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-12">
                <p class="text-muted">O item adimplência do Mega Desafio foi aplicado somente no mês de outubro, pois, devido a uma questão sistêmica nos demais meses não foi possível apurar o quesito. Essa condição não impacta os resultados da campanha, visto que, foi aplicada a todos de forma imparcial.</p>
            </div>
        </div>
    </div>
</div>

<div class="py-5" *ngIf="getModelUserView(user) === 'rv'">
    <div class="container">
        <div class="row" style="row-gap: 20px">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-4">
                        <svg-icon class="titleIcon" src="assets/svg/botijao.svg"></svg-icon>
                    </div>
                    <div class="col-8">
                        <h4 class="page-title">METAS DE VENDA EM KG</h4>
                    </div>
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-ranking">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"><span class="text-primary">Meta</span></th>
                                    <th scope="col"><span class="text-tertiary">Realizado</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let x of rankingResaleGoal">
                                    <td class="text-secondary">{{x.month}}</td>
                                    <td class="text-primary">{{x.goal}}</td>
                                    <td class="text-tertiary">{{x.accomplished}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

            <div class="offset-md-1 col-md-7">
                <div class="row">
                    <div class="col-4 col-md-2">
                        <svg-icon class="titleIcon" src="assets/svg/desempenho.svg"></svg-icon>
                    </div>
                    <div class="col-8">
                        <h4 class="page-title">DESEMPENHO</h4>
                    </div>
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-ranking">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col" [ngClass]="{'active' : this.indexMount == 0}">
                                        <span class="text-primary"
                                              [ngClass]="{'bg-gray' : this.indexMount != 0}">OUT</span>
                                    </th>
                                    <th scope="col" [ngClass]="{'active' : this.indexMount == 1}">
                                        <span class="text-primary"
                                              [ngClass]="{'bg-gray' : this.indexMount != 1}">NOV</span>
                                    </th>
                                    <th scope="col" [ngClass]="{'active' : this.indexMount == 2}">
                                        <span class="text-primary"
                                              [ngClass]="{'bg-gray' : this.indexMount != 2}">DEZ</span>
                                    </th>
                                    <th scope="col" [ngClass]="{'active' : this.indexMount == 3}">
                                        <span class="text-primary"
                                              [ngClass]="{'bg-gray' : this.indexMount != 3}">JAN</span>
                                    </th>
                                    <th scope="col" [ngClass]="{'active' : this.indexMount == 4}">
                                        <span class="text-primary"
                                              [ngClass]="{'bg-gray' : this.indexMount != 4}">FEV</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let x of rankingResalePerformance; let  i = index">
                                    <td [ngClass]="(i + 1) === rankingResalePerformance.length?
                                    'font-weight-bold text-primary':
                                    'text-secondary'" class="no-border">
                                        {{caseCategory(x.category)}}
                                    </td>
                                    <td class="text-primary" [ngClass]="{'active' : this.indexMount == 0}">
                                        {{x.scoreOut}}
                                    </td>
                                    <td class="text-primary" [ngClass]="{'active' : this.indexMount == 1}">
                                        {{x.scoreNov}}
                                    </td>
                                    <td class="text-primary" [ngClass]="{'active' : this.indexMount == 2}">
                                        {{x.scoreDez}}
                                    </td>
                                    <td class="text-primary" [ngClass]="{'active' : this.indexMount == 3}">
                                        {{x.scoreJan}}
                                    </td>
                                    <td class="text-primary" [ngClass]="{'active' : this.indexMount == 4}">
                                        {{x.scoreFev}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalRankingInformation" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="max-width: 640px">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <h3 class="modal-title modal_title"></h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body pt-0 bg-secondary">
                <h3 class="modal-title modal_title text-uppercase text-center mb-5"
                    style="max-width: 372px;margin: 0 auto;">
                    ATENÇÃO
                </h3>
                <h4 class="text-white text-center mb-4">
                    O item adimplência do Mega Desafio foi aplicado somente no mês de outubro, pois, devido a uma questão sistêmica nos demais meses não foi possível apurar o quesito. Essa condição não impacta os resultados da campanha, visto que, foi aplicada a todos de forma imparcial.
                </h4>
            </div>
        </div>
    </div>
</div>
