import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-prize-detail',
    templateUrl: './prize-detail.component.html',
    styleUrls: ['./prize-detail.component.scss']
})
export class PrizeDetailComponent extends BaseComponent implements OnInit {

    constructor(public router: Router,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
    }

}
