<div class="modal fade" id="{{id}}" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="max-width: 560px">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <h3 class="modal-title modal_title"></h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body pt-0 bg-secondary">
                <h3 class="modal-title modal_title text-uppercase text-center mb-5"
                    style="max-width: 372px;margin: 0 auto;">
                    defina qual é o tipo da sua revenda
                </h3>
                <div class="row align-items-center no-gutters border-bottom border-white pb-3 mb-3"
                     *ngIf="this.gasStationInput.gasStation">
                    <div class="col-sm">
                        <span class="text-white">Possui Testeira?</span>
                    </div>
                    <div class="col-sm-4">
                        <div class="row mx-n1">
                            <div class="col px-1">
                                <a (click)="gasStationInput.isFacade = true"
                                   [ngClass]="{'bg-white text-primary':gasStationInput.isFacade}"
                                   class="btn btn-block btn-outline-white">
                                    <span>Sim</span>
                                </a>
                            </div>
                            <div class="col px-1">
                                <a (click)="gasStationInput.isFacade = false"
                                   [ngClass]="{'bg-white text-primary':gasStationInput.isFacade == false}"
                                   class="btn btn-block btn-outline-white">
                                    <span>Não</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center no-gutters">
                    <div class="col-sm">
                        <span class="text-white">Possui Frota?</span>
                    </div>
                    <div class="col-sm-4">
                        <div class="row mx-n1">
                            <div class="col px-1">
                                <a (click)="gasStationInput.isFleet = true"
                                   [ngClass]="{'bg-white text-primary':gasStationInput.isFleet}"
                                   class="btn btn-block btn-outline-white">
                                    <span>Sim</span>
                                </a>
                            </div>
                            <div class="col px-1">
                                <a (click)="gasStationInput.isFleet = false"
                                   [ngClass]="{'bg-white text-primary':gasStationInput.isFleet == false}"
                                   class="btn btn-block btn-outline-white">
                                    <span>Não</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <div class="col-sm-7">
                        <a (click)="updateGasStation()" class="btn btn-block btn-white">Alterar</a>
                    </div>
                    <div class="col-sm-5">
                        <a (click)="closeModal(id)" class="btn btn-block btn-outline-white">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
