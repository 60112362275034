<header class="header" [ngClass]="{ 'open': opened }">
    <div class="container-xxl">
        <div class="row justify-content-between pr-3">
            <div class="col-lg-auto d-flex justify-content-end">
                <div class="header_logo">
                    <img src="assets/images/logo-header.png" alt="logo">
                </div>
            </div>
            <div class="col-lg">
                <div class="row justify-content-end hero_primary">
                    <div class="col-auto">
                        <div class="row align-items-end" style="row-gap: 15px">
                            <div class="col-lg-auto" *ngIf="getModelUserView(user) === 'rv'">
                                <div class="header_goal bg-white">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <p class="goal_lbl text-primary mb-0">Meta de vendas</p>
                                            <p class="goal_value text-tertiary mb-0">{{isNullOrUndefined(goalMonth) ? 'Outubro' : goalMonth}}</p>
                                        </div>
                                        <div class="col-auto">
                                            <p class="goal_value text-primary mb-0">
                                                <small>{{isNullOrUndefined(goalValue) ? 'Aguarde' : goalValue + ' kg'}}</small>
                                                <!--99.999 Kg--></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto order-lg-first">
                                <div class="row align-items-center" style="row-gap: 15px">
                                    <div class="col-auto">
                                        <img class="header_photo" alt="photo"
                                             src="{{isNullOrUndefined(user.pathImage)?'assets/images/nophoto.png':getImageURL() + 'user/image/' + user.pathImage}}">
                                    </div>
                                    <div class="col-auto pl-0">
                                        <p class="header_text mb-0">Olá, <span>{{this.user.name}}</span>
                                            <ng-container
                                                    *ngIf="getModelUserView(user) === 'rv' || getModelUserView(user) === 'eq'">
                                                <ng-container *ngIf="!this.isNullOrUndefined(this.user.profile)">
                                                    {{' | ' + this.user.profile?.name}}
                                                </ng-container>
                                                <span *ngIf="!this.isNullOrUndefined(this.user.gmr)">
                                                    {{' | ' + this.user.gmr?.name}}
                                                </span>
                                                <span *ngIf="!this.isNullOrUndefined(this.user.range)">
                                                    {{' | ' + this.user.range?.name}}
                                                </span>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="getModelUserView(user) !== 'rv' && getModelUserView(user) !== 'eq' && getModelUserView(user) !== 'ma'">
                                                <ng-container *ngIf="!this.isNullOrUndefined(this.user.gmr)">
                                                    {{' | ' + this.user.gmr?.name}}
                                                </ng-container>
                                                <span *ngIf="!this.isNullOrUndefined(this.user.gve)">
                                                    {{' | ' + this.user.gve}}
                                                </span>
                                            </ng-container>
                                        </p>
                                    </div>
                                    <div class="col-auto">
                                        <a class="btn btn-sm d-flex btn-secondary" (click)="showModal('isProfile')">
                                            <span class="text-uppercase">meu perfil</span>
                                            <svg-icon src="assets/svg/person.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/images/logo-white.png" alt="logo">
                    </div>
                </div>
                <div class="header_margin">
                    <div class="row align-items-center" style="row-gap: 20px">
                        <div class="col-lg">
                            <ul class="row mx-n2 header_nav">
                                <li class="col-lg-auto px-2">
                                    <a href="javascript:void('')" class="header_route"
                                       [ngClass]="{'active':router.url === '/'}" (click)="opened = false"
                                       [routerLink]="'/'">Home</a>
                                </li>
                                <li class="col-lg-auto px-2">
                                    <a href="javascript:void('')" class="header_route" (click)="opened = false"
                                       [ngClass]="{'active':router.url === '/campanha'}" [routerLink]="'/campanha'">A
                                        campanha</a>
                                </li>
                                <li class="col-lg-auto px-2" *ngIf="getModelUserView(user) === 'rv'">
                                    <a href="javascript:void('')" class="header_route" (click)="opened = false"
                                       [ngClass]="{'active':router.url === '/usuarios'}" [routerLink]="'/usuarios'">Cadastre
                                        sua
                                        equipe</a>
                                </li>
                                <li class="col-lg-auto px-2">
                                    <a href="javascript:void('')" class="header_route" (click)="opened = false"
                                       [ngClass]="{'active':router.url === '/time-preparado'}"
                                       [routerLink]="'/time-preparado'">Time preparado</a>
                                </li>
                                <li class="col-lg-auto px-2" *ngIf="getModelUserView(user) === 'rv'">
                                    <a href="javascript:void('')" class="header_route" (click)="opened = false"
                                       [ngClass]="{'active':router.url === '/padronizacao'}"
                                       [routerLink]="'/padronizacao'">Padronização</a>
                                </li>
                                <li class="col-lg-auto px-2" *ngIf="getModelUserView(user) === 'rv' || getModelUserView(user) === 'ec'">
                                    <a href="javascript:void('')" class="header_route" (click)="opened = false"
                                       [ngClass]="{'active':router.url === '/visita-tecnica'}"
                                       [routerLink]="'/visita-tecnica'">Visita
                                        técnica</a>
                                </li>
                                <li class="col-lg-auto px-2">
                                    <a href="javascript:void('')" class="header_route" (click)="opened = false"
                                       [ngClass]="{'active':router.url === '/premios'}"
                                       [routerLink]="'/premios'">Prêmios</a>
                                </li>
                                <li class="col-lg-auto px-2">
                                    <a href="javascript:void('')" class="header_route" (click)="opened = false"
                                       [ngClass]="{'active':router.url === '/ganhadores'}"
                                       [routerLink]="'/ganhadores'">Ganhadores</a>
                                </li>
                                <li class="col-lg-auto px-2"
                                    *ngIf="getModelUserView(user) === 'rv'
                                    || user.profile?.id == 'MA' || user.profile?.id == 'EC'">
                                    <a href="javascript:void('')" class="header_route" (click)="opened = false"
                                       [ngClass]="{'active':router.url === '/ranking'}"
                                       [routerLink]="'/ranking'">Ranking</a>
                                </li>
                                <!--                                <li class="col-lg-auto" *ngIf="getModelUserView(user) === 'rv'">-->
                                <!--                                    <a href="javascript:void('')" class="header_route" (click)="opened = false"-->
                                <!--                                       [ngClass]="{'active':router.url === '/resgate'}"-->
                                <!--                                       [routerLink]="'/resgate'">Resgate</a>-->
                                <!--                                </li>-->
                            </ul>
                        </div>
                        <div class="col-lg-auto">
                            <div class="d-flex align-items-center">
                                <a class="btn btn-exit btn-sm d-flex btn-tertiary" (click)="onLogout()">
                                    <span class="text-uppercase">Sair</span>
                                    <svg-icon src="assets/svg/logout.svg"></svg-icon>
                                </a>
                                <img src="assets/images/logo-white.png" alt="logo" class="brand-fixed ml-3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="header-mobile d-lg-none">
    <div class="row pr-3 no-gutters justify-content-between align-items-center">
        <div class="col-auto">
            <div id="hamburger" (click)="menu()" [ngClass]="{ 'open': opened }">
                <svg width="60" height="60" viewBox="0 0 100 100">
                    <path class="line line-1"
                          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"/>
                    <path class="line line-2" d="M 20,50 H 80"/>
                    <path class="line line-3"
                          d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"/>
                </svg>
            </div>
        </div>
        <div class="col-auto">
            <img src="assets/images/logo-white.png" alt="logo">
        </div>
    </div>
</div>
