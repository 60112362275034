import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {QuizQuestion, User, UserGoalMonthGQL} from '../../../generated/graphql';

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {
    opened = false;
    user: User = new User();

    goalValue = 'Aguarde';
    goalMonth = '';

    constructor(public router: Router,
                public translate: TranslateService,
                public userGoalMonthGQL: UserGoalMonthGQL,
                public userService: UserService
    ) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.user = data as User;
                    window.onscroll = () => {
                        const hero = document.querySelector<HTMLElement>('.container-hero');
                        const header = document.querySelector<HTMLElement>('.header');
                        const body = document.querySelector<HTMLElement>('.body-inside');
                        window.pageYOffset >= hero.offsetHeight - (!this.isNullOrUndefined(body) ? 10 : 190) ? header.classList.add('hero') : header.classList.remove('hero');
                    };
                }
            }, error: err => super.onError(err)
        });
        this.userGoalMonthGQL.watch().valueChanges.subscribe(({data}) => {
            const a: any = data.userGoalMonth;
            this.goalValue = a.goal;
            this.goalMonth = a.month;
        }, error => super.onError(error));
    }

    menu() {
        this.opened = !this.opened;
    }
}
