import {AfterViewInit, Component, OnInit, OnDestroy} from '@angular/core';
import {environment} from '../../../environments/environment.js';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    Banner, ClickBannerGQL, ClickNewsAccessGQL,
    CreateBannerAccessInput, CreateNewsAccessInput,
    HomeBannersGQL,
    HomeNewsGQL, NewsPage, User, UserCouponDto, UserCouponGQL, UserPerformance, UserPerformanceGQL,
} from "../../../generated/graphql";
import {UserService} from "../../service/user.service";
import moment from "moment";

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    newsPage: NewsPage = new NewsPage();
    banner: Banner[] = [];
    selectMouth = 4;
    user: User = new User();
    modelUserView: string;

    listEngagedReseller = [
        {name: 'Outubro', activeAt: '1/10/2022', active: true},
        {name: 'Novembro', activeAt: '1/11/2022', active: true},
        {name: 'Dezembro', activeAt: '1/12/2022', active: true},
        {name: 'Janeiro', activeAt: '1/1/2023', active: true},
        {name: 'Fevereiro', activeAt: '1/2/2023', active: true}
    ];
    userPerformance: UserPerformance = new UserPerformance();
    listSortMouth = [2, 1, 0, 4, 3];
    viewCookies = false;
    userCoupon: UserCouponDto = new UserCouponDto();

    constructor(public router: Router,
                public homeNewsGQL: HomeNewsGQL,
                public homeBannersGQL: HomeBannersGQL,
                public userService: UserService,
                public clickBannerGQL: ClickBannerGQL,
                public clickNewsAccessGQL: ClickNewsAccessGQL,
                public userPerformanceGQL: UserPerformanceGQL,
                private userCouponGQL: UserCouponGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit() {
        this.handlerCookiesShow();
        this.userService.user.subscribe((data) => {
            if (!this.isNullOrUndefined(data.id)) {
                this.user = data as User;
                this.modelUserView = this.user.profile?.id;
                $(() => {
                    this.getCupon();
                    this.getBanner();
                    this.getNews();
                    this.getPerformance();
                });
            }
        });

        $(() => {
            const m = $('#isViewVideo');
            m.on('shown.bs.modal', () => {
                const a = '<iframe width="100%" height="280" src="https://www.youtube.com/embed/S-3S2pvowGg" title="SistechPro" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                $('.box-video-frame').html(a);
            });
            m.on('hidden.bs.modal', () => {
                $('.box-video-frame').html('');
            });
        });
    }


    ngAfterViewInit() {
        setTimeout(() => {
            // $('#slick-quiz').slick({arrows: false});
        }, 100);
    }

    getNews() {
        this.homeNewsGQL.watch({page: 1}).valueChanges.subscribe(({data}) => {
            this.newsPage = data.homeNews as NewsPage;
            setTimeout(() => {
                $('#slick-news').slick({
                    // accesibility: false,
                    // draggable: false,
                    // swipe: false,
                    // touchMove: false,
                    dots: true,
                    arrows: false,
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: true,
                                dots: true
                            }
                        },
                        {
                            breakpoint: 760,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            }, 100);
        }, error => super.onError(error));
    }

    getBanner() {
        this.homeBannersGQL.watch().valueChanges.subscribe(({data}) => {
            this.banner = data.homeBanners as Banner[];
            setTimeout(() => {
                $('#slick-banner').slick({
                    accesibility: false,
                    draggable: false,
                    swipe: false,
                    touchMove: false,
                    dots: true,
                    arrows: true,
                    autoplay: true,
                    autoplaySpeed: 5000
                });
            }, 100);
        }, error => super.onError(error));
    }

    ngOnDestroy(): void {
        $('#sidebar').removeClass('is-hide');
        super.destroyModal('isViewVideo');
    }

    clickBanner(banner: Banner) {
        const d: CreateBannerAccessInput = {bannerId: banner.id};
        this.clickBannerGQL.mutate({input: d}).subscribe(({data}) => {
            if (!this.isNullOrUndefined(banner.pathLink)) {
                document.location.href = banner.pathLink;
            }
        }, error => super.onError(error));
    }

    clickNewsAccess(id) {
        const d: CreateNewsAccessInput = {newsId: id};
        this.clickNewsAccessGQL.mutate({input: d}).subscribe(({data}) => {
            this.router.navigate(['/noticias-detalhe/' + id]);
        }, error => super.onError(error));
    }

    getPerformance() {
        this.userPerformanceGQL.watch().valueChanges.subscribe(({data}) => {
            this.userPerformance = data.userPerformance as UserPerformance;
        }, error => super.onError(error));
    }

    currentMount(m: number) {
        const cuurretMout = moment().month() + 1;
        return cuurretMout === m;
    }

    handlerCookiesShow(): void {
        this.viewCookies = localStorage.getItem('view-video') === 'true';
        if (!this.viewCookies) {
            // this.showModal('isViewVideo');
        }
    }

    actionCookies() {
        this.viewCookies = true;
        localStorage.setItem('view-video', 'true');
    }

    getCupon() {
        this.userCouponGQL.watch().valueChanges.subscribe(({data}) => {
            this.userCoupon = data.userCoupon as UserCouponDto;
        }, error => super.onError(error));
    }
}



