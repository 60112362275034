import {AfterContentInit, Component, OnDestroy} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {
    FindAllRangeGQL,
    GmrRegion,
    QuizAnswerDto,
    QuizAnswerFinish,
    QuizAnswerGQL,
    QuizAnswerInput,
    QuizEnableGQL,
    QuizEnableQuery,
    QuizFinishGQL,
    QuizQuestion,
    QuizQuestionGQL, Range,
    RankingExtraMechanicsInput,
    RankingMechanics,
    RankingMechanicsExtraGQL,
    RankingMechanicsExtraQuery, RegionFindAllGQL,
    TrainingEmployeeResult,
    TrainingEmployees,
    TrainingEmployeesGQL,
    TrainingEmployeesQuery,
    User,
    WathingVideoGQL
} from '../../../generated/graphql';
import {Month} from "../../model/month";
import {QueryRef} from "apollo-angular";
import {AnimationOptions} from "ngx-lottie";

declare var $: any;
declare var jlPlayer;
declare var moment;
declare var totalJLPlayer;
declare var idElement;

@Component({
    selector: 'app-engaged-reseller',
    templateUrl: './engaged-reseller.component.html',
    styleUrls: ['./engaged-reseller.component.scss']
})
export class EngagedResellerComponent extends BaseComponent implements AfterContentInit, OnDestroy {
    stepTraining: number;
    quizEnableStatus = 0;
    quizEnableMessage: string;

    trophyJson: AnimationOptions = {
        path: '/assets/lottie/trophy.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };

    oasJson: AnimationOptions = {
        path: '/assets/lottie/oas.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };

    user: User = new User();
    quizQuestion: QuizQuestion = new QuizQuestion();
    quizAnswerInput: QuizAnswerInput = new QuizAnswerInput();
    quizAnswerDto: QuizAnswerDto;
    quizAnswerFinish: QuizAnswerFinish = new QuizAnswerFinish();
    quizEnableQuery: QueryRef<QuizEnableQuery>;

    trainingEmployeesQuery: QueryRef<TrainingEmployeesQuery>;
    trainingEmployees: TrainingEmployees = new TrainingEmployees();
    trainingMessage = '';
    trainingWinner = false;
    dataUser: TrainingEmployeeResult = new TrainingEmployeeResult();

    page: number = 1;
    rankingInput: RankingExtraMechanicsInput = new RankingExtraMechanicsInput();
    rankingMechanics: RankingMechanics = new RankingMechanics();
    rankingMechanicsQuery: QueryRef<RankingMechanicsExtraQuery>;

    regionFindAll: GmrRegion[] = [];
    findAllRange: Range[] = [];
    handlerRankingResaleBool = false;
    handlerRankingResaleBoolRange = false;

    rankingTotalizer = [
        {
            id: 99, year: 2023, name: 'Total', point: 0, active: true, date: '30/12/2022',
            link: 'assets/videos/video.mp4',
            thumbPath: 'assets/images/videoframe.jpg',
        },
        {
            id: 98, year: 2023, name: 'Total', point: 0, active: true, date: '30/03/2023',
            link: 'assets/videos/video.mp4',
            thumbPath: 'assets/images/videoframe.jpg',
        },
        {
            id: 97, year: 2023, name: 'Total', point: 0, active: true, date: '30/03/2023',
            link: 'assets/videos/video.mp4',
            thumbPath: 'assets/images/videoframe.jpg',
        }
    ];

    constructor(public router: Router,
                public userService: UserService,
                public quizEnableGQL: QuizEnableGQL,
                public wathingVideoGQL: WathingVideoGQL,
                public quizQuestionGQL: QuizQuestionGQL,
                public quizAnswerGQL: QuizAnswerGQL,
                public quizFinishGQL: QuizFinishGQL,
                public regionFindAllGQL: RegionFindAllGQL,
                public findAllRangeGQL: FindAllRangeGQL,
                public trainingEmployeesGQL: TrainingEmployeesGQL,
                public rankingMechanicsExtraGQL: RankingMechanicsExtraGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngAfterContentInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.user = data as User;
                    this.configFilter();
                    this.findRegion();
                }
            }
        });
        this.getTrainingEmployees();

        $(() => {
            $('#modalTrainingFinish').on('hide.bs.modal', () => {
                if (this.trainingWinner) {
                    this.openQuiz();
                }
            });
        });
    }

    ngOnDestroy(): void {
        super.destroyModal('modalQuest');
        super.destroyModal('modalAccumulated');
        super.destroyModal('modalQuestFinish');
        super.destroyModal('modalTrainingFinish');
    }

    configFilter() {
        // const indexMount = this.listOfMonths.map((e) => e.id).indexOf(moment().month() + 1);
        this.handlerTraining(this.rankingTotalizer[2]);
        this.rankingInput.month = this.rankingTotalizer[2].id;
        this.rankingInput.year = this.rankingTotalizer[2].year;
        /* const indexMount = this.listOfMonths.map((e) => e.id).indexOf(2);
        if (indexMount == -1) {
            console.log('123');
            console.log(this.listOfMonths[0]);
            this.handlerTraining(this.listOfMonths[0]);
            this.rankingInput.month = this.listOfMonths[0].id;
            this.rankingInput.year = this.listOfMonths[0].year;
        } else {
            this.handlerTraining(this.listOfMonths[indexMount]);
            this.rankingInput.month = this.listOfMonths[indexMount - 1].id;
            this.rankingInput.year = this.listOfMonths[indexMount - 1].year;
        } */
        this.handlerRankingResaleBool = this.handlerRankingResale();
        this.handlerRankingResaleBoolRange = this.handlerRankingResaleRange();
    }

    handlerRankingResale() {
        console.log('handlerRankingResale');
        console.log(this.user.profile?.id);
        return this.user.profile?.id.toLowerCase() === 'ma';
    }

    handlerRankingResaleRange() {
        return this.user.profile?.id.toLowerCase() === 'ma' || this.user.profile?.id.toLowerCase() === 'ec';
    }

    findRange() {
        this.findAllRangeGQL.watch().valueChanges.subscribe(({data}) => {
            this.findAllRange = data.findAllRange as Range[];
            if (!this.isNullOrUndefined(this.user.range?.id)) {
                this.rankingInput.rangeId = this.user.range!.id;
            } else this.rankingInput.rangeId = this.findAllRange[0].id;
            this.getRankingMachine();
        }, error => super.onError(error));
    }

    findRegion() {
        this.regionFindAllGQL.watch().valueChanges.subscribe(({data}) => {
            this.regionFindAll = data.regionFindAll as GmrRegion[];
            if (!this.isNullOrUndefined(this.user.gmr?.region)) {
                this.rankingInput.region = this.user.gmr!.region;
            } else {
                this.rankingInput.region = this.regionFindAll[0].name;
            }
            this.findRange();
        }, error => super.onError(error));
    }

    handlerTraining(month: Month) {
        if (!month.active) { return; }
        this.stepTraining = month.id;
        this.quizEnableQuery = this.quizEnableGQL.watch({input: {month: this.stepTraining}});
        this.quizEnableQuery.valueChanges.subscribe(({data}) => {
            this.quizEnableStatus = data.quizEnable.status;
            this.quizEnableMessage = data.quizEnable.message;
            this.actionMedia(month);
        }, error => super.onError(error));
    }

    getRankingMachine() {
        this.rankingMechanicsQuery = this.rankingMechanicsExtraGQL.watch({input: this.rankingInput});
        this.rankingMechanicsQuery.valueChanges.subscribe(({data}) => {
            this.rankingMechanics = data.rankingMechanicsExtra as RankingMechanics;
        }, error => super.onError(error));
    }

    getTrainingEmployees() {
        this.trainingEmployeesQuery = this.trainingEmployeesGQL.watch({input: this.page});
        this.trainingEmployeesQuery.valueChanges.subscribe(({data}) => {
            this.trainingEmployees = data.trainingEmployees as TrainingEmployees;
            this.dataUser = this.trainingEmployees.data as TrainingEmployeeResult;
        }, error => super.onError(error));
    }

    openQuiz() {
        if (this.quizEnableStatus !== 0) {
            this.quizQuestion = new QuizQuestion();
            this.quizQuestionGQL.watch({input: {month: this.stepTraining}}).valueChanges.subscribe(({data}) => {
                this.quizQuestion = data.quizQuestion as QuizQuestion;
                this.quizAnswerInput.questionId = this.quizQuestion.question.id;
                this.showModal('modalQuest');
            }, error => super.onError(error));
        } else {
            this.showMessage('Atenção', this.quizEnableMessage);
        }
    }

    onSave() {
        this.quizAnswerGQL.mutate({input: this.quizAnswerInput}).subscribe(({data}) => {
            this.quizAnswerDto = data.quizAnswer as QuizAnswerDto;
            if (this.quizQuestion.current === this.quizQuestion.total) {
                this.onFinish();
            }
        }, error => super.onError(error));

    }

    onFinish() {
        this.quizFinishGQL.mutate({input: {month: this.stepTraining}}).subscribe(({data}) => {
            this.quizAnswerFinish = data.quizFinish as QuizAnswerFinish;
            this.quizEnableQuery.refetch({input: {month: this.stepTraining}}).then();
            this.closeModal('modalQuest');
            this.showModal('modalQuestFinish');
        }, error => super.onError(error));
    }

    onNextQuiz() {
        this.quizAnswerDto = null;
        this.quizQuestion = new QuizQuestion();
        this.quizAnswerInput = new QuizAnswerInput();
        this.quizQuestionGQL.watch({input: {month: this.stepTraining}}).valueChanges.subscribe(({data}) => {
            this.quizQuestion = data.quizQuestion as QuizQuestion;
            this.quizAnswerInput.questionId = this.quizQuestion.question.id;
        }, error => super.onError(error));
    }

    pageChanged(event: number) {
        this.page = event;
        this.trainingEmployeesQuery.refetch({input: this.page}).then();
    }

    actionMedia(month: Month): void {
        let video = $('.media-video');
        video.html('');
        const strHtml = `<div class="video-container">
          <video id="video" preload="none"  poster="${month.thumbPath}"
                 class="jlplayer-video">
            <source src="${month.link}" type="video/mp4"> 
          </video>
        </div>`;
        video.html(strHtml);
        setTimeout(() => {
            totalJLPlayer = document.getElementsByClassName('jlplayer-video').length;
            if (totalJLPlayer > 0) {
                for (let i = 0; i < totalJLPlayer; i++) {
                    idElement = 'jlplayer-' + Math.floor(Math.random() * 100000000);
                    document.getElementsByClassName('jlplayer-video')[i].id = idElement;
                    new jlPlayer(idElement);
                    $('.video-container').find('video').on('ended', () => {
                        console.log('video terminou');
                        this.wathingVideoGQL.mutate({input: {month: month.id}}).subscribe(({data}) => {
                            this.quizEnableStatus = data.wathingVideo.isSuccess;
                            if (this.quizEnableStatus === 1) {
                                const a = this.isNullOrUndefined(data.wathingVideo.message) ? 'Você finalizou o treinamento com sucesso. E agora pode testar seus conhecimentos.' : data.wathingVideo.message;
                                this.trainingMessage = a;
                                this.trainingWinner = data.wathingVideo.isWinner;
                                this.showModal('modalTrainingFinish');
                                // this.showMessage('Parabéns!', a, 'success');
                                // this.openQuiz();
                            }
                        }, error => super.onError(error));
                    });
                }
            }
        }, 500);
    }

    handlerRanking(l?: Month) {
        this.rankingInput.month = !this.isNullOrUndefined(l) ? l.id : this.rankingInput.month;
        this.rankingInput.year = !this.isNullOrUndefined(l) ? l.year : this.rankingInput.year;
        this.rankingMechanicsQuery.refetch({input: this.rankingInput}).then();
    }

    handlerQuiz() {
        return this.quizAnswerFinish.quantityQuestion === this.quizAnswerFinish.quantityCorrectAnswers;
    }
}
