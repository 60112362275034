<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Visita Técnica</p>
        <div class="container">
            <h2 class="page_header_title">Visita <span>Técnica</span></h2>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="false">
    <div class="wait-panel">
        <h3>Em Breve</h3>
    </div>
</div>

<ng-container *ngIf="true">
    <div class="container">
        <div class="row py-5">
            <div class="col-sm-6">
                <h4 class="home_title mb-5 text-uppercase" style="line-height:1.2">
                    Cada detalhe conta para sua revenda se destacar e pontuar</h4>
                <dl>
                    <dt class="technical_lbl">FIQUE LIGADO!</dt>
                    <dd class="technical_value">
                        Quanto antes você fizer o upload das fotos da sua revenda, mais cedo o auditor poderá fazer sua
                        avaliação.
                    </dd>
                </dl>
            </div>
            <div class="col-sm-6">
                <div class="row" style="row-gap:30px">
                    <div class="col-6">
                        <svg-icon [svgStyle]="{'transform':'rotate(45deg)'}" src="assets/svg/item-point.svg"></svg-icon>
                        <p class="technical_text mt-2 mb-0">Você está cuidando bem da gestão da sua revenda?</p>
                    </div>
                    <div class="col-6">
                        <svg-icon src="assets/svg/item-point.svg"></svg-icon>
                        <p class="technical_text mt-2 mb-0">Está treinando direitinho sua equipe?</p>
                    </div>
                    <div class="col-6">
                        <svg-icon [svgStyle]="{'transform':'rotate(-90deg)'}"
                                  src="assets/svg/item-point.svg"></svg-icon>
                        <p class="technical_text mt-2 mb-0">Está tomando todos os cuidados com a segurança?</p>
                    </div>
                    <div class="col-6">
                        <svg-icon [svgStyle]="{'transform':'rotate(-45deg)'}"
                                  src="assets/svg/item-point.svg"></svg-icon>
                        <p class="technical_text mt-2 mb-0">A Visita Técnica vai avaliar tudo isso, fique ligado!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-5" style="background-color: #F2F2F2">
        <div class="container">
            <div class="row">
                <div class="col-5">
                    <h4 class="home_title mb-0 text-uppercase" *ngIf="technicalVisitPage.technicalVisits?.length > 1">
                        Lista<br>de Revenda</h4>
                    <h4 class="home_title mb-0 text-uppercase" *ngIf="technicalVisitPage.technicalVisits?.length <= 1">
                        Revenda</h4>
                </div>
                <div class="col-7">
                    <p class="technical_text" style="font-size: 15px;">
                        Clique no
                        <span>ícone do lápis</span>
                        para acessar o espaço onde poderá fazer o upload das
                        imagens em cada um
                        dos critérios da “Visita Técnica”.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row mt-3">
            <div class="col-lg-12">
                <form class="row mb-5" autocomplete="off">
                    <div class="col-md-4">
                        <div class="input-material mb-0">
                            <input type="text" [(ngModel)]="filter.companyName" class="form-control" autocomplete="off"
                                   maxlength="100"
                                   id="search1" name="search1" required/>
                            <label for="search1">Pesquisar</label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="input-material select mb-0">
                            <select [disabled]="disableGme" [(ngModel)]="filter.gmr" class="form-control" id="gme"
                                    name="gme">
                                <option [ngValue]="undefined">Todos</option>
                                <option *ngFor="let x of gmrAll" [value]="x.id">{{x.name}}</option>
                            </select>
                            <label for="gme" [ngClass]="{'disabled':disableGme}">GMR</label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-material mb-0">
                            <select [(ngModel)]="filter.status" class="form-control" id="status" name="status">
                                <option [ngValue]="undefined">Todos</option>
                                <option value="FINALIZADA">Finalizada</option>
                                <option value="EM ANÁLISE">Em análise</option>
                                <option value="PENDENTE">Pendente</option>
                                <option value="REVISAR">Revisar</option>
                            </select>
                            <label for="status">Status Visita</label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <a (click)="onFilter()" class="btn btn-secondary btn-block">Buscar</a>
                    </div>
                </form>
                <div class="table-responsive">
                    <table class="table table-resale table-striped">
                        <thead class="bg-primary">
                        <tr>
                            <th class="text-center" scope="col">CNPJ</th>
                            <th class="" scope="col">Razão</th>
                            <th class="" scope="col">UF</th>
                            <th class="" scope="col">Cidade</th>
                            <th class="" scope="col">Status Visita</th>
                            <th class="" scope="col">Status Padronização</th>
                            <th class="text-center" scope="col">Ação</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="this.isNullOrUndefined(technicalVisitPage.technicalVisits)">
                            <td colspan="7" class="text-center">Nenhum registro encontrado</td>
                        </tr>
                        <tr *ngFor="let x of technicalVisitPage.technicalVisits |
                        paginate:{id:'technicalVisits',itemsPerPage: technicalVisitPage.PageSize,
                        currentPage: technicalVisitPage.currentPage, totalItems: technicalVisitPage.totalCount }">
                            <td class="text-center">{{x.cpfCnpj | mask:'00.000.000/0000-00'}}</td>
                            <td>{{x.companyName}}</td>
                            <td>{{x.gmr}}</td>
                            <td>{{x.gve}}</td>
                            <td>{{x.status}}</td>
                            <td>{{x.statusStandardization}}</td>
                            <td>
                                <a href="javascript:void(0)" (click)="handlerDetail(x)"
                                   class="btn btn-sm btn-primary cursor-pointer d-flex justify-content-center">
                                    <span>Detalhe </span>
                                    <svg-icon class="svg-white svg-icon-sm"
                                              src="assets/svg/edit-secondary.svg"></svg-icon>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <app-pagination-custom
                        [id]="'technicalVisits'"
                        (pageChange)="pageChanged($event)"
                ></app-pagination-custom>
                <dl>
                    <dt class="technical_lbl technical_lbl-lg">Legendas</dt>
                    <dd class="row mt-4">
                        <div class="col-sm-6 col-lg-3">
                            <div class="row align-items-center">
                                <div class="col-2 col-sm-3">
                                    <svg-icon src="assets/svg/finished.svg"></svg-icon>
                                </div>
                                <div class="col">
                                    <span class="technical_value technical_value-sm">FINALIZADA</span>
                                </div>
                                <div class="col-sm-9 offset-sm-3">
                                    <p class="technical_value technical_value-sm">As fotos foram analizadas e não
                                        necessitam de ação adicional da equipe
                                        comercial ou revenda.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="row align-items-center">
                                <div class="col-2 col-sm-3">
                                    <svg-icon src="assets/svg/analysis.svg"></svg-icon>
                                </div>
                                <div class="col">
                                    <span class="technical_value technical_value-sm">EM ANÁLISE</span>
                                </div>
                                <div class="col-sm-9 offset-sm-3">
                                    <p class="technical_value technical_value-sm">As imagens já foram enviadas e estão
                                        sendo analisadas.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="row align-items-center">
                                <div class="col-2 col-sm-3">
                                    <svg-icon src="assets/svg/pending.svg"></svg-icon>
                                </div>
                                <div class="col">
                                    <span class="technical_value technical_value-sm">PENDENTE</span>
                                </div>
                                <div class="col-sm-9 offset-sm-3">
                                    <p class="technical_value technical_value-sm">Não houve envio de fotos para
                                        análise.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="row align-items-center">
                                <div class="col-2 col-sm-3">
                                    <svg-icon src="assets/svg/review.svg"></svg-icon>
                                </div>
                                <div class="col">
                                    <span class="technical_value technical_value-sm">REVISAR</span>
                                </div>
                                <div class="col-sm-9 offset-sm-3">
                                    <p class="technical_value technical_value-sm">
                                        A equipe comercial ou a revenda precisa tomar providências conforme comentário
                                        recebido.</p>
                                </div>
                            </div>
                        </div>
                    </dd>
                </dl>
            </div>
        </div>

    </div>
</ng-container>

<div class="modal fade" id="modalGasStation" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="max-width: 560px">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <h3 class="modal-title modal_title"></h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body pt-0 bg-secondary">
                <h3 class="modal-title modal_title text-uppercase text-center mb-4"
                    style="max-width: 372px;margin: 0 auto;">
                    defina qual é o tipo da sua revenda
                </h3>
                <div class="row mb-4">
                    <dl class="col-auto">
                        <dt class="modal_value text-white mb-0 pr-2">CNPJ</dt>
                        <dd class="modal_lbl text-white mb-0">{{technicalVisit.cpfCnpj |mask:'00.000.000/0000-00'}}</dd>
                    </dl>
                    <dl class="col">
                        <dt class="modal_value text-white mb-0 pr-2">Razão Social</dt>
                        <dd class="modal_lbl text-white mb-0">{{technicalVisit.companyName}}</dd>
                    </dl>
                    <dl class="col-auto">
                        <div class="row mx-n1 align-items-center">
                            <div class="col-4 px-1">
                                <svg-icon [src]="caseStatusIcon(technicalVisit.status)"></svg-icon>
                            </div>
                            <div class="col-auto px-1">
                                <dt class="modal_value text-white mb-0 pr-2">Status</dt>
                                <dd class="modal_lbl text-white mb-0">{{technicalVisit.status}}</dd>
                            </div>
                        </div>

                    </dl>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <a class="btn w-100 h-100 align-text-center btn-outline-white"
                           [ngClass]="{'btn-white': gasStation.gasStation == false}"
                           (click)="handlerGasStation(false)">
                            <span>Revenda</span>
                        </a>
                    </div>
                    <div class="col-sm-6">
                        <a class="btn w-100 h-100 align-text-center btn-outline-white"
                           [ngClass]="{'btn-white': gasStation.gasStation}"
                           (click)="handlerGasStation(true)">
                            <span>Postos ou Supermercados</span>
                        </a>
                    </div>
                </div>

                <div class="mt-5" *ngIf="!isNullOrUndefined(this.gasStation.gasStation)">
                    <div class="row align-items-center no-gutters border-bottom border-white pb-3 mb-3"
                         *ngIf="this.gasStation.gasStation">
                        <div class="col-sm">
                            <span class="text-white">Possui Testeira?</span>
                        </div>
                        <div class="col-sm-4">
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <a (click)="gasStation.isFacade = true"
                                       [ngClass]="{'bg-white text-primary':gasStation.isFacade}"
                                       class="btn btn-block btn-outline-white">
                                        <span>Sim</span>
                                    </a>
                                </div>
                                <div class="col px-1">
                                    <a (click)="gasStation.isFacade = false"
                                       [ngClass]="{'bg-white text-primary':gasStation.isFacade == false}"
                                       class="btn btn-block btn-outline-white">
                                        <span>Não</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center no-gutters">
                        <div class="col-sm">
                            <span class="text-white">Possui Frota?</span>
                        </div>
                        <div class="col-sm-4">
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <a (click)="gasStation.isFleet = true"
                                       [ngClass]="{'bg-white text-primary':gasStation.isFleet}"
                                       class="btn btn-block btn-outline-white">
                                        <span>Sim</span>
                                    </a>
                                </div>
                                <div class="col px-1">
                                    <a (click)="gasStation.isFleet = false"
                                       [ngClass]="{'bg-white text-primary':gasStation.isFleet == false}"
                                       class="btn btn-block btn-outline-white">
                                        <span>Não</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-sm-7">
                        <a class="btn w-100 h-100 align-text-center btn-white" (click)="changeGasStation()">
                            <span>Salvar</span>
                        </a>
                    </div>
                    <div class="col-sm-5">
                        <a class="btn w-100 h-100 align-text-center btn-outline-white"
                           (click)="closeModal('modalGasStation')">
                            <span>Cancelar</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
