import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {User} from "../../../generated/graphql";

declare var $: any;

@Component({
    selector: 'app-campaign',
    templateUrl: './campaign.component.html',
    styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent extends BaseComponent implements OnInit {
    urlMap: string = 'map';
    user: User = new User();

    constructor(public router: Router,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
        this.userService.user.subscribe((data) => {
            if (!this.isNullOrUndefined(data.id)) {
                this.user = data as User;
            }
        });
    }

    mouseEnter(map: string) {
        this.urlMap = map;
    }
}
