import {Component, OnInit} from '@angular/core';
import {Faq} from "./model/faq";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NewsGQL} from "../../../generated/graphql";
import {TranslateService} from "@ngx-translate/core";


declare var $: any;

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})


export class FaqComponent extends BaseComponent implements OnInit {
    faqList: Faq[] = [];
    filter: string;

    constructor(public router: Router,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        // this.getFaqFiltered();
        $(() => {
            $('#new_search').on('keyup', (e) => {
                $('.faq-group').each((gi, g) => {
                    let t = 0;
                    $(g).find('.mbg').each((i, c) => {
                        if ($(c).text().toLowerCase().indexOf($(e.target).val().toLowerCase()) >= 0) {
                            $(c).removeClass('d-none');
                        } else {
                            t++;
                            $(c).addClass('d-none');
                        }
                    });
                    if (t === $(g).find('.mbg').length) {
                        $(g).find('.empty').removeClass('d-none');
                    } else {
                        $(g).find('.empty').addClass('d-none');
                    }
                });
            });
        });
    }

    handlerCollapse(id: string) {
        $('#' + id).collapse('toggle');
    }

}
