import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {HomeComponent} from './views/home/home.component';
import {AuthGuard} from './service/auth-guard.service';
import {LoginComponent} from './views/login/login.component';
import {UserComponent} from './views/user/user.component';
import {PrizeDetailComponent} from "./views/prize-detail/prize-detail.component";
import {RankingComponent} from "./views/ranking/ranking.component";
import {PrizesComponent} from "./views/prizes/prizes.component";
import {TechnicalVisitComponent} from "./views/technical-visit/technical-visit.component";
import {CampaignComponent} from "./views/campaign/campaign.component";
import {EngagedResellerComponent} from "./views/engaged-reseller/engaged-reseller.component";
import {NewsComponent} from "./views/news/news.component";
import {RankingListExtraComponent} from "./views/ranking-list-extra/ranking-list-extra.component";
import {RankingDetailComponent} from "./views/ranking-detail/ranking-detail.component";
import {RecoveryComponent} from "./views/recovery/recovery.component";
import {FaqComponent} from "./views/faq/faq.component";
import {ContactComponent} from "./views/contact/contact.component";
import {DownloadsComponent} from "./views/downloads/downloads.component";
import {LogoutComponent} from "./views/logout/logout.component";
import {NewsDetailComponent} from "./views/news-detail/news-detail.component";
import {PrivacyPolicyComponent} from "./views/privacy-policy/privacy-policy.component";
import {LgpdComponent} from "./views/lgpd/lgpd.component";
import {WinnersComponent} from './views/winners/winners.component';
import {TechnicalVisitDetailComponent} from "./views/technical-visit-detail/technical-visit-detail.component";
import {StandardizationPageComponent} from "./views/standardization-page/standardization-page.component";

const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'recovery/:id', component: RecoveryComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {path: 'regulamento', component: PrivacyPolicyComponent},
    {path: 'politica', component: LgpdComponent},
    {
        path: '', component: MainComponent,
        children: [
            {path: '', component: HomeComponent},
            {path: 'home', component: HomeComponent},
            {path: 'campanha', component: CampaignComponent},
            {path: 'usuarios', component: UserComponent},
            {path: 'downloads', component: DownloadsComponent},
            {path: 'time-preparado', component: EngagedResellerComponent},
            {path: 'visita-tecnica', component: TechnicalVisitComponent},
            {path: 'visita-tecnica/:id', component: TechnicalVisitDetailComponent},
            {path: 'padronizacao', component: StandardizationPageComponent},
            {path: 'premios', component: PrizesComponent},
            {path: 'ranking', component: RankingComponent},
            {path: 'ranking-detalhe', component: RankingDetailComponent},
            {path: 'ranking-list-extra', component: RankingListExtraComponent},
            {path: 'resgate', component: PrizeDetailComponent},
            {path: 'ganhadores', component: WinnersComponent},
            {path: 'noticias', component: NewsComponent},
            {path: 'noticias-detalhe/:id', component: NewsDetailComponent},
            {path: 'faq', component: FaqComponent},
            {path: 'contato', component: ContactComponent},
        ],
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        relativeLinkResolution: 'legacy',
        scrollOffset: [0, 0],
        scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
