<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg text-left">Noticías</p>
        <div class="container">
            <h2 class="page_header_title">Noticías</h2>
        </div>
    </div>
</div>
<div class="hero-banner">
    <img [src]="getImageURL()+ 'news/image/'+news.pathImage" class="hero-banner-thumb" alt="{{news.title}}"/>
</div>
<div class="container container-hero mt-5">
    <div class="row mb-5" style="row-gap: 20px">
        <div class="col-lg-2 position-relative text-right">
            <a routerLink="/noticias" class="btn btn-outline-secondary back_button">
                <div class="d-flex align-items-center" style="gap: 20px">
                    <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                    <p class="mb-0">Voltar</p>
                </div>
            </a>
        </div>
        <div class="col">
            <!--<div class="mb-4">
                <img [src]="getImageURL()+ 'news/image/'+news.pathImage" class="img-fluid w-100" alt="{{news.title}}"/>
            </div>-->
            <h2 class="text-uppercase text-primary">{{news.title}}</h2>
            <p class="main_news_text"><small>Publicada em {{news.createdAt | date: 'dd/MM/yyyy'}}</small></p>
            <div class="main_news_text" [innerHTML]="news.text"></div>
        </div>
        <div class="d-none d-lg-block col-2">&nbsp;</div>
    </div>
    <div class="d-flex align-items-center mb-5" style="gap: 10px">
        <span class="news-detail_date">{{news.createdAt|date:'dd/MM/yyyy':'UTC-0'}}</span>
        <div class="divider">&nbsp;</div>
    </div>

    <div class="carousel" id="slick-news">

        <div *ngFor="let n of newsPage.news">
            <div class="home-news_item">
                <div class="home-news_thumb">
                    <img src="{{this.getImageURL() + 'news/image/' +n.pathImage}}" alt="image">
                    <p>{{n.title}}</p>
                </div>
                <div class="px-3">
                    <p class="home-news_text" [innerHTML]="n.text"></p>
                    <div class="row justify-content-between align-items-center">
                        <div class="col-auto">
                            <p class="home-news_date mb-0">{{n.createdAt | date:'dd/MM/yyyy | HH:mm':'UTC-0'}}</p>
                        </div>
                        <div class="col-auto">
                            <a class="home-news_link" [routerLink]="'/noticias-detalhe/' + n.id">Leia mais</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


