<div class="footer ">
    <div class="container-xxl">
        <div class="row my-3" style="row-gap: 20px">
            <div class="col-sm-3 d-flex flex-column justify-content-between">
                <div class="row no-gutters h-100">
                    <div class="col-6 col-md-12">
                        <a class="footer_value" [routerLink]="['/regulamento']" href="javascript:void('')">Regulamento</a>
                    </div>
                    <!--                    <div class="col-12">-->
                    <!--                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/lgpd']">LGPD</a>-->
                    <!--                    </div>-->
                    <div class="col-6 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/contato']">Fale Conosco</a>
                    </div>
                    <div class="col-6 col-md-12">
                        <a class="footer_value" href="javascript:void('')" [routerLink]="['/politica']">Politica
                            de
                            privacidade</a>
                    </div>
                    <div class="col-6 col-md-12">
                        <a class="footer_value " href="javascript:void('')" [routerLink]="['/downloads']">Downloads</a>
                    </div>
                </div>

            </div>
            <div class="col-sm-3 d-flex flex-column justify-content-between">
                <dl class="row">
                    <dt class="col-12 footer_lbl">SAC:</dt>
                    <dd class="col-12 footer_value">Segunda a sexta-feira das 9h às 18h</dd>
                </dl>
                <dl class="row">
                    <dt class="col-12 footer_lbl">Contato:</dt>
                    <dd class="col-12 mb-0 footer_value">sac@sistechpro.com.br</dd>
                </dl>
            </div>
            <div class="col-sm-3">
                <dl class="row w-100">
                    <dt class="col-12 footer_lbl">Período de participação</dt>
                    <dd class="col-12 footer_value">01/10/2022 a 28/02/2023</dd>
                </dl>
                <div class="divider">&nbsp;</div>
                <dl class="row align-items-center" style="row-gap: 5px">
                    <dt class="col-lg-5 footer_value mb-0">Visite nossas redes sociais</dt>
                    <dd class="col-lg-7 mb-0">
                        <div class="row justify-content-md-around mx-n1">
                            <div class="col-auto px-1">
                                <a href="https://www.facebook.com/sistechpro" target="_blank">
                                    <img src="assets/images/facebook.png" alt="facebook">
                                </a>
                            </div>
                            <div class="col-auto px-1">
                                <a href="https://www.linkedin.com/company/lindal-group/" target="_blank">
                                    <img src="assets/images/linkedin.png" alt="linkedin">
                                </a>
                            </div>
                        </div>
                    </dd>
                </dl>
            </div>
            <div class="col-sm-3 d-flex align-items-center justify-content-center">
                <img src="assets/images/logo-dark-small.png" alt="logo">
            </div>
        </div>
    </div>
</div>

