import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {
    FindAllRangeGQL,
    GmrAdmin,
    Range,
    GmrFindAllGQL,
    RankingInput,
    RankingMyPositionGQL,
    RankingMyPositionQuery,
    RankingPosition, RankingResale, RankingResaleGQL, RankingResaleInput, RankingResaleQuery,
    User, RankingResaleSalesGoalGQL, RankingSalesGoal, RankingResalePerformanceGQL, RankingResalePerformance, GmrRegion, RegionFindAllGQL
} from '../../../generated/graphql';
import moment from "moment";
import {QueryRef} from "apollo-angular";
import {Month} from "../../model/month";
import {RankingService} from "../../service/ranking.service";

declare var $: any;

@Component({
    selector: 'app-ranking',
    templateUrl: './ranking.component.html',
    styleUrls: ['./ranking.component.scss']
})
export class RankingComponent extends BaseComponent implements OnInit {
    user: User = new User();
    rankingInput: RankingInput = new RankingInput();
    rankingResaleInput: RankingResaleInput = new RankingResaleInput();

    rankingMyPositionQuery: QueryRef<RankingMyPositionQuery>;
    rankingResaleQuery: QueryRef<RankingResaleQuery>;

    rankingPosition: RankingPosition = new RankingPosition();
    rankingResales: RankingResale[] = [];
    gmrFindAll: GmrAdmin[] = [];
    regionFindAll: GmrRegion[] = [];
    findAllRange: Range[] = [];
    handlerRankingResaleBool: boolean = false;
    handlerRankingResaleBoolRange: boolean = false;
    handlerRankingResaleExcelBool: boolean = false;
    rankingResaleGoal: RankingSalesGoal[] = [];
    rankingResalePerformance: RankingResalePerformance[] = [];
    indexMount: number;

    constructor(public router: Router,
                public userService: UserService,
                public rankingMyPositionGQL: RankingMyPositionGQL,
                public rankingResaleGQL: RankingResaleGQL,
                public gmrFindAllGQL: GmrFindAllGQL,
                public regionFindAllGQL: RegionFindAllGQL,
                public findAllRangeGQL: FindAllRangeGQL,
                public rankingResaleSalesGoalGQL: RankingResaleSalesGoalGQL,
                public rankingResalePerformanceGQL: RankingResalePerformanceGQL,
                public rankingService: RankingService,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.user = data as User;
                    this.configFilter();
                    this.rankingMyPosition();
                    this.rankingResaleFilter();
                    this.rankingResaleSalesGoal();
                    this.rankingPerformance();
                }
            }, error: err => super.onError(err)
        });

        $(() => {
            // $('#modalRankingInformation').modal('show');
        });

    }

    configFilter() {
        this.indexMount = this.listOfMonths.map((e) => e.id).indexOf(moment().month() + 1);
        console.log(this.indexMount);
        if (this.indexMount == -1) {
            this.rankingInput.month = this.listOfMonths[4].id;
            this.rankingInput.year = this.listOfMonths[4].year;

            this.rankingResaleInput.month = this.listOfMonths[4].id;
            this.rankingResaleInput.year = this.listOfMonths[4].year;
        } else {
            this.rankingInput.month = this.listOfMonths[this.indexMount - 1].id; // mes anterior
            this.rankingInput.year = this.listOfMonths[this.indexMount - 1].year; // mes anterior

            this.rankingResaleInput.month = this.listOfMonths[this.indexMount - 1].id; // mes anterior
            this.rankingResaleInput.year = this.listOfMonths[this.indexMount - 1].year; // mes anterior
        }

        this.handlerRankingResaleBool = this.handlerRankingResale();
        this.handlerRankingResaleBoolRange = this.handlerRankingResaleRange();
        this.handlerRankingResaleExcelBool = this.handlerRankingResaleExcel();
    }

    handlerRankingResale() {
        return this.user.profile.id.toLowerCase() === 'ma';
    }

    handlerRankingResaleRange() {
        return this.user.profile.id.toLowerCase() === 'ma' || this.user.profile.id.toLowerCase() === 'ec';
    }

    handlerRankingResaleExcel() {
        return this.handlerRankingResaleBool || this.user.profile.id.toLowerCase() === 'ec';
    }

    handlerTraining(l: Month) {
        if (l.active) {
            this.rankingInput.month = l.id;
            this.rankingInput.year = l.year;

            this.rankingResaleInput.month = l.id;
            this.rankingResaleInput.year = l.year;

            this.rankingMyPositionQuery.refetch({input: this.rankingInput}).then();
            this.rankingResaleQuery.refetch({input: this.rankingResaleInput}).then();
        }
    }

    onSearch() {
        this.rankingResaleQuery.refetch({input: this.rankingResaleInput}).then();
    }

    rankingMyPosition() {
        this.rankingMyPositionQuery = this.rankingMyPositionGQL.watch({input: this.rankingInput});
        this.rankingMyPositionQuery.valueChanges.subscribe(({data}) => {
            this.rankingPosition = data.rankingMyPosition as RankingPosition;
        }, error => super.onError(error));
    }

    rankingResaleFilter() {
        this.regionFindAllGQL.watch().valueChanges.subscribe(({data}) => {
            this.regionFindAll = data.regionFindAll as GmrRegion[];
            // VALID IF USER HAS REGION
            if (!this.isNullOrUndefined(this.user.gmr?.region)) {
                this.rankingResaleInput.region = this.user.gmr!.region;
            } else {
                this.rankingResaleInput.region = this.regionFindAll[0].name;
            }
            this.findRange();
        }, error => super.onError(error));


    }

    findRange() {
        this.findAllRangeGQL.watch().valueChanges.subscribe(({data}) => {
            this.findAllRange = data.findAllRange as Range[];
            // VALID IF USER HAS RANGE
            if (!this.isNullOrUndefined(this.user.range?.id)) {
                this.rankingResaleInput.rangeId = this.user.range!.id;
            } else this.rankingResaleInput.rangeId = this.findAllRange[0].id;

            this.rankingResale();
        }, error => super.onError(error));
    }

    rankingResale() {
        this.rankingResaleQuery = this.rankingResaleGQL.watch({input: this.rankingResaleInput});
        this.rankingResaleQuery.valueChanges.subscribe(({data}) => {
            this.rankingResales = data.rankingResale as RankingResale[];
        }, error => super.onError(error));
    }

    rankingResaleSalesGoal() {
        this.rankingResaleSalesGoalGQL.watch().valueChanges.subscribe(({data}) => {
            this.rankingResaleGoal = data.rankingResaleSalesGoal as RankingSalesGoal[];
        }, error => super.onError(error));
    }

    rankingPerformance() {
        this.rankingResalePerformanceGQL.watch().valueChanges.subscribe(({data}) => {
            this.rankingResalePerformance = data.rankingResalePerformance as RankingResalePerformance[];
        }, error => super.onError(error))
    }

    caseCategory(category: string) {
        switch (category) {
            case 'salesValume':
                return 'ΣΔV';
            case 'adimplance':
                return 'ΣAd';
            case 'extraMechanics':
                return 'ME';
            case 'technicalVisit':
                return 'VT';
            case 'result':
                return 'Resultado';
            default:
                return category;
        }
    }

    getExcel() {
        this.toggleLoader(true);
        this.rankingService.getExcel(this.rankingResaleInput).subscribe({
            next: data => {
                this.toggleLoader(false);
                this.downloadHandlerFile(data, 'Ranking');
            }, error: err => {
                super.onError(err);
                this.toggleLoader(false);
            }
        });
    }

    downloadHandlerFile(e: any, fileName: string) {
        const downLoad = $('#btnDownload');
        downLoad.attr('href', e);
        downLoad.attr('download', fileName + '_' + moment().format('DD-MM-YYYY'));
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }
}
