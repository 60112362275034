import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';

declare var $: any;

@Component({
  selector: 'app-lgpd',
  templateUrl: './lgpd.component.html',
  styleUrls: ['./lgpd.component.scss']
})
export class LgpdComponent extends BaseComponent implements OnInit {

  constructor(public router: Router,
              public translate: TranslateService,
              public userService: UserService
  ) {
    super(router, translate);
  }

  ngOnInit(): void {
    $(() => {
      $('body').removeClass('body-inside');
    });
  }

  getAuthenticated() {
    return this.userService.getStatusAuthenticated();
  }

}
