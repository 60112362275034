<!-- Modal CROP -->
<div class="modal fade modal-crop" [id]="cropId" tabindex="-1" role="dialog" aria-labelledby="moreLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex align-items-center">
                    <svg-icon class="svg-icon-primary" src="assets/svg/addimage.svg"></svg-icon>
                    <h3 class="modal-title ml-3">
                        <strong class="text-primary">{{'Enviar imagem'|translate}}</strong>
                    </h3>
                </div>

                <a class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body align-items-center">
                <div class="row align-items-end w-100 mb-3" *ngIf="!isNullOrUndefined(imageChangedEvent)">
                    <div class="col-auto">
                        <div class="crop_description" (click)="onReset()">
                            <svg-icon class="svg-icon-primary crop_icon"
                                      src="assets/svg/addimage.svg"></svg-icon>
                            <span class="crop_text">Nova imagem</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="crop_description" (click)="onImageRotate()">
                            <svg-icon class="svg-icon-primary crop_icon"
                                      src="assets/svg/rotate.svg"></svg-icon>
                            <span class="crop_text">Rotacionar</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="crop_description" (click)="onImageSave()">
                            <svg-icon class="svg-icon-primary crop_icon"
                                      src="assets/svg/disquete.svg"></svg-icon>
                            <span class="crop_text">Salvar</span>
                        </div>
                    </div>
                </div>
                <label class="description__photo" *ngIf="isNullOrUndefined(imageChangedEvent)">
                    <img src="assets/images/prevent-upload.png" alt="Upload" class="img-fluid"/>
                    <input type="file" class="description__photo--file" name="profile_photo"
                           onclick="this.value = null" accept="image/png, image/jpeg, image/jpg"
                           (change)="fileChangeEvent($event)">
                </label>
                <image-cropper
                        *ngIf="!isNullOrUndefined(imageChangedEvent)"
                        [format]="'png'"
                        [imageQuality]="40"
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="!isNullOrUndefined(cropAspectRatio)"
                        [aspectRatio]="isNullOrUndefined(cropAspectRatio) ? null : cropAspectRatio"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"
                        [canvasRotation]="cropCanvasRotation"
                        [resizeToWidth]="isNullOrUndefined(cropResizeToWidth) ? null : cropResizeToWidth"
                        [resizeToHeight]="isNullOrUndefined(cropResizeToHeight) ? null : cropResizeToHeight"
                ></image-cropper>
            </div>
        </div>
    </div>
</div>
