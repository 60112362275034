import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    UpdateTechnicalVisitGasStationGQL,
    DetailsTechnicalVisitStandardizationResaleGQL,
    TechnicalVisitGasStationInput,
    TechnicalVisitStandardizationDetails,
    DetailsTechnicalVisitStandardizationResaleQuery,
    TechnicalVisitDetails,
    SaveTechnicalVisitStandardizationResaleGQL,
    TechnicalVisitStandardizationSaveInput,
    TechnicalVisitTransmitInput,
    UserCouponGQL, UserCouponDto
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";

@Component({
    selector: 'app-standardization-page',
    templateUrl: './standardization-page.component.html',
    styleUrls: ['./standardization-page.component.scss']
})
export class StandardizationPageComponent extends BaseComponent implements OnInit {
    standardizationDetails: TechnicalVisitStandardizationDetails = new TechnicalVisitStandardizationDetails();
    gasStationInput: TechnicalVisitGasStationInput = new TechnicalVisitGasStationInput();
    detailsStandardizationQuery: QueryRef<DetailsTechnicalVisitStandardizationResaleQuery>;
    validateUploadFile: boolean = true;
    userCoupon: UserCouponDto = new UserCouponDto();


    constructor(public router: Router,
                private detailsTechnicalVisitStandardizationResaleGQL: DetailsTechnicalVisitStandardizationResaleGQL,
                private saveTechnicalVisitStandardizationResaleGQL: SaveTechnicalVisitStandardizationResaleGQL,
                private updateTechnicalVisitGasStationGQL: UpdateTechnicalVisitGasStationGQL,
                private userCouponGQL: UserCouponGQL,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
        this.userCouponGQL.watch().valueChanges.subscribe(({data}) => {
            this.userCoupon = data.userCoupon as UserCouponDto;
        }, error => super.onError(error));
        this.detailsStandardizationQuery = this.detailsTechnicalVisitStandardizationResaleGQL.watch();
        this.detailsStandardizationQuery.valueChanges.subscribe(({data}) => {
            this.standardizationDetails = data.detailsTechnicalVisitStandardizationResale as TechnicalVisitStandardizationDetails;
            this.validateUploadFile = this.standardizationDetails.status.toLowerCase() !== "pendente" && this.standardizationDetails.status.toLowerCase() !== "revisar"
            this.gasStationInput = new TechnicalVisitGasStationInput();
            this.gasStationInput.gasStation = this.standardizationDetails.gasStation;
            this.gasStationInput.technicalVisitId = this.standardizationDetails.technicalVisitId;
            if (this.isNullOrUndefined(this.standardizationDetails.gasStation)) {
                this.showModal('modalGasStationStandPage');
            }
        }, error => super.onError(error));
        super.showMessage('Atenção', 'O prazo para enviar a comprovação da Padronização da revenda e frota foi encerrado em 10/02/2023, aguarde a apuração.', 'warning');
    }

    handlerGasStation(gasStation: boolean) {
        super.finishMessageStandardization();
        return;

        if (this.validateUploadFile) {
            return;
        }
        this.gasStationInput = new TechnicalVisitGasStationInput();
        this.gasStationInput.technicalVisitId = this.standardizationDetails.technicalVisitId;
        this.gasStationInput.gasStation = gasStation;
        this.gasStationInput.isClearStandardizationItem = true;
        super.confirmMessage('Atenção', 'Isso apagará os arquivos de sua matriz, deseja continuar ?', () => {
            this.showModal('modalGasStation');
        });
    }

    refetchDetail() {
        this.detailsStandardizationQuery.refetch().then();
    }

    saveReport() {
        super.finishMessageStandardization();
        return;

        super.confirmMessage('Atenção', 'Deseja realmente enviar esse relatório?', () => {
            let input: TechnicalVisitStandardizationSaveInput = new TechnicalVisitStandardizationSaveInput();
            input.technicalVisitId = this.standardizationDetails.technicalVisitId;
            input.isForceSave = false;
            this.saveTechnicalVisitStandardizationResaleGQL.mutate({input: input}).subscribe(({data}) => {
                if (data.saveTechnicalVisitStandardizationResale.status == 1) {
                    super.confirmMessage('Atenção', data.saveTechnicalVisitStandardizationResale.message + ' Deseja enviar mesmo assim?', () => {
                        input.isForceSave = true;
                        this.saveTechnicalVisitStandardizationResaleGQL.mutate({input: input}).subscribe(({data}) => {
                            this.finishReport();
                        }, error => super.onError(error));
                    });
                } else {
                    this.finishReport();
                }
            }, error => super.onError(error))
        });

    }


    finishReport() {
        super.finishMessageStandardization();
        return;

        this.showMessage('Sucesso!', 'Padronização enviado com sucesso', 'success');
        this.detailsStandardizationQuery.refetch().then();
    }

    changeGasStation() {
        super.finishMessageStandardization();
        return;

        if (this.validateUploadFile) {
            return;
        }
        if (!this.gasStationInput.gasStation) {
            this.gasStationInput.isFacade = true;
        }
        if (this.isNullOrUndefined(this.gasStationInput.gasStation)) {
            super.showMessage('Atenção', 'Escolha um tipo de revenda', 'warning');
            return;
        }
        if (this.isNullOrUndefined(this.gasStationInput.isFacade) || this.isNullOrUndefined(this.gasStationInput.isFleet)) {
            super.showMessage('Atenção', 'Responda todas as perguntas para prosseguir', 'warning');
            return;
        }
        this.gasStationInput.isClearStandardizationItem = false;
        this.updateTechnicalVisitGasStationGQL.mutate({input: this.gasStationInput}).subscribe(({data}) => {
            this.closeModal('modalGasStationStandPage');
            this.detailsStandardizationQuery.refetch().then();
        }, error => super.onError(error));
    }
}
