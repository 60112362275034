<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">RANKING DETALHE</p>
        <div class="container">
            <h2 class="page_header_title">RANKING <span>DETALHADO</span></h2>
        </div>
    </div>
</div>
<div class="container">
    <div class="row mt-3">
        <div class="col-2">
            <div [routerLink]="'/ranking'" class="btn-back btn btn-outline-secondary d-flex">
                <svg-icon src="assets/svg/back.svg"></svg-icon>
                Voltar
            </div>
        </div>
        <div class="offset-md-2 col-md">
            <div class="input-material select mb-3">
                <select [disabled]="!handlerMasterBool" class="form-control"
                        [(ngModel)]="this.rankingResaleInput.region"
                        id="region" name="region">
                    <option *ngFor="let x of regionFindAll" [value]="x.name">{{x.name}}</option>
                </select>
                <label class="disabled" for="region">Regiao</label>
            </div>
        </div>
        <div class="col-md">
            <div class="input-material select mb-3">
                <select [disabled]="!handlerCommercialBool" class="form-control" id="range"
                        name="range"
                        [(ngModel)]="this.rankingResaleInput.rangeId">
                    <option *ngFor="let x of findAllRange" [value]="x.id">{{x.name}}</option>
                </select>
                <label class="disabled" for="range">Faixa</label>
            </div>
        </div>
        <div class="col-md-2" *ngIf="this.handlerCommercialBool">
            <a (click)="onSearchPage(1)" class="btn btn-block btn-secondary">Buscar</a>
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col-md-9">
            <div class="position-relative my-4">
                <div class="row h-100 mx-n1 justify-content-between align-content-center" style="row-gap: 30px">
                    <div class="px-1 position-relative" (click)="handlerTraining(x)"
                         *ngFor="let x of listOfMonths;let i = index">
                        <div class="home-training_btn" [ngClass]="{'active':x.id == rankingResaleInput.month}">
                            <p class="home-training_lbl">{{x.name}}</p>
                            <p class="home-training_lbl home-training_lbl-sm">{{x.date}}</p>
                        </div>
                        <svg-icon class="home-training_arrow home-training_arrow-ranking"
                                  [ngClass]="{'active':x.id == rankingResaleInput.month}"
                                  src="assets/svg/arrow-training.svg"></svg-icon>
                    </div>
                </div>
                <div class="home-training_bar home-training_bar-ranking">&nbsp;</div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="dark-card">
                <span>ACUMULADO</span>
                <b>{{numberWithCommas(rankingResalePage.accumulatorScore)}}</b>
            </div>
        </div>
        <div class="col-12">
            <div class="legend">
                <span class="legend-item"><strong>ΔV</strong> | Variação de vendas</span>
                <span class="legend-item"><strong>Ad</strong> | Adimplência</span>
                <span class="legend-item"><strong>VT</strong> | Visita técnica</span>
                <span class="legend-item"><strong>ME</strong> | Mecânica de Engajamento</span>
            </div>
            <div class="legend">
                <span class="legend-item"><strong>IMPORTANTE:</strong> A pontuação na Visita Técnica (VT) será aplicada somente ao final da campanha.</span>
            </div>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="text-primary" scope="col">Posição</th>
                        <th class="text-primary" scope="col">GMR</th>
                        <th class="text-primary text-center" scope="col">Faixa</th>
                        <th class="text-primary" scope="col">Revenda</th>
                        <th class="text-primary text-center" scope="col">ΔV</th>
                        <th class="text-primary text-center" scope="col">Ad</th>
                        <th class="text-primary text-center" scope="col">VT</th>
                        <th class="text-primary text-center" scope="col">ME</th>
                        <th class="text-primary text-center" scope="col">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="isNullOrUndefined(this.rankingResalePage.resales)">
                        <td class="text-center" colspan="8">Nenhum registro encontrado</td>
                    </tr>
                    <tr *ngFor="let x of this.rankingResalePage.resales|
                        paginate:{id:'rankingDetail',itemsPerPage: rankingResalePage.PageSize,
                        currentPage: rankingResalePage.currentPage, totalItems: rankingResalePage.totalCount }">
                        <td>{{x.position}}º</td>
                        <td>{{x.gmr}}</td>
                        <td class="text-center">{{x.rangeId}}</td>
                        <td>{{x.companyName}}</td>
                        <td class="text-center">{{x.salesVolume}}</td>
                        <td class="text-center">{{x.adimplance}}</td>
                        <td class="text-center">{{x.TechnicalVisit}}</td>
                        <td class="text-center">{{x.extraMechanics}}</td>
                        <td class="text-center">{{numberWithCommas(x.finalScore)}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-right">
                <app-pagination-custom
                        [id]="'rankingDetail'"
                        (pageChange)="onSearchPage($event)"
                ></app-pagination-custom>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalRankingInfo" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalRankingInfoLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="max-width: 560px">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <h3 class="modal-title modal_title">ATENÇÃO</h3>
            </div>
            <div class="modal-body pt-0 bg-secondary">
                <div class="row">
                    <div class="col-12 text-white">
                        <p><strong>IMPORTANTE:</strong> A pontuação da Visita Técnica (VT) só será somada no seu ranking ao final da campanha, em Fevereiro/2023.</p>
                        <p>Consulte a seguir a sua pontuação de <strong>(ΔV) Variação de vendas | (Ad) Adimplência | (ME) Mecânica de Engajamento</strong>.</p>
                    </div>
                </div>
                <div class="row justify-content-center mt-4">
                    <div class="col-sm-7">
                        <a class="btn w-100 h-100 align-text-center btn-white" data-dismiss="modal" aria-label="Close">
                            <span>Fechar</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
