<div class="login_bg">
    <div class="container">
        <div class="row no-gutters align-items-center" style="height: 100vh">
            <div class="col d-none d-lg-block">
                <img style="width: 95%;" src="assets/images/logo.png" alt="logo">
            </div>
            <div class="col-lg-3">
                <div class="pr-lg-4">
                    <div class="d-flex flex-column align-items-center" style="margin-bottom: 66px">
                        <img src="assets/images/logo-login.png" alt="logo">
                    </div>
                    <p class="login_lbl">Digite sua nova senha</p>
                    <div class="input-material">
                        <input class="form-control" type="password" id="password" [(ngModel)]="input.newPassword"
                               [type]="isEyeBool?'text':'password'" autocomplete="off" name="password" maxlength="100"
                               required>
                        <svg-icon (click)="isEyeBool=!isEyeBool" class="right"
                                  src="assets/svg/eye-password.svg"></svg-icon>
                        <label for="password">Senha</label>
                    </div>
                    <div class="input-material">
                        <input class="form-control" type="password" autocomplete="off" [(ngModel)]="confirmPassword"
                               [type]="isEyeBoolConfirm?'text':'password'" name="confirmPassword" id="confirmPassword"
                               required>
                        <svg-icon (click)="isEyeBoolConfirm=!isEyeBoolConfirm" class="right"
                                  src="assets/svg/eye-password.svg"></svg-icon>
                        <label for="confirmPassword">Confirmar Senha</label>
                    </div>

                    <a href="javascript:void(0);" class="btn btn-block btn-tertiary text-white mb-4"
                       (click)="onSave()"> Redefinir Senha </a>
                </div>
            </div>
        </div>
    </div>
</div>
