import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    Contact,
    CreateContactInput,
    CreateContactGQL,
    SubjectGQL, Subject, User,
} from "../../../generated/graphql";
import {error} from "selenium-webdriver";
import {UserService} from "../../service/user.service";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends BaseComponent implements OnInit {
    createcontact: CreateContactInput = new CreateContactInput();
    subjectList: Subject[] = [];
    user: User = new User();

    constructor(public router: Router,
                public createContactGQL: CreateContactGQL,
                public subjectGQL: SubjectGQL,
                public userService: UserService,
                public translate: TranslateService,) {
        super(router, translate)
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.user = data as User;
                }
            }, error: err => super.onError(err)
        });
        this.subjectGQL.watch().valueChanges.subscribe(({data}) => {
            this.subjectList = data.subject as Subject[];
        }, error => super.onError(error))
    }

    actionContact() {
        const arrValidateFields = [
            {value: this.createcontact.name, text: `Nome Completo* <br>`},
            {value: this.createcontact.phone, text: `DDD + Telefone Fixo* <br>`},
            {value: this.createcontact.email, text: `E-mail* <br>`},
            {value: this.createcontact.subjectId, text: `Assunto* <br>`},
            {value: this.createcontact.message, text: `Mensagem* <br>`},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br> ${stringError}`);
            return;
        }
        this.createcontact.profile = this.user.profile?.id;
        this.createcontact.jobRole = this.user.resaleJobRole?.id;

        this.createContactGQL.mutate({input: this.createcontact}).subscribe(({data}) => {
            this.showMessage('Sucesso!', data.createContact.message, 'success');
            this.createcontact = new CreateContactInput();
        }, error => super.onError(error));

    }

}
