import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    AuthType,
    ChangePasswordGQL,
    ChangePasswordInput,
    Gmr,
    Profile,
    Range,
    UpdateUserFirstAccessGQL, UpdateUserFirstAccessInput, UpdateUserGQL,
    UpdateUserInput,
    User
} from '../../../generated/graphql';
import {UploadService} from '../../service/upload.service';
import {UserService} from '../../service/user.service';
import {environment} from '../../../environments/environment';
import moment from 'moment';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit, OnChanges {
    step = 1;
    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;
    file: File = null;

    @Input()
    token: string;

    @Input()
    inputUser: User = new User();
    user: User = new User();

    @Output()
    saveProfile: EventEmitter<User> = new EventEmitter<User>();

    profile: Profile = new Profile();
    range: Range = new Range();
    gmr: Gmr = new Gmr();
    changePassword: ChangePasswordInput = new ChangePasswordInput();
    newPassword: any;
    newPasswordConfirm: string;
    emailConfirm: string;
    image_url: string;
    isValidateProfileBool: boolean;

    modelTerms: false;
    modelPolicy: false;


    constructor(public router: Router,
                public service: UserService,
                public updateUserFirstAccessGQL: UpdateUserFirstAccessGQL,
                public updateUserGQL: UpdateUserGQL,
                public uploadService: UploadService,
                public changePasswordGQL: ChangePasswordGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
        if (!this.isNullOrUndefined(this.inputUser)) {
            this.user = Object.assign({}, this.inputUser);
        }
        if (!this.isNullOrUndefined(this.user?.profile)) {
            this.profile = this.user.profile;
        }
        if (!this.isNullOrUndefined(this.user?.range)) {
            this.range = this.user.range;
        }
        if (!this.isNullOrUndefined(this.user?.gmr)) {
            this.gmr = this.user.gmr;
        }
        this.newPassword = undefined;
        this.newPasswordConfirm = undefined;
        this.emailConfirm = this.user.email;
        this.isValidateProfileBool = this.isValidateProfile();
    }

    ngOnInit(): void {
        this.user = new User();
        this.image_url = environment.image_url;
    }

    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.handleUpload(o.file);
    }

    handleUpload(event) {
        const files = event;
        if (files.length > 0) {
            /*if (files[0].size > 2000000) {
                super.showMessage('Atenção',
                    'O tamanho do arquivo ultrapassou o limite permitido.', 'info');
                return;
            }*/
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage('Atenção', 'Selecione uma imagem com as seguintes extensões: JPG, JPEG ou PNG',
                    'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(event[0]);
        this.file = files[0];
        // this.uploadImage();
    }

    onSave() {
        const arrValidateFields: any = [
            {value: this.user.name, text: `Nome Completo <br>`},
            {value: this.user.cpf, text: `CPF <br>`},
            {value: this.user.birthday, text: `Data de Nascimento <br>`},
            {value: this.user.email, text: `E-mail <br>`},
            {value: this.user.phone, text: `Telefone com DDD <br>`}
        ];

        if (this.isNullOrUndefined(this.user.activatedAt) && (!this.modelTerms || !this.modelPolicy)) {
            arrValidateFields.push({value: null, text: `Aceite o regulamento <br>`});
            arrValidateFields.push({value: null, text: `Aceite a política de privacidade <br>`});
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br> ${stringError}`);
            return;
        }

        if (Math.floor(moment(new Date()).diff(moment(this.user.birthday, 'DD/MM/YYYY'), 'years', true)) < 18) {
            this.showMessage('Atenção', `É permitido apenas cadastro de maiores de 18 anos.`);
            return;
        }

        if (this.isNullOrUndefined(this.emailConfirm)) {
            this.showMessage('Atenção', `Confirme o E-mail para prosseguir`);
            return;
        }

        if (this.user.email != this.emailConfirm) {
            this.showMessage('Atenção', `E-mails não confere!`);
            return;
        }


        if (this.isNullOrUndefined(this.token)) {
            this.updateUser();
        } else {
            this.firstAccess();
        }
    }

    updateUser() {
        const d: UpdateUserInput = {
            id: this.user.id,
            birthday: moment(this.user.birthday, 'DD/MM/YYYY').utc(false).format('DD/MM/YYYY'),
            email: this.user.email,
            name: this.user.name,
            phone: this.user.phone,
        };
        this.updateUserGQL.mutate({input: d}).subscribe(({data}) => {
            if (!this.isNullOrUndefined(this.file)) {
                this.uploadService.upload(this.file, 'user-image', data.updateUser.id).subscribe(data2 => {
                    this.saveProfile.emit(data.updateUser as User);
                    this.closeModal('isProfile');
                    this.showMessage('Sucesso', 'Perfil atualizado com  sucesso!', 'success');
                }, error2 => super.onError(error2));
            } else {
                this.saveProfile.emit(data.updateUser as User);
                this.closeModal('isProfile');
                this.showMessage('Sucesso', 'Perfil atualizado com  sucesso!', 'success');
            }
        }, error => super.onError(error));
    }

    firstAccess() {
        if (this.isNullOrUndefined(this.newPassword)) {
            this.showMessage('Atenção', `Senha é obrigatório`);
            return;
        }
        if (this.isNullOrUndefined(this.newPasswordConfirm)) {
            this.showMessage('Atenção', `Confirme a senha para prosseguir`);
            return;
        }
        if (this.newPassword != this.newPasswordConfirm) {
            this.showMessage('Atenção', `Senhas não confere!`);
            return;
        }
        const d: UpdateUserFirstAccessInput = {
            id: this.user.id,
            password: this.newPassword,
            birthday: moment(this.user.birthday, 'DD/MM/YYYY').utc(false).format('DD/MM/YYYY'),
            email: this.user.email,
            name: this.user.name,
            phone: this.user.phone,
            cpf: this.user.cpf
        };
        this.setToken(this.token);
        this.updateUserFirstAccessGQL.mutate({input: d}).subscribe(({data}) => {
            this.service.updateUser(data.updateUserFirstAccess as User);
            this.service.getStatusAuthenticated();
            if (!this.isNullOrUndefined(this.file)) {
                this.uploadService.upload(this.file, 'user-image', data.updateUserFirstAccess.id).subscribe(data2 => {
                    this.closeModal('isProfile');
                    this.router.navigate(['/']).then();
                    this.showMessage('Sucesso', 'Cadastro de primeiro acesso realizado com  sucesso', 'success');
                }, error2 => super.onError(error2));
            } else {
                this.router.navigate(['/']).then();
                this.closeModal('isProfile');
                this.showMessage('Sucesso', 'Cadastro de primeiro acesso realizado com  sucesso', 'success');
            }
        }, error => super.onError(error));
    }

    onSavePassword() {
        const arrValidateFields = [
            {value: this.changePassword.password, text: `Senha atual <br>`},
            {value: this.changePassword.newPassword, text: `Nova senha <br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br> ${stringError}`);
            return;
        }

        if (this.changePassword.newPassword != this.newPasswordConfirm) {
            this.showMessage('Atenção', `Senhas não confere!`);
            return;
        }
        console.log(this.changePassword);
        this.changePasswordGQL.mutate({input: this.changePassword}).subscribe(({data}) => {
            this.closeModal('changePassword');
            this.showMessage('Sucesso', 'Senha alterada com  sucesso', 'success');
        }, error => super.onError(error));
    }

    isValidateProfile() {
        return this.isNullOrUndefined(this.user.activatedAt);
    }
}
