import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UploadService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    upload(file, type, id, idImage = null) {
        let url = `${this.BASE_URL}/upload/${type}/${id}`;
        if (idImage != null) {
            url = `${this.BASE_URL}/upload/${type}/${id}/${idImage}`;
        }
        return new Observable((observer) => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }
}
