import {Component, OnInit} from '@angular/core';
import {StepTechnicalVisit} from "./model/step-technical-visit";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    DetailsTechnicalVisitGQL,
    TechnicalVisitDetails,
    TechnicalVisitGasStationInput,
    TechnicalVisitImage,
    DetailsTechnicalVisitQuery,
    RemoveTechnicalVisitItemImageGQL,
    TechnicalVisitItemRemoveInput,
    SaveTechnicalVisitItemDateTrainingGQL,
    TechnicalVisitItemTrainingInput,
    TransmitTechnicalVisitGQL,
    TechnicalVisitTransmitInput
} from "../../../generated/graphql";
import {UploadService} from "../../service/upload.service";
import {QueryRef} from "apollo-angular";
import moment from "moment";

declare var $: any;

@Component({
    selector: 'app-technical-visit-detail',
    templateUrl: './technical-visit-detail.component.html',
    styleUrls: ['./technical-visit-detail.component.scss']
})
export class TechnicalVisitDetailComponent extends BaseComponent implements OnInit {
    parmId: string;
    listStep: StepTechnicalVisit[] = [
        {
            id: 1,
            icon: "assets/svg/computerized.svg",
            name: "Gestão Informatizada",
        },
        {
            id: 2,
            icon: "assets/svg/training.svg",
            name: "Treinamento"
        },
        {
            id: 3,
            icon: "assets/svg/security.svg",
            name: "Segurança nas Instalações"
        },
        {
            id: 4,
            icon: "assets/svg/security.svg",
            name: "Padronização"
        },
    ];
    stepSelected = 1;
    detailsTechnicalVisitQuery: QueryRef<DetailsTechnicalVisitQuery>;
    technicalVisit: TechnicalVisitDetails = new TechnicalVisitDetails();
    gasStationInput: TechnicalVisitGasStationInput = new TechnicalVisitGasStationInput();
    file: File = null;

    customers: TechnicalVisitImage = new TechnicalVisitImage();
    cashFlow: TechnicalVisitImage[] = [];
    image1: TechnicalVisitImage = new TechnicalVisitImage();
    image2: TechnicalVisitImage = new TechnicalVisitImage();
    validateUploadFile: boolean = true;


    constructor(public router: Router,
                public router2: ActivatedRoute,
                private detailsTechnicalVisitGQL: DetailsTechnicalVisitGQL,
                private removeTechnicalVisitItemImageGQL: RemoveTechnicalVisitItemImageGQL,
                private saveTechnicalVisitItemDateTrainingGQL: SaveTechnicalVisitItemDateTrainingGQL,
                private transmitTechnicalVisitGQL: TransmitTechnicalVisitGQL,
                private uploadService: UploadService,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.parmId = url.id;
                this.detailsTechnicalVisitQuery = this.detailsTechnicalVisitGQL.watch({input: this.parmId});
                this.detailsTechnicalVisitQuery.valueChanges.subscribe(({data}) => {
                    this.technicalVisit = data.detailsTechnicalVisit as TechnicalVisitDetails;
                    this.validateUploadFile = this.technicalVisit.status.toLowerCase() !== "pendente" && this.technicalVisit.status.toLowerCase() !== "revisar"
                    this.createList();
                    this.initGasStation();
                }, error => super.onError(error));
            }
        });
        super.showMessage('Atenção', 'O prazo para enviar a comprovação da Visita Técnica foi encerrado em 10/02/2023, aguarde a apuração.', 'warning');
    }

    validateUploadFileItem(item: TechnicalVisitImage) {
        return this.validateUploadFile || (item.status == 'approved' || item.status == 'disapproved');
    }

    handleTrainingUpload(event, itemId, idImage) {
        super.finishMessage();
        return;

        if (!this.isNullOrUndefined(this.technicalVisit.training.trainingDate)) {
            this.saveTraining();
        }
        this.handleUpload(event, itemId, idImage);
    }

    handleUpload(event, itemId, idImage) {
        super.finishMessage();
        return;

        const files = event.target.files;
        this.file = files[0];
        let type = files[0].name.toLowerCase().split('.');
        if (files.length > 0) {
            if (files[0].size > 40000000) {
                super.showMessage(this.translate.instant('profile.edit.warningAttention'),
                    this.translate.instant('profile.edit.warningUpload'), 'info');
                return;
            }

            if (type[type.length - 1].toLowerCase() !== 'jpg'
                && type[type.length - 1].toLowerCase() !== 'jpeg'
                && type[type.length - 1].toLowerCase() !== 'png'
                && type[type.length - 1].toLowerCase() !== 'pdf') {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'), 'extensão não suportada',
                    'info');
                return;
            }

            let reader = new FileReader();
            reader.onload = (_event) => {
                this.uploadFile(itemId, idImage);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
        $('#' + 'doc-1').val('');

    }

    handlerGasStation(t: TechnicalVisitDetails, gasStation: boolean) {
        super.finishMessage();
        return;

        if (this.validateUploadFile) {
            return;
        }
        this.gasStationInput = new TechnicalVisitGasStationInput();
        this.gasStationInput.technicalVisitId = t.technicalVisitId;
        this.gasStationInput.gasStation = gasStation;
        this.gasStationInput.isClearStandardizationItem = true;
        super.confirmMessage('Atenção', 'Isso apagará os arquivos de sua matriz em padronização, deseja continuar ?', () => {
            this.showModal('modalGasStation');
        });
    }

    uploadFile(itemId: string, idImage: string) {
        super.finishMessage();
        return;

        if (!this.isNullOrUndefined(this.file) && !this.isNullOrUndefined(itemId)) {
            this.uploadService.upload(this.file, 'technical-visit-image', itemId, idImage).subscribe(data => {
                this.detailsTechnicalVisitQuery.refetch({input: this.parmId}).then();
            }, error => super.onError(error));
        }
    }

    sendReport() {
        super.finishMessage();
        return;

        super.confirmMessage('Atenção', 'Deseja realmente enviar esse relatório?', () => {
            let input: TechnicalVisitTransmitInput = new TechnicalVisitTransmitInput();
            input.technicalVisitId = this.parmId;
            input.isForceSave = false;
            this.transmitTechnicalVisitGQL.mutate({input: input}).subscribe(({data}) => {
                const a: any = data;
                if (a.errors) {
                    this.showMessage('Atenção', a.errors[0].message, 'warning');
                    return;
                }
                if (data.transmitTechnicalVisit.status == 1) {
                    super.confirmMessage('Atenção', data.transmitTechnicalVisit.message + ' Deseja enviar mesmo assim?', () => {
                        input.isForceSave = true;
                        this.transmitTechnicalVisitGQL.mutate({input: input}).subscribe(({data}) => {
                            this.finishReport();
                        }, error => super.onError(error));
                    });
                } else {
                    this.finishReport();
                }
            }, error => super.onError(error));
        });
    }

    finishReport() {
        super.finishMessage();
        return;

        this.showMessage('Sucesso!', 'Relatório enviado com sucesso', 'success');
        this.detailsTechnicalVisitQuery.refetch({input: this.parmId}).then();
    }

    saveTraining() {
        super.finishMessage();
        return;

        const diff = moment(new Date()).diff(moment(this.technicalVisit.training.trainingDate, 'DD/MM/YYYY'), 'years', true);
        console.log(diff);
        if (diff > 1) {
            this.showMessage('Atenção', `Validade do certificado é superior a 1 ano. Inclua apenas certificado que esteja na validade de 1 ano.`);
            return;
        }
        if (diff < 0) {
            this.showMessage('Atenção', `Data do Certificado de treinamento ou cursos não pode ultrapassar a data de hoje`);
            return;
        }
        const input: TechnicalVisitItemTrainingInput = {
            technicalVisitItemId: this.technicalVisit.training.technicalVisitItemId,
            date: moment(this.technicalVisit.training.trainingDate, 'DD/MM/YYYY').utc(false).format('DD/MM/YYYY'),
        }
        this.saveTechnicalVisitItemDateTrainingGQL.mutate({input}).subscribe(({data}) => {
            this.detailsTechnicalVisitQuery.refetch({input: this.parmId}).then();
            this.showMessage('Sucesso', 'Data do treinamento salvo com sucesso!', 'success');
        }, error => super.onError(error));
    }

    initGasStation() {
        this.gasStationInput.technicalVisitId = this.technicalVisit.technicalVisitId;
        this.gasStationInput.gasStation = this.technicalVisit.gasStation;
    }

    removeFile(x: TechnicalVisitImage) {
        super.finishMessage();
        return;

        const input: TechnicalVisitItemRemoveInput = {
            technicalVisitItemImageId: x.id,
            nameImage: x.path,
        }
        super.confirmMessage('Atenção', 'Deseja realmente deletar esse arquivo?', () => {
            this.removeTechnicalVisitItemImageGQL.mutate({input}).subscribe(({data}) => {
                this.detailsTechnicalVisitQuery.refetch({input: this.parmId}).then();
                this.showMessage('Sucesso', 'Arquivo deletado com sucesso', 'success');
            }, error => super.onError(error));
        });

    }

    refetchDetail() {
        this.detailsTechnicalVisitQuery.refetch({input: this.parmId}).then();
    }

    createList() {
        this.customers = this.filterList(this.technicalVisit.computerizedManagent.items, 'listCustomers')[0];
        this.cashFlow = this.filterList(this.technicalVisit.computerizedManagent.items, 'cashFlow');
        this.image1 = this.filterList(this.technicalVisit.installationSafety.items, 'image1')[0];
        this.image2 = this.filterList(this.technicalVisit.installationSafety.items, 'image2')[0];
    }
}
