<!--<app-header *ngIf="getAuthenticated()"></app-header>-->

<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg text mb-0">Politica de privacidade</p>
        <div class="container">
            <h2 class="page_header_title">LEI GERAL De PROTEÇÃO DE DADOS PESSOAIS (LGPD)</h2>
        </div>
    </div>
</div>

<div class="container">
    <div class="d-block text-right">
        <a routerLink="/" class="btn btn-outline-secondary back_button my-3">
            <div class="d-flex align-items-center" style="gap: 20px">
                <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                <p class="mb-0">Voltar</p>
            </div>
        </a>
    </div>

    <div class="regulation_text">
        <p>
            A presente Política tem por finalidade demonstrar o compromisso da
            <br>
            <br>

            SistechProDistribuidora S/A (“Nós”)

            <br>
            <br>
            Endereço: Avenida Paulista, 1.842 – Cetenco Plaza, 1º, 2º e 3º Partes, 4º, 5º e 6º andares – Bela Vista, São Paulo/SP, CEP 01310-923

            <br>
            <br>
            CNPJ/MF: 60.886.413/0001-47

            <br>
            <br>
            Com a privacidade e proteção dos seus Dados Pessoais, além de estabelecer as regras sobre o Tratamento dos seus Dados Pessoais dentro do escopo dos serviços e funcionalidades oferecidas em nossas plataformas (“Plataformas”), disponibilizadas através do site http://www.liquigas.com.br, de acordo com as leis em vigor, com transparência e clareza junto a Você e ao mercado em geral.
            <br>
            <br>
            Como condição para acesso e uso das funcionalidades exclusivas de nossas Plataformas, Você declara que fez a leitura completa e atenta desta Política, estando plenamente ciente de sua integralidade e conferindo, assim, sua livre e expressa concordância com os termos aqui estipulados, incluindo a coleta dos Dados aqui mencionados, bem como sua utilização para os fins abaixo especificados. Caso Você não esteja de acordo com as disposições desta Política, Você deverá descontinuar o seu acesso ou uso da(s) Plataforma(s).
            <br>
            <br>
            NOTA ESPECIAL PARA CRIANÇAS E ADOLESCENTES COM IDADE INFERIOR A 18 ANOS
            <br>
            <br>
            Por favor, não se registre ou faça uso dos formulários e/ou serviços que exijam fornecimento de dados pessoais na Plataforma caso tenha menos de 18 anos.
            <br>
            <br>
            NOTA ESPECIAL PARA OS REPRESENTANTES LEGAIS
            <br>
            <br>
            Os representantes legais deverão supervisionar e representar as crianças e adolescentes com idade inferior a 16 (dezesseis) anos e assistir os adolescentes em idade superior a 16 (dezesseis) anos e inferior a 18 (dezoito) anos, sempre que aplicável.
            <br>
            <br>
            1.    Sobre os Dados que Coletamos
            <br>
            <br>
            1.1.    Como coletamos Dados. Dentre outras informações, os seus Pessoais poderão ser coletados quando Você os submete ou quando Você interage com nossas Plataformas e seus serviços, o que inclui:
            <br>
            <br>
            O que coletamos?	Para que coletamos?<br>
            Dados Cadastrais <br>
            Nome completo	(i) Identificar e autenticar Você. <br>
            CPF	(ii) Cumprir as obrigações decorrentes do uso dos nossos serviços. <br>
            E-mail	(iii) Ampliar nosso relacionamento, informando Você sobre novidades e noticias que consideramos relevantes pra voçê
            <br>
            Telefones para contato	(iv) Enriquecer sua experiência conosco e promover nossos produtos e serviços <br>
            CEP <br>
            Endereço <br>
            Número do PIS/NIT <br>
            Dados Bancários <br>
            Dados de Identificação Digital <br>
            Endereço UP e Porta Lógica de Origem	(i)    Identificar e autenticar Você. <br>
            Dispositivo (versão do sistema operacional)	(ii)    Cumprir com obrigações legais de manutenção de registros estabelecidas pelo Marco Civil da Internet (Lei nº12.965/2014).
            <br>
            Geolocalização	(iii) Cumprir nossas obrigações legais e regulatórias <br>
            Registros de data e horário cada ação que Você realizar	(iv)    Manter registro de nossas operações de tratamento de dados pessoais, conforme estabelecido pela Lei Geral de Proteção de Dados (Lei nº 13.709/2018).
            <br>
            ID da sessão	(v) Aprimorar nossas Plataformas <br>
            Sessões <br>
            Cookies <br>
            <br>
            1.2.    Dados necessários. Muitos de nossos serviços dependem diretamente de alguns Dados informados na tabela acima, principalmente Dados cadastrais, além de outros Dados que não são categorizados como Dados Pessoais (como razão social, CNPJ, inscrição estadual, etc.). Caso você opte por não fornecer alguns desses Dados, podemos ficar impossibilitados de prestar total ou parcialmente os serviços disponibilizados em nossas Plataformas a Você.
            <br>
            <br>
            1.3.    Atualização e Veracidade dos Dados. Você é o único responsável pela precisão, veracidade ou falta dela em relação aos Dados que você fornece ou pela sua desatualização. Fique atento pois é de sua responsabilidade garantir a exatidão ou mantê-los atualizados.
            <br>
            <br>
            1.3.1. Da mesma forma, Nós não somos obrigados a processar ou tratar quaisquer dos seus Dados Pessoais se houver razões para crer que tal processamento ou tratamento possa nos imputar qualquer infração de qualquer lei aplicável, ou se você estiver utilizando nossas Plataformas para quaisquer fins ilegais, ilícitos ou contrários à moralidade.
            <br>
            <br>
            1.4.    Base de Dados. A base de dados formada por meio da coleta de dados e de dados pessoais é de nossa propriedade e está sob nossa responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos dentro dos limites e propósitos dos negócios descritos nesta Política.
            <br>
            <br>
            1.5.    Tecnologias empregadas. Nós utilizamos cookies, pixel tags, armazenamento local e/ou outros identificadores para diversas funções, as quais estão descritas nesta Política. Você poderá gerenciar preferências de cookies e outras tecnologias a partir da configuração de seu navegador ou dispositivo, porém isso poderá impedir o funcionamento de determinadas funcionalidades de nossos sites, portais e aplicativos.
            <br>
            <br>
            1.5.1.    Você pode saber mais sobre cookies acessando nossa Política de Cookies.
            <br>
            <br>
            1.5.2.    Todas as tecnologias utilizadas respeitarão sempre a legislação vigente e os termos desta Política.
            <br>
            <br>
            1.6.    Nós não utilizamos nenhum tipo de decisão unicamente automatizada que impacte Você.
            <br>
            <br>
            2.    Como Compartilhamos Dados e Informações
            <br>
            <br>
            2.1.    Hipóteses de compartilhamento dos dados. Os Dados coletados e as atividades registradas podem ser compartilhados:
            <br>
            <br>
            (i)    Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determinação legal, requerimento, requisição ou ordem judicial;
            <br>
            <br>
            (ii)    Quando necessário às atividades comerciais e aos serviços prestados por Nós;
            <br>
            <br>
            (iii)    Com empresas parceiras para fins publicitários e estatísticos; e
            <br>
            <br>
            (iv)    De forma automática, em caso de movimentações societárias, como fusão, aquisição e incorporação.
            <br>
            <br>
            2.2.    Anonimização de Dados. Para as finalidades de pesquisas de inteligência de mercado e divulgação de dados à imprensa, os dados fornecidos por Você serão compartilhados de forma anonimizada, isto é, de forma que não possibilite a sua identificação.
            <br>
            <br>
            3.    Como Protegemos Seus Dados e Como Você Também Poderá Protegê-los
            <br>
            <br>
            3.1.    Compartilhamento de senhas. Você também é responsável pelo sigilo de seus Dados Pessoais e deve ter sempre ciência de que o compartilhamento de senhas viola esta Política e pode comprometer a segurança dos seus Dados e da(s) Plataforma(s).
            <br>
            <br>
            3.2.    Cuidados que Você dever tomar. É muito importante que Você proteja seus Dados contra acesso não autorizado ao seu computador, conta ou senha, além de se certificar de sempre clicar em “sair” ao encerrar sua navegação em um computador compartilhado. Também é muito importante que Você saiba que nós nunca enviaremos mensagens eletrônicas solicitando confirmação de dados ou com anexos que possam ser executados (extensões: .exe, .com, entre outros) ou ainda links para eventuais downloads.
            <br>
            <br>
            3.3.    Acesso aos Dados Pessoais, proporcionalidade e relevância. Internamente, os Dados Pessoais coletados pela SistechProsão acessados somente por profissionais devidamente autorizados, respeitando os princípios de proporcionalidade, necessidade e relevância para os objetivos do negócio, além do compromisso de confidencialidade e preservação da sua privacidade nos termos desta Política.
            <br>
            <br>
            3.4.    Links externos. Quando Você utilizar nossas Plataformas, Você poderá ser conduzido, via link, a outros portais ou plataformas externos a nosso domínio, e que poderão coletar suas informações e ter sua própria Política de Tratamento de Dados.
            <br>
            <br>
            3.4.1.    Caberá a Você ler a Política de Privacidade e Tratamento de Dados de tais portais ou plataformas fora do nosso ambiente, sendo de sua responsabilidade aceitá-la ou rejeitá-la. Nós não somos responsáveis pelas Políticas de Privacidade e Tratamento de Dados de terceiros e nem pelo conteúdo de quaisquer websites ou serviços ligados à ambientes que não os nossos.
            <br>
            <br>
            3.4.2.    Serviços de parceiros. Nós possuímos parceiros comerciais que, eventualmente, podem oferecer serviços por meio de funcionalidades ou sites que podem ser acessados a partir das Plataformas. Os Dados fornecidos por Você a estes parceiros serão de responsabilidade destes, estando assim sujeitos às suas próprias práticas de coleta e uso de dados.
            <br>
            <br>
            3.5.    Processamento por terceiros sob nossa diretriz. Caso empresas terceirizadas realizem, em nome da SistechPro, o tratamento em nosso nome de quaisquer Dados Pessoais que coletamos, as mesmas respeitarão as condições aqui estipuladas e as normas de segurança da informação, obrigatoriamente.
            <br>
            <br>
            3.6.    Comunicação por e-mail. Para otimizar e melhorar nossa comunicação, quando enviamos um e-mail para Você, podemos receber uma notificação quando eles são abertos, desde que esta possibilidade esteja disponível. É importante você ficar atento, pois os e-mails são enviados somente pelo domínio @liquigas.com.br.
            <br>
            <br>
            4.    Como Armazenamos seus Dados Pessoais e Registramos as Atividades
            <br>
            <br>
            4.1.    Os Dados Pessoais coletados e os registros de atividades são armazenados em ambiente seguro e controlado por um prazo mínimo que segue a tabela abaixo:
            <br>
            <br>
            Prazo de armazenameno	Fundamento legal <br>
            Dados cadastrais <br>
            5 anos após o término da relação	Arts. 12, 27 e 34 do Código de Defesa do Consumidor <br>
            Dados de Identificação digital <br>
            6 meses    Art. 15, Marco Civil da Internet <br>
            Outros Dados <br>
            Enquanto durar a relação e não houver pedido de apagamento ou revogação de consentimento	Art. 9, Inciso II da Lei Geral de Proteção de Dados Pessoais
            <br>
            <br>
            4.2.     Prazos de armazenamento superiores. Para fins de auditoria, segurança, controle de fraudes, proteção ao crédito e preservação de direitos, poderemos permanecer com o histórico de registro de seus Dados por prazo maior nas hipóteses que a lei ou norma regulatória assim estabelecer ou para preservação de direitos.
            <br>
            <br>
            4.3.    Os Dados coletados serão armazenados em nossos servidores localizados no Brasil, bem como em ambiente de uso de recursos ou servidores na nuvem (cloud computing), o que poderá exigir uma transferência e/ou processamento destes Dados fora do Brasil.
            <br>
            <br>
            5.    Seus Direitos e Como Exercê-los
            <br>
            <br>
            5.1.    Seus Direitos básicos. Você poderá solicitar ao nosso Encarregado de Dados Pessoais a confirmação da existência de tratamento de Dados Pessoais, além da exibição ou retificação de seus Dados Pessoais, por meio dos nossos Canais de Comunicação e, em especial, de nossa página dedicada a tal fim.
            <br>
            <br>
            5.2.    Limitação, oposição e exclusão de dados. Pelos Canais de Comunicação, Você pode também:
            <br>
            <br>
            (i)    Requerer a limitação do uso de seus Dados Pessoais;
            <br>
            <br>
            (ii)    Manifestar sua oposição e/ou revogar o consentimento quanto ao uso de seus Dados Pessoais; ou
            <br>
            <br>
            (iii)    Solicitar a exclusão de seus Dados Pessoais que tenham sidos coletados por Nós.
            <br>
            <br>
            5.2.1.    Se Você solicitar a exclusão de seus Dados Pessoais ou retirar seu consentimento para finalidades fundamentais ao regular funcionamento de qualquer das Plataformas e de seus serviços, tais ambientes e serviços poderão ficar indisponíveis para Você.
            <br>
            <br>
            5.2.2.    Caso Você solicite a exclusão de seus Dados Pessoais, pode ocorrer que os Dados precisem ser mantidos por período superior ao pedido de exclusão, nos termos do artigo 16 da Lei Geral de Proteção de Dados Pessoais, para (i) cumprimento de obrigação legal ou regulatória, (ii) estudo por órgão de pesquisa, e (iii) transferência a terceiro (respeitados os requisitos de tratamento de dados dispostos na mesma Lei). Em todos os casos, desde que possível, proceder-se-á com a anonimização de seus Dados Pessoais.
            <br>
            <br>
            5.2.3.    Findos o prazo de manutenção e a necessidade legal, os Dados Pessoais serão excluídos com uso de métodos de descarte seguro, ou utilizados de forma anonimizada para fins estatísticos.
            <br>
            <br>
            6.    Informações sobre essa Política
            <br>
            <br>
            6.1.     Alteração do teor e atualização. Você reconhece o nosso direito de alterar o teor desta Política a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo a Você verificá-la sempre que efetuar o acesso às Plataformas ou utilizar nossos serviços.
            <br>
            <br>
            6.1.1.    Ocorrendo atualizações neste documento e que demandem nova coleta de consentimento, Você será notificado por meio dos canais de contatos que Você informar.
            <br>
            <br>
            6.2.    Inaplicabilidade. Caso algum ponto desta Política seja considerado inaplicável por Autoridade de Dados ou judicial, as demais condições permanecerão em pleno vigor e efeito.
            <br>
            <br>
            6.3.    Comunicação Eletrônica. Você reconhece que toda comunicação realizada por e-mail (aos endereços informados no seu cadastro), SMS, aplicativos de comunicação instantânea ou qualquer outra forma digital, também são válidas, eficazes e suficiente para a divulgação de qualquer assunto que se refira aos serviços que prestamos, aos seus Dados, bem como às condições de sua prestação ou a qualquer outro assunto nele abordado, sendo exceção apenas o que essa Política prever como tal.
            <br>
            <br>
            6.4.    Canais de Comunicação. Em caso de qualquer dúvida com relação às disposições constantes desta Política de Privacidade e Tratamento de Dados, você poderá entrar em contato por meio do Canal de Comunicação Privacidade e Proteção de Dados Pessoais apontado a seguir, cujo horário de funcionamento segunda a sexta das 8h às 17h, exceto feriados:
            <br>
            <br>
            6.4.1.    E-mail: privacidade@liquigas.com.br
            <br>
            <br>
            6.4.2.    Formulário de contato: clique aqui
            <br>
            <br>
            6.5.    Lei aplicável e foro. Essa Política será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro do seu domicílio para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
            <br>
            <br>
            6.5.1.    Você, caso não possua domicílio no Brasil, e em razão dos serviços oferecidos por Nós apenas em território nacional, se submete à legislação brasileira, concordando, portanto, que em havendo litígio a ser solucionado, a ação deverá ser proposta no Foro da Comarca de São Paulo.
            <br>
            <br>
            7.    Glossário
            <br>
            <br>
            7.1.    Para os fins desta Política, devem se considerar as seguintes definições e descrições para seu melhor entendimento:
            <br>
            <br>
            (i)    Anonimização: Utilização de meios técnicos razoáveis e disponíveis no momento do Tratamento, por meio dos quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.
            <br>
            <br>
            (ii)    Cloud Computing: Ou computação em nuvem, é tecnologia de virtualização de serviços construída a partir da interligação de mais de um servidor por meio de uma rede de informação comum (p.ex. a Internet), com objetivo de reduzir custos e aumentar a disponibilidade dos serviços sustentados.
            <br>
            <br>
            (iii)    Conta de Acesso: Credencial de necessária para utilizar ou acessar as funcionalidades exclusivas do site/aplicativo.
            <br>
            <br>
            (iv)    Cookies: Pequenos arquivos enviados por nossas Plataformas e salvos nos seus dispositivos, que armazenam as preferências e poucas outras informações, com a finalidade de personalizar sua navegação de acordo com o seu perfil.
            <br>
            <br>
            (v)    Dados: Quaisquer informações inseridas, tratadas ou transmitidas através do site/aplicativo.
            <br>
            <br>
            (vi)    Dados cadastrais: informações básicas de contato com o usuário, geralmente necessárias para a realização de um cadastro.
            <br>
            <br>
            (vii)    Dados de identificação digital: dados técnicos relacionados ao usuário e seus equipamentos ou ainda à interação do usuário com os sites, portais ou aplicativos da SistechPro, capazes de, ainda que indiretamente, identificá-lo, como seu endereço IP, o ID de seu aparelho, etc.
            <br>
            <br>
            (viii)    Dados Pessoais: Dados relacionados a pessoa natural identificada ou identificável.
            <br>
            <br>
            (ix)    Decisões unicamente automatizadas: Trata-se de decisões que afetam um usuário que foram programadas para funcionar automaticamente, sem a necessidade de uma operação humana, com base em tratamento automatizado de dados pessoais.
            <br>
            <br>
            (x)    Dispositivo: equipamento utilizado para acessar e/ou utilizar o site, portal ou aplicativo da SistechPro.
            <br>
            <br>
            (xi)    Encarregado (Data Protection Officer -DPO): Pessoa indicada por Nós para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
            <br>
            <br>
            (xii)    ID de Sessão: Identificação da sessão de usuários quando é efetuado o acesso às nossas Plataformas.
            <br>
            <br>
            (xiii)    IP: Abreviatura de Internet Protocol. É conjunto alfanumérico que identifica os dispositivos dos usuários na Internet;
            <br>
            <br>
            (xiv)    Link: elemento, trecho ou palavra destacada capaz de, com um clique, conectar e redirecionar o usuário para outro elemento, documento ou site.
            <br>
            <br>
            (xv)    Logs: Registros de atividades de quaisquer usuários que utilizem nossas Plataformas.
            <br>
            <br>
            (xvi)    Pixel Tag: também chamados de também chamadas de clear GIF ou web beacon, é um código inserido em uma campanha que permite monitorar conversões e criar estatísticas com o objetivo de avaliar a sua abrangência e eficácia e direcionar melhor as campanhas ao público-alvo.
            <br>
            <br>
            (xvii)    Plataformas: refere-se coletivamente aos portais mantidos pela SistechPro, disponibilizados através do site http://www.liquigas.com.br ou por outros caminhos e que estejam sob sua titularidade, como o Canal Fornecedor (https://www.liquigas.com.br/wps/portal/mi), Portal de Negócios (https://www.liquigas.com.br/wps/portal/portalnegocios2011), o Portal Medição Individualizada SistechPro(MIL) (https://www.liquigas.com.br/wps/portal/mi) e o Portal de Marketing (http://www.marketing.liquigas.com.br/).
            <br>
            <br>
            (xviii)    Portal: um site na internet projetado para aglomerar e distribuir conteúdo de várias fontes diferentes de maneira uniforme, sendo um ponto de acesso para uma série de outros sites ou subsites internamente ou externamente ao domínio ou subdomínio da empresa gestora do portal.
            <br>
            <br>
            (xix)    Sessão: período contínuo de interação com o site, portal ou aplicativo da SistechPro, assim considerado desde a conexão do usuário até o encerramento de suas interações.
            <br>
            <br>
            (xx)    Tratamento: Toda operação realizada com Dados Pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
            <br>
            <br>
            (xxi)    Usuário: pessoa física que, através de equipamento adequado, se conecta a site, portal ou aplicativo da SistechPro, independentemente do uso que dele(s) faz ou da existência de relação prévia com a SistechPro.
            <br>
            <br>
            Nossas Plataformas, o conteúdo de seus respectivos Termos de Uso, esta Política de Privacidade e nossas Políticas de Cookies se encontram sujeitos a contínuo aprimoramento, de modo que poderão ser modificados a qualquer momento, desde que não haja vedação legal nesse sentido, sendo obrigação do Usuário verificá-los periodicamente.
            <br>
            <br>
            Caso, em algum momento, essas modificações promovam mudança substancial na maneira como são coletados e utilizados dados pessoais, nós as informaremos: a) ao público em geral, mediante anúncio com destaque em nossa página principal; e b) aos usuários cadastrados, mediante comunicação eletrônica.
            <br>
            <br>
        </p>



    </div>
</div>
