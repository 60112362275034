<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Visita Técnica</p>
        <div class="container">
            <h2 class="page_header_title">Visita <span>Técnica</span></h2>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-sm-6 col-lg-5">
            <h4 class="home_title mt-5 mb-5 text-uppercase" style="line-height:1.2">
                Capriche nas fotos e faça bonito na Visita Técnica</h4>
        </div>
        <div class="col-sm-6 offset-lg-1">
            <div class="technical-detail_card p-4">
                <div class="row">
                    <dl class="col-auto">
                        <dt class="technical-detail_lbl">CNPJ</dt>
                        <dd class="technical-detail_value">{{technicalVisit.standarlization?.cnpj|mask:'00.000.000/0000-00'}}</dd>
                    </dl>
                    <dl class="col">
                        <dt class="technical-detail_lbl">Razão Social</dt>
                        <dd class="technical-detail_value">{{technicalVisit.companyName}}</dd>
                    </dl>
                    <dl class="col-auto">
                        <div class="row mx-n1 align-items-center">
                            <div class="col-4 px-1">
                                <svg-icon [src]="caseStatusIcon(technicalVisit.status)"></svg-icon>
                            </div>
                            <div class="col-auto px-1">
                                <dt class="technical-detail_lbl">Status</dt>
                                <dd class="technical-detail_value">{{technicalVisit.status}}</dd>
                            </div>
                        </div>
                    </dl>
                </div>
                <div class="row">
                    <div class="col-12 text-primary">
                        <small><strong>IMPORTANTE:</strong> A pontuação na Visita Técnica (VT) será aplicada somente ao final da campanha.</small>
                    </div>
                </div>
                <div class="row mt-3" style="row-gap:20px">
                    <div class="col-sm-6">
                        <a class="btn w-100 h-100 align-text-center"
                           [ngClass]="technicalVisit.gasStation ? 'btn-outline-primary' : 'btn-white'"
                           (click)="handlerGasStation(technicalVisit,false)">
                            <div class="row justify-content-center align-items-center h-100">
                                <div class="col-auto" [ngClass]="{'d-none':technicalVisit.gasStation}">
                                    <svg-icon src="assets/svg/check2.svg"></svg-icon>
                                </div>
                                <div class="col-auto">
                                    <span>Revenda</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-sm-6">
                        <a class="btn w-100 h-100 align-text-center"
                           [ngClass]="!technicalVisit.gasStation ? 'btn-outline-primary' : 'btn-white'"
                           (click)="handlerGasStation(technicalVisit,true)">
                            <div class="row justify-content-center align-items-center">
                                <div class="col-auto" [ngClass]="{'d-none':!technicalVisit.gasStation}">
                                    <svg-icon src="assets/svg/check2.svg"></svg-icon>
                                </div>
                                <div class="col-auto">
                                    <span>Postos ou Supermercados</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <a class="btn btn-lg btn-block btn-secondary mt-5" (click)="sendReport()" *ngIf="false"><!-- *ngIf="!validateUploadFile" -->
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto d-flex">
                        <svg-icon src="assets/svg/send.svg"></svg-icon>
                    </div>
                    <div class="col-auto">
                        <span>Enviar Relatório</span>
                    </div>
                </div>
            </a>
            <a class="btn btn-lg btn-block btn-secondary mt-5" style="cursor: default;"><!-- *ngIf="!validateUploadFile" -->
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto d-flex">
                        <svg-icon src="assets/svg/regulament.svg"></svg-icon>
                    </div>
                    <div class="col-auto">
                        <span>Prazo Encerrado</span>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <div class="position-relative mt-4" style="margin-bottom: 1.1rem;">
        <div class="row h-100 mx-n1 justify-content-between align-content-center" style="row-gap: 30px">
            <a class="col-sm-auto px-1 position-relative cursor-pointer" (click)="stepSelected = x.id"
               *ngFor="let x of listStep">
                <div class="technical-detail_step" [ngClass]="{'active': stepSelected === x.id}">
                    <div class="row mx-n2 align-items-center">
                        <div class="col-auto px-2">
                            <div class="technical-detail_step_icon">
                                <svg-icon src="{{x.icon}}"></svg-icon>
                            </div>
                        </div>
                        <div class="col-auto px-2">
                            <p class="technical-detail_step_name mb-0">{{x.name}}</p>
                        </div>
                    </div>
                </div>
                <svg-icon class="home-training_arrow home-training_arrow-technical"
                          [ngClass]="{'active':stepSelected == x.id}"
                          src="assets/svg/arrow-training-gray.svg"></svg-icon>
            </a>
        </div>
        <div class="home-training_bar home-training_bar-ranking">&nbsp;</div>
    </div>
</div>
<ng-container *ngIf="stepSelected == 1">
    <div style="background-color: #F2F2F2">
        <div class="container">
            <div class="row">
                <div class="col-sm-5 offset-sm-1">
                    <div class="p-4">
                        <p class="technical-detail_description">
                            A Revenda pontuará se possuir controles informatizados da
                            Lista de
                            Clientes ou do Fluxo de Caixa,
                            conforme segue</p>
                    </div>
                </div>
                <div class="col-4 col-sm-2 d-flex flex-column justify-content-center align-items-center bg-tertiary">
                    <p class="technical-detail_point_value mb-0">50</p>
                    <p class="technical-detail_point_lbl mb-0">pontos</p>
                </div>
                <div class="col-8 col-sm-4">
                    <dl class="py-4 p-sm-4">
                        <dt class="technical-detail_description mb-2">Importante!</dt>
                        <dd class="technical-detail_description">Caso a revenda não possua computador, não pontua</dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row my-5" style="row-gap:20px">
            <div class="col-sm-6">
                <div class="row mx-n1 align-items-center mb-4">
                    <div class="col-auto px-1">
                        <div class="technical-detail_bar">&nbsp;</div>
                    </div>
                    <div class="col px-1">
                        <h5 class="technical-detail_title">Lista de Clientes<span> |   25 pontos</span></h5>
                    </div>
                </div>
                <p class="technical-detail_text mb-5">
                    Cadastrados da Revenda, podendo ser apresentada fotografia ou print de tela que comprove esses
                    registros
                    através de algum sistema ou planilha. Não serão aceitas imagens de listas manuais.
                </p>

                <div class="row justify-content-center mb-4 pb-5 border-bottom">
                    <ng-container *ngIf="!isNullOrUndefined(customers)">
                        <div class="col-4">
                            <div class="technical-detail_download">
                                <a class="technical-detail_download_icon"
                                   href="{{getImageURL()+ 'technical-visit/image/' + customers.path}}" target="_blank">
                                    <svg-icon class="d-block mb-2" src="assets/svg/doc.svg"></svg-icon>
                                    <p class="technical-detail_download_text home_text text-center mb-0">{{customers.path}}</p>
                                </a>
                                <a (click)="removeFile(customers)" *ngIf="!validateUploadFileItem(customers)"
                                   class="btn btn-secondary rounded-circle technical-detail_download_trash">
                                    <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isNullOrUndefined(customers)">
                        <div class="col-4">
                            <label class="btn btn-sm btn-block btn-outline-secondary hover-stroke pt-4 pb-2">
                                <svg-icon class="d-block mb-2" src="assets/svg/add-secondary.svg"></svg-icon>
                                <span class="mb-0">Adicionar</span>
                                <input class="d-none" type="file" id="doc-1" name="doc-1" required
                                       [disabled]="validateUploadFile"
                                       (change)="handleUpload($event,
                                       technicalVisit.computerizedManagent.technicalVisitItemId,
                                       technicalVisit.computerizedManagent.typeImage[0].id)"/>
                            </label>
                        </div>
                    </ng-container>
                </div>

                <div class="row mx-n1 align-items-center mb-4">
                    <div class="col-auto px-1">
                        <div class="technical-detail_bar">&nbsp;</div>
                    </div>
                    <div class="col px-1">
                        <h5 class="technical-detail_title">Fluxo de Caixa<span> |   25 pontos</span></h5>
                    </div>
                </div>
                <p class="technical-detail_text">
                    Podendo ser apresentada fotografia ou print de tela que comprove esses registros através de algum
                    sistema ou planilha. É necessário que no arquivo conste alguma informação de identificação da
                    Revenda,
                    como a razão social e/ou CNPJ.
                </p>
                <div class="row mt-5 justify-content-center">
                    <div class="col-lg-7">
                        <div class="row justify-content-between mb-5">
                            <ng-container *ngFor="let x of cashFlow">
                                <div class="col-5 position-relative d-flex flex-column justify-content-center align-items-center">
                                    <div class="technical-detail_download">
                                        <a class="technical-detail_download_icon"
                                           href="{{getImageURL()+ 'technical-visit/image/' + x.path}}" target="_blank">
                                            <svg-icon class="d-block mb-2" src="assets/svg/doc.svg"></svg-icon>
                                            <p class="technical-detail_download_text home_text text-center mb-0">{{x.path}}</p>
                                        </a>
                                        <a (click)="removeFile(x)" *ngIf="!validateUploadFileItem(x)"
                                           class="btn btn-secondary rounded-circle technical-detail_download_trash">
                                            <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="cashFlow.length < 2">
                                <div class="col-5">
                                    <label class="btn btn-sm btn-block btn-outline-secondary hover-stroke pt-4 pb-2">
                                        <svg-icon class="d-block mb-2" src="assets/svg/add-secondary.svg"></svg-icon>
                                        <span class="mb-0">Adicionar</span>
                                        <input class="d-none" type="file" id="doc-1" name="doc-1" required
                                               [disabled]="validateUploadFile"
                                               (change)="handleUpload($event,
                                               technicalVisit.computerizedManagent.technicalVisitItemId,
                                               technicalVisit.computerizedManagent.typeImage[1].id)"/>
                                    </label>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-5 offset-lg-1">
                <app-chat [(chat)]="isNullOrUndefined(technicalVisit.computerizedManagent)
                ?[]:technicalVisit.computerizedManagent.chat"
                          [itemId]="technicalVisit.computerizedManagent?.technicalVisitItemId"
                          [status]="technicalVisit.status"></app-chat>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="stepSelected == 2">
    <div style="background-color: #F2F2F2">
        <div class="container">
            <div class="row">
                <div class="col-sm-8 offset-sm-1">
                    <div class="py-4 p-sm-4">
                        <p class="technical-detail_description mb-0">
                            Certificado de treinamento / cursos com validade de até 1 ano da data de inserção no site.
                            Serão aceitos treinamentos realizados pelo proprietário da Revenda ou Equipe da Revenda,
                            sejam eles ministrados pelo consultor comercial SistechProou cursos online ou presenciais em
                            outras instituições desde que seja em área relacionadas com gestão de negócios ou similares.
                        </p>
                    </div>
                </div>
                <div class="col-sm-2 bg-tertiary">
                    <div class="d-flex flex-column justify-content-center align-items-center h-100 p-3">
                        <p class="technical-detail_point_value mb-0">50</p>
                        <p class="technical-detail_point_lbl mb-0">pontos</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row my-5" style="row-gap:20px">
            <div class="col-sm-6">
                <div class="row mx-n1 align-items-center mb-4">
                    <div class="col-auto px-1">
                        <div class="technical-detail_bar">&nbsp;</div>
                    </div>
                    <div class="col px-1">
                        <p class="technical-detail_text mb-0">
                            Data do Certificado de treinamento ou cursos com validade de até 1 ano da data de inserção
                            no site
                        </p>
                    </div>
                </div>
                <form autocomplete="off" class="row justify-content-center align-items-center border-bottom pb-5 mb-4">
                    <div class="col-3 col-sm-auto">
                    </div>
                    <div class="col col-sm-auto">
                        <div class="input-material">
                            <input class="form-control" autocomplete="off" type="text" id="birthdayProfile"
                                   name="birthday"
                                   [(ngModel)]="this.technicalVisit.training.trainingDate"
                                   [mask]="'d0/M0/0000'"
                                   [dropSpecialCharacters]="false"
                                   required/>
                            <label for="birthdayProfile">Data do certificado</label>
                        </div>
                    </div>
                </form>
                <div class="row justify-content-center align-items-center">
                    <div class="col-sm">
                        <div class="row mx-n1 align-items-center mb-4">
                            <div class="col-auto px-1">
                                <div class="technical-detail_bar">&nbsp;</div>
                            </div>
                            <div class="col px-1">
                                <p class="technical-detail_text mb-0"> Upload da foto do certificado com data. </p>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngFor="let x of this.technicalVisit.training.items">
                        <div class="col-5 col-sm-4 col-lg-3 offset-lg-3">
                            <div class="technical-detail_download">
                                <a class="technical-detail_download_icon"
                                   href="{{getImageURL()+ 'technical-visit/image/' + x.path}}" target="_blank">
                                    <svg-icon class="d-block mb-2" src="assets/svg/doc.svg"></svg-icon>
                                    <p class="technical-detail_download_text home_text text-center mb-0">{{x.path}}</p>
                                </a>
                                <a (click)="removeFile(x)" *ngIf="!validateUploadFileItem(x)"
                                   class="btn btn-secondary rounded-circle technical-detail_download_trash">
                                    <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.technicalVisit.training.items.length == 0">
                        <div class="col-5 col-sm-4 col-lg-3 offset-lg-3">
                            <label class="btn btn-sm btn-block btn-outline-secondary hover-stroke pt-4 pb-2">
                                <svg-icon class="d-block mb-2" src="assets/svg/add-secondary.svg"></svg-icon>
                                <span class="mb-0">Adicionar</span>
                                <input class="d-none" type="file" id="doc-1" name="doc-1" required
                                       [disabled]="validateUploadFile"
                                       (change)="handleTrainingUpload($event,
                                       technicalVisit.training.technicalVisitItemId,
                                       technicalVisit.training.typeImage[0].id)"/>
                            </label>
                        </div>
                    </ng-container>
                </div>
                <div class="row mt-5 justify-content-center" *ngIf="!validateUploadFile">
                    <div class="col-sm-7">
                        <a (click)="saveTraining()" class="btn btn-block btn-outline-secondary">Salvar</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-5 offset-lg-1">
                <app-chat [(chat)]="isNullOrUndefined(technicalVisit.training)
                ?[]:technicalVisit.training.chat"
                          [itemId]="technicalVisit.training?.technicalVisitItemId"
                          [status]="technicalVisit.status"></app-chat>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="stepSelected == 3">
    <div style="background-color: #F2F2F2">
        <div class="container">
            <div class="row">
                <div class="col-sm-4 offset-sm-1">
                    <div class="py-4 p-sm-4">
                        <p class="technical-detail_description">
                            Delimitação da área de armazenamento de botijões por meio de pintura no piso ou cerca/
                            gradil metálico.
                        </p>
                    </div>
                </div>
                <div class="col-4 col-sm-2 offset-sm-1 d-flex flex-column justify-content-center align-items-center bg-tertiary">
                    <p class="technical-detail_point_value mb-0">50</p>
                    <p class="technical-detail_point_lbl mb-0">pontos</p>
                </div>
                <div class="col-8 col-sm-4">
                    <dl class="py-4 p-sm-4">
                        <dt class="technical-detail_description mb-2">Importante!</dt>
                        <dd class="technical-detail_description">Se houver botijões fora da área de armazenamento, não
                            pontua.
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row align-items-center my-5" style="row-gap:20px">
            <div class="col-sm-6">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7">
                        <div class="row justify-content-between mb-5">
                            <ng-container *ngIf="!isNullOrUndefined(image1)">
                                <div class="col-5 position-relative d-flex flex-column justify-content-center align-items-center">
                                    <div class="technical-detail_download">
                                        <a class="technical-detail_download_icon"
                                           href="{{getImageURL()+ 'technical-visit/image/' + image1.path}}"
                                           target="_blank">
                                            <svg-icon class="d-block mb-2" src="assets/svg/doc.svg"></svg-icon>
                                            <p class="technical-detail_download_text home_text text-center mb-0">{{image1.path}}</p>
                                        </a>
                                        <a (click)="removeFile(image1)" *ngIf="!validateUploadFileItem(image1)"
                                           class="btn btn-secondary rounded-circle technical-detail_download_trash">
                                            <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="isNullOrUndefined(image1)">
                                <div class="col-5">
                                    <label class="btn btn-sm btn-block btn-outline-secondary hover-stroke pt-4 pb-2">
                                        <svg-icon class="d-block mb-2" src="assets/svg/add-secondary.svg"></svg-icon>
                                        <span class="mb-0">Adicionar</span>
                                        <input class="d-none" type="file" id="doc-1" name="doc-1" required
                                               [disabled]="validateUploadFile"
                                               (change)="handleUpload($event,
                                               technicalVisit.installationSafety.technicalVisitItemId,
                                               technicalVisit.installationSafety.typeImage[0].id)"/>
                                    </label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!isNullOrUndefined(image2)">
                                <div class="col-5 position-relative d-flex flex-column justify-content-center align-items-center">
                                    <div class="technical-detail_download">
                                        <a class="technical-detail_download_icon"
                                           href="{{getImageURL()+ 'technical-visit/image/' + image2.path}}"
                                           target="_blank">
                                            <svg-icon class="d-block mb-2" src="assets/svg/doc.svg"></svg-icon>
                                            <p class="technical-detail_download_text home_text text-center mb-0">{{image2.path}}</p>
                                        </a>
                                        <a (click)="removeFile(image2)" *ngIf="!validateUploadFileItem(image2)"
                                           class="btn btn-secondary rounded-circle technical-detail_download_trash">
                                            <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="isNullOrUndefined(image2)">
                                <div class="col-5">
                                    <label class="btn btn-sm btn-block btn-outline-secondary hover-stroke pt-4 pb-2">
                                        <svg-icon class="d-block mb-2" src="assets/svg/add-secondary.svg"></svg-icon>
                                        <span class="mb-0">Adicionar</span>
                                        <input class="d-none" type="file" id="doc-1" name="doc-1" required
                                               [disabled]="validateUploadFile"
                                               (change)="handleUpload($event,
                                               technicalVisit.installationSafety.technicalVisitItemId,
                                               technicalVisit.installationSafety.typeImage[1].id)"/>
                                    </label>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-5 offset-lg-1">
                <app-chat [(chat)]="isNullOrUndefined(technicalVisit.installationSafety)
                ?[]:technicalVisit.installationSafety.chat"
                          [itemId]="technicalVisit.installationSafety?.technicalVisitItemId"
                          [status]="technicalVisit.status"></app-chat>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="stepSelected == 4">
    <app-standardization [(standardization)]="technicalVisit.standarlization"
                         (handlerChildren)="refetchDetail()"
                         [status]="technicalVisit.status"
                         [gasStation]="technicalVisit.gasStation"
    ></app-standardization>
</ng-container>


<app-modal-gas-station [gasStationInput]="gasStationInput"
                       (eventSend)="refetchDetail()"></app-modal-gas-station>

