<div class="container-xxl p-0  container-hero">
    <div class="carousel slick-home" id="slick-banner">
        <div *ngFor="let b of this.banner; let i = index;">
            <img (click)="clickBanner(b)" class="slick-home-thumb"
                 src="{{this.getImageURL() + 'banner/image/' + b.pathImage}}" alt="banner">
        </div>
    </div>
</div>

<div class="container">
    <div class="row my-3 my-sm-5 mx-n4" style="row-gap: 25px">
        <div class="col-lg-5 px-4">
            <h1 class="home_title">Treinamento | <span>Vídeo e Quiz</span></h1>
            <div class="row no-gutters justify-content-between" style="row-gap: 15px">
                <div class="col-sm-3 position-relative">
                    <div class="row h-100 mx-n1 align-content-center" style="row-gap: 30px">
                        <div class="col px-1 col-sm-12" *ngFor="let item of listEngagedReseller;let i = index" (click)="item.active ? selectMouth = i : false">
                            <!-- (click)="selectMouth = i"-->
                            <div class="home-training_btn" [ngClass]="{'active':i == selectMouth}">
                                <p class="home-training_lbl">{{item.name}}</p>
                                <p class="home-training_lbl home-training_lbl-sm">{{item.activeAt}}</p>
                            </div>
                            <svg-icon class="home-training_arrow" src="assets/svg/arrow-training.svg"></svg-icon>
                            <!-- [ngClass]="{'active':i == selectMouth}"-->
                        </div>
                    </div>
                    <div class="home-training_bar">&nbsp;</div>
                </div>
                <div class="col-sm-8 pl-sm-3">
                    <div class="carousel" id="slick-quiz">
                        <ng-container *ngFor="let item of listEngagedReseller;let i = index">
                            <div [ngClass]="{'d-none' : i !== selectMouth}">
                                <h4 class="home-training_title">{{item.name}}</h4>
                                <p class="home_text">
                                    Assista ao vídeo, responda ao quiz e garanta seus pontos no mês.
                                </p>
                                <img style="width: 100%;" src="assets/images/logo.png" alt="video" class="mb-2">
                                <a routerLink="/time-preparado" class="btn btn-block btn-outline-secondary">
                                    Acessar Treinamento</a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 px-4">
            <div class="row">
                <div class="col">
                    <h1 class="home_title"></h1>
                </div>
                <div class="col-12">
                    <div class="row align-items-center mx-n1 d-none">
                        <div class="col-12 px-1 pb-2">
                            <!--<p class="home-news_lbl mb-0">Veja todas as notícias</p>-->
                            <iframe width="100%" height="160" src="https://www.youtube.com/embed/S-3S2pvowGg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div class="col-12 px-1">
                            <!--<p class="home-news_title">O  SistechPro começou!</p>
                            <p class="home-news_text">Assista ao vídeo para conferir as novidades, as mecânicas e os prêmios para iniciar a campanha a todo gás.</p>-->
                            <!--<div class="row justify-content-between align-items-center">
                                <div class="col-auto">
                                    <p class="home-news_date mb-0">Loren ipsum dolor sit amet</p>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 px-4">
            <div class="row">
                <div class="col">
                    <h1 class="home_title">Notícias</h1>
                </div>
                <div class="col-auto">
                    <a class="row align-items-center mx-n1" routerLink="/noticias">
                        <div class="col px-1">
                            <p class="home-news_lbl mb-0">Veja todas as notícias</p>
                        </div>
                        <div class="col-auto px-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.421" height="10.918"
                                 viewBox="0 0 6.421 10.918">
                                <g id="Icon_ionic-ios-arrow-dropleft" data-name="Icon ionic-ios-arrow-dropleft"
                                   transform="translate(-7.52 -4.501)">
                                    <path id="Caminho_56" data-name="Caminho 56"
                                          d="M12.436,10.252a.928.928,0,0,1,1.308,0l4.57,4.584a.923.923,0,0,1,.029,1.274l-4.5,4.517a.923.923,0,1,1-1.308-1.3l3.822-3.88L12.431,11.56A.924.924,0,0,1,12.436,10.252Z"
                                          transform="translate(-4.642 -5.481)" fill="#546e7a"/>
                                </g>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div class="carousel" id="slick-news">
                <div *ngFor="let n of this.newsPage.news">
                    <div class="home-news_item">
                        <div class="home-news_thumb">
                            <img src="{{this.getImageURL() + 'news/image/' +n.pathImage}}" alt="image">
                        </div>
                        <div class="px-3">
                            <p class="home-news_title">{{n.title}}</p>
                            <p class="home-news_text" [innerHTML]="n.text"></p>
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto">
                                    <p class="home-news_date mb-0">{{n.createdAt | date:'dd/MM/yyyy | HH:mm':'UTC-0'}}</p>
                                </div>
                                <div class="col-auto">
                                    <a class="home-news_link cursor-pointer" (click)="clickNewsAccess(n.id)">Leia
                                        mais</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bg-primary">
    <div class="container-sm py-5">
        <h1 class="home_title text-white">Mecânica Extra<br>
            <span>Seu desempenho</span>
        </h1>
        <p class="home_text text-white">
            Assista ao vídeo, responda ao quiz e garanta seus pontos do mês.
        </p>
        <div class="row home_grid">
            <div class="col-12 col-sm-3 col-lg-2">
                <div class="home_card text-tertiary">
                    <p class="home_card_month mb-0">Total</p>
                    <div class="my-3">
                        <p class="home_card_value mb-0">{{userPerformance.total}}</p>
                        <p class="home_card_lbl mb-0">pontos</p>
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-3 col-lg-2" *ngFor="let x of userPerformance.performanceMonth,let  i = index">
                <div class="home_card"
                     [ngClass]="{'text-white':currentMount(userPerformance.performanceMonth[listSortMouth[i]].month)}">
                    <p class="home_card_month mb-0">{{enumMonth(userPerformance.performanceMonth[listSortMouth[i]].month)}}</p>
                    <div class="my-3">
                        <p class="home_card_value mb-0">{{userPerformance.performanceMonth[listSortMouth[i]].score}}</p>
                        <p class="home_card_lbl mb-0">pontos</p>
                    </div>
                    <p class="home_card_description mb-0">Meta
                        mensal {{userPerformance.performanceMonth[listSortMouth[i]].goal}} pontos</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-sm py-5">
    <h1 class="home_title">Padronização</h1>
    <div class="row" style="row-gap:20px">
        <div class="col-lg-4">
            <h2 class="standardization_title mb-3">Cupons</h2>
            <!--<p class="home_text"> O sorteio dos cupons será do dia 28/02/2023 </p>-->
            <div class="row" style="row-gap: 20px">
                <div class="col-sm-4">
                    <div class="coupon_card" [ngClass]="{'card-active':userCoupon.facade}">
                        <img class="coupon_img" [ngClass]="{'img-active':userCoupon.facade}"
                             src="assets/images/logo-dark-small.png" alt="logo-dark-small"/>
                        <p class="coupon_lbl" [ngClass]="{'lbl-active':userCoupon.facade}">Fachada</p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="coupon_card" [ngClass]="{'card-active':userCoupon.fleet}">
                        <img class="coupon_img" [ngClass]="{'img-active':userCoupon.fleet}"
                             src="assets/images/logo-dark-small.png" alt="logo-dark-small"/>
                        <p class="coupon_lbl" [ngClass]="{'lbl-active':userCoupon.fleet}">Frota</p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="coupon_card" [ngClass]="{'card-active':userCoupon.extra}">
                        <img class="coupon_img" [ngClass]="{'img-active':userCoupon.extra}"
                             src="assets/images/logo-dark-small.png" alt="logo-dark-small"/>
                        <p class="coupon_lbl" [ngClass]="{'lbl-active':userCoupon.extra}">Extra</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-7 offset-lg-1">
            <div class="row align-items-center mb-3">
                <div class="col">
                    <h2 class="standardization_title">Prêmios</h2>
                </div>
                <div class="col-auto">
                    <a class="row align-items-center mx-n1" routerLink="/premios">
                        <div class="col-auto px-1">
                            <p class="home-news_lbl mb-0">Veja mais</p>
                        </div>
                        <div class="col-auto px-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.421" height="10.918"
                                 viewBox="0 0 6.421 10.918">
                                <g id="Icon_ionic-ios-arrow-dropleft" data-name="Icon ionic-ios-arrow-dropleft"
                                   transform="translate(-7.52 -4.501)">
                                    <path id="Caminho_56" data-name="Caminho 56"
                                          d="M12.436,10.252a.928.928,0,0,1,1.308,0l4.57,4.584a.923.923,0,0,1,.029,1.274l-4.5,4.517a.923.923,0,1,1-1.308-1.3l3.822-3.88L12.431,11.56A.924.924,0,0,1,12.436,10.252Z"
                                          transform="translate(-4.642 -5.481)" fill="#546e7a"/>
                                </g>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <p class="home_text pr-lg-5 mr-lg-5">
                Todas as revendas com fachada que comprovarem a padronização de sua fachada e frota, ganham cupons para
                o sorteio de até R$ 10 mil em carga de gás, de acordo com sua faixa.
            </p>
            <div class="row mt-4 no-gutters flex-sm-nowrap">

            </div>
            <p class="home_text mt-4"><strong>Atenção:</strong> Só concorrem ao sorteio de padronização as revendas que
                possuem fachadas. Revendas sem fachada (exemplo de postos de combustíveis e supermercados) não concorrem
                ao sorteio.</p>
        </div>
    </div>
</div>
<ng-container *ngIf="!isNullOrUndefined(modelUserView)">
    <ng-container *ngIf="modelUserView!.toLowerCase() === 'ec' || modelUserView!.toLowerCase() === 'ma'">
        <div class="bg-secondary">
            <div class="container-sm py-5">
                <div class="row align-items-end" style="row-gap: 20px">
                    <div class="col-lg-3">
                        <h1 class="home_title">Visita Técnica<br>
                            <span class="text-white">Acompanhamento</span>
                        </h1>
                        <p class="home_text text-white">
                            Acompanhe os status das avaliações das visitas técnicas até o momento.
                        </p>
                        <p class="home_text text-white">
                            Caso esteja faltando alguma revenda na lista ou alguma delas não for mais atendida, favor
                            clicar
                            em
                            “Contato” para nos informar.
                        </p>
                    </div>
                    <div class="col-lg-9">
                        <div class="row home-technical_grid">
                            <div class="col-sm">
                                <div class="home-technical_card">
                                    <div class="d-flex flex-column align-items-center mb-3">
                                        <svg-icon src="assets/svg/finished.svg"></svg-icon>
                                        <p class="home-technical_lbl mb-0">Finalizada</p>
                                    </div>
                                    <p class="home-technical_value mb-0">15</p>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="home-technical_card bg-warning">
                                    <div class="d-flex flex-column align-items-center mb-3">
                                        <svg-icon src="assets/svg/analysis.svg"></svg-icon>
                                        <p class="home-technical_lbl mb-0">Em análise</p>
                                    </div>
                                    <p class="home-technical_value mb-0">15</p>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="home-technical_card bg-tertiary">
                                    <div class="d-flex flex-column align-items-center mb-3">
                                        <svg-icon src="assets/svg/pending.svg"></svg-icon>
                                        <p class="home-technical_lbl mb-0">Pendente</p>
                                    </div>
                                    <p class="home-technical_value mb-0">15</p>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="home-technical_card bg-danger">
                                    <div class="d-flex flex-column align-items-center mb-3">
                                        <svg-icon src="assets/svg/review.svg"></svg-icon>
                                        <p class="home-technical_lbl mb-0">Revisar</p>
                                    </div>
                                    <p class="home-technical_value mb-0">15</p>
                                </div>
                            </div>
                            <div class="col-sm cursor-pointer" routerLink="/visita-tecnica">
                                <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                    <div class="home-technical_see-more mb-2">
                                        <svg id="Icon_ionic-ios-arrow-dropleft"
                                             data-name="Icon ionic-ios-arrow-dropleft"
                                             xmlns="http://www.w3.org/2000/svg" width="11.346" height="19.292"
                                             viewBox="0 0 11.346 19.292">
                                            <path id="Caminho_56" data-name="Caminho 56"
                                                  d="M12.646,10.459a1.64,1.64,0,0,1,2.311,0l8.075,8.1a1.632,1.632,0,0,1,.051,2.252l-7.956,7.982a1.631,1.631,0,1,1-2.311-2.3l6.755-6.856-6.932-6.865A1.632,1.632,0,0,1,12.646,10.459Z"
                                                  transform="translate(-12.162 -9.983)" fill="#fff"/>
                                        </svg>
                                    </div>
                                    <p class="text-center">Ver mais detalhes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>


<div class="modal fade" id="isViewVideo" tabindex="-1" role="dialog"
     aria-labelledby="isValidateLabel" aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="row no-gutters">
                <div class="col-sm-4 p-3 bg-primary d-flex align-items-end">
                    <img class="modal_logo modal_logo-kitchen" src="assets/images/logo-liquigas-large-white.png"
                         alt="logo">
                </div>
                <div class="col">
                    <div class="modal-body">
                        <h3 class="kitchen_subtitle text-uppercase mb-4">O  SistechPro ESTÁ NO AR!</h3>
                        <p class="kitchen_text mb-4">
                            Assista ao vídeo para conferir as novidades, as mecânicas e os prêmios para entrar na campanha com todo gás.</p>
                        <div class="w-100 d-block">
                            <div class="box-video-frame"></div>
                            <!--<iframe width="100%" height="280" src="https://www.youtube.com/embed/hg_gO7eefLg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-6">
                                <a href="javascript:void('');" data-dismiss="modal" aria-label="Close" class="btn btn-block btn-secondary" (click)="actionCookies()">Fechar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
