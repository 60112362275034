import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {ResetPasswordGQL, ResetPasswordInput} from "../../../generated/graphql";

@Component({
    selector: 'app-recovery',
    templateUrl: './recovery.component.html',
    styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent extends BaseComponent implements OnInit {
    input: ResetPasswordInput = new ResetPasswordInput();
    confirmPassword: string;
    isEyeBool: boolean = false;
    isEyeBoolConfirm: boolean = false;

    constructor(public router: Router,
                public service: UserService,
                private router2: ActivatedRoute,
                public resetPasswordGQL: ResetPasswordGQL,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
        this.router2.params.subscribe(url => {
                if (!this.isNullOrUndefined(url.id)) {
                    this.input.code = url.id;
                }
            }
        );
    }

    onSave() {
        console.log(this.input);
        if (super.isNullOrUndefined(this.input.newPassword)) {
            this.showMessage('Atenção', `Preencha a senha corretamente`);
            return;
        }

        if (this.input.newPassword !== this.confirmPassword) {
            this.showMessage('Atenção', `Senhas não conferem`);
            return;
        }
        this.resetPasswordGQL.mutate({input: this.input}).subscribe(({data}) => {
            this.router.navigate(['/login']).then();
            this.showMessage('Sucesso', 'Senha redefinida com sucesso', 'success');
        }, error => super.onError(error));
    }
}
