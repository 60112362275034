import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-winners',
    templateUrl: './winners.component.html',
    styleUrls: ['./winners.component.scss']
})
export class WinnersComponent implements OnInit {
    resaleRaking = false;
    resalePrepared = true;
    resaleTeam = true;
    novembro = true;
    novembroreg = 1;
    dezembro = false;
    dezembroreg = 1;
    cargagas = 1;
    kitdesafio = 1;
    timepreparado = 1;
    region = 1;
    region2 = 1;




    constructor() {
    }

    ngOnInit(): void {
    }

}
