<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Ganhadores</p>
        <div class="container">
            <h2 class="page_header_title">Ganhadores</h2>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="false">
    <div class="wait-panel">
        <h3>Em Breve</h3>
    </div>
</div>

<ng-container *ngIf="true">
    <div class="py-5">
        <div class="container" style="z-index: 2">
            <div class="position-relative">
                <h2 class="prize_title text-uppercase mb-4">Revenda</h2>
                <h3 class="prize_subtitle mb-4 text-primary">Ranking <span>Final</span></h3>

                <div class="step mt-4">
                    <a href="javascript:void(0)" class="step-item active">
                        <span>Carga de Gás (GLP) + Crédito  para Equipe</span>
                    </a>

                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class=" input-material select mb-3">
                            <select class="form-control"
                                    [(ngModel)]="region"
                                    id="region" name="region">
                                <option value="1">Região 1</option>
                                <option value="2">Região 2</option>
                                <option value="3">Região 3</option>
                                <option value="4">Região 4</option>
                            </select>
                            <label for="region">Regiao</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class=" input-material select mb-3">
                            <select class="form-control"
                                    [(ngModel)]="cargagas"
                                    id="cargagas" name="cargagas">
                                <option value="1">Faixa 1</option>
                                <option value="2">Faixa 2</option>
                                <option value="3">Faixa 3</option>
                                <option value="4">Faixa 4</option>
                                <option value="5">Faixa 5</option>
                            </select>
                            <label for="region">Regiao</label>
                        </div>
                    </div>


                    <div class="col-12">

                        <div class="step_secondary d-none">
                            <a href="javascript:void(0)" class="step-item"
                               (click)="region = 1" [ngClass]="{'active':region == 1}">
                                <span>Região 1</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="region = 2" [ngClass]="{'active':region == 2}">
                                <span>Região 2</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="region = 3" [ngClass]="{'active':region == 3}">
                                <span>Região 3</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="region = 4" [ngClass]="{'active':region == 4}">
                                <span>Região 4</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col-lg-2">
                        <p class="prize_text">Confira as 200 revendas que se destacaram no Mega Desafio SistechProe foram
                            premiadas com Prêmios de acordo com sua região e faixa e créditos no  para toda
                            sua equipe cadastrada na campanha:</p>


                    </div>
                    <div class="col-lg" *ngIf="region == 1">
                        <div class="step_secondary d-none">
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 1" [ngClass]="{'active':cargagas == 1}">
                                <span>Faixa 1</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 2" [ngClass]="{'active':cargagas == 2}">
                                <span>Faixa 2</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 3" [ngClass]="{'active':cargagas == 3}">
                                <span>Faixa 3</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 4" [ngClass]="{'active':cargagas == 4}">
                                <span>Faixa 4</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 5" [ngClass]="{'active':cargagas == 5}">
                                <span>Faixa 5</span>
                            </a>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 1">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>276087</td>
                                    <td>LONGO COMERCIO DE COMBUSTVEIS LTDA</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2º Colocado</td>
                                    <td>259252</td>
                                    <td>G F DA SILVA COMERCIO DE GLP - ME</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>3º Colocado</td>
                                    <td>268835</td>
                                    <td>DISTRIBUIDORA DE GAS BELEM EIRELI</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>4º Colocado</td>
                                    <td>284815</td>
                                    <td>ALVES E SOARES COMERCIO VAREJISTA D</td>
                                    <td>PA</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>5º Colocado</td>
                                    <td>285024</td>
                                    <td>ASA COMBUSTIVEIS EIRELI</td>
                                    <td>PA</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>6º Colocado</td>
                                    <td>287087</td>
                                    <td>CENTRAL GAS GLE LTDA</td>
                                    <td>MA</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>7º Colocado</td>
                                    <td>285032</td>
                                    <td>POSTO DE COMBUSTIVEL INHANGAPI LTDA</td>
                                    <td>PA</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>8º Colocado</td>
                                    <td>21782</td>
                                    <td>A. F. NOBRE LTDA</td>
                                    <td>PA</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>9º Colocado</td>
                                    <td>21659</td>
                                    <td>F. LIMA COELHO</td>
                                    <td>MA</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>10º Colocado</td>
                                    <td>261788</td>
                                    <td>R S L FREITAS & CIA LTDA</td>
                                    <td>CE</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 2">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>284879</td>
                                    <td>STELIO GAS COMERCIO VAREJISTA DE GL</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>2º Colocado</td>
                                    <td>98341</td>
                                    <td>L S CASTRO DISTRIBUIDORA LTDA- ME</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>3º Colocado</td>
                                    <td>287074</td>
                                    <td>BOB GAS GUAMA LTDA</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>4º Colocado</td>
                                    <td>281487</td>
                                    <td>H S B COMERCIO DE GAS EIRELI</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>5º Colocado</td>
                                    <td>98385</td>
                                    <td>F. S. DE PAULA COMERCIO DE GAS - ME</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>6º Colocado</td>
                                    <td>81094</td>
                                    <td>REAL COMERCIO DE GAS LTDA ME</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>7º Colocado</td>
                                    <td>288042</td>
                                    <td>J R GAS LTDA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>8º Colocado</td>
                                    <td>93434</td>
                                    <td>NORT COMERCIO VAREJISTA DE GAS LTDA</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>9º Colocado</td>
                                    <td>67840</td>
                                    <td>A L V GOMES - ME</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>10º Colocado</td>
                                    <td>14111</td>
                                    <td>AGF COMERCIO VAREJISTA LTDA - ME</td>
                                    <td>PA</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 3">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>65066</td>
                                    <td>A. J. VALADARES PANTOJA</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>2º Colocado</td>
                                    <td>94676</td>
                                    <td>J F MENEZES COMERCIO E REPRESENTAÇÕ</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>3º Colocado</td>
                                    <td>268546</td>
                                    <td>W. M. COMERCIO DE GAS LTDA - ME</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>4º Colocado</td>
                                    <td>287672</td>
                                    <td>TOP TRANSPORTE LTDA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>5º Colocado</td>
                                    <td>281474</td>
                                    <td>F RIBEIRO FERREIRA EIRELI</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>6º Colocado</td>
                                    <td>287579</td>
                                    <td>PINHEIRO GAS LTDA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>7º Colocado</td>
                                    <td>92008</td>
                                    <td>M P SOLER COMERCIO LTDA - ME</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>8º Colocado</td>
                                    <td>271527</td>
                                    <td>W. M. COMERCIO DE GAS LTDA - ME</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>9º Colocado</td>
                                    <td>97859</td>
                                    <td>F SEABRA DE PAULA - ME</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>10º Colocado</td>
                                    <td>287911</td>
                                    <td>M C MONTEIRO - ME</td>
                                    <td>MA</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 4">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>278479</td>
                                    <td>V. GONÇALVES PINHEIRO</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>2º Colocado</td>
                                    <td>288504</td>
                                    <td>CHAME GAS E CIA COMERCIO DE GAS LTD</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>3º Colocado</td>
                                    <td>89325</td>
                                    <td>K B PINHEIRO COMERCIO DE GAS ME</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>4º Colocado</td>
                                    <td>281120</td>
                                    <td>TOP COMERCIO DE GAS EIRELI</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>5º Colocado</td>
                                    <td>272507</td>
                                    <td>VALADARES COMERCIO DE MERCADORIAS E</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>6º Colocado</td>
                                    <td>88235</td>
                                    <td>PINHEIRO GAS LTDA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>7º Colocado</td>
                                    <td>9713</td>
                                    <td>MARDEM FERREIRA SOUSA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>8º Colocado</td>
                                    <td>271716</td>
                                    <td>BONITO GAS LTDA</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>9º Colocado</td>
                                    <td>94160</td>
                                    <td>VALADARES & CIA LTDA</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>10º Colocado</td>
                                    <td>287973</td>
                                    <td>GENIVAL DOS S SILVA</td>
                                    <td>MA</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 5">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>262518</td>
                                    <td>H. L. PANTOJA JUNIOR COM DE GAS</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>2º Colocado</td>
                                    <td>14317</td>
                                    <td>REPRISE GAS LTDA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>3º Colocado</td>
                                    <td>8147</td>
                                    <td>IRMÃOS OLIVEIRA & CIA LTDA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>4º Colocado</td>
                                    <td>262831</td>
                                    <td>ACELLES OLIVEIRA NOBRE EIRELI - ME</td>
                                    <td>PI</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>5º Colocado</td>
                                    <td>286468</td>
                                    <td>BELGAS LTDA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>6º Colocado</td>
                                    <td>275536</td>
                                    <td>VANCIRA M DE MACEDO</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>7º Colocado</td>
                                    <td>9070</td>
                                    <td>GAS DO SERTAO LTDA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>8º Colocado</td>
                                    <td>250847</td>
                                    <td>TECC EMPREENDIMENTOS LTDA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>9º Colocado</td>
                                    <td>271710</td>
                                    <td>PACHECO GAS LTDA - ME</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>10º Colocado</td>
                                    <td>56278</td>
                                    <td>PORTO GAS LTDA</td>
                                    <td>MA</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div class="col-lg" *ngIf="region == 2">
                        <div class="step_secondary d-none">
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 1" [ngClass]="{'active':cargagas == 1}">
                                <span>Faixa 1</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 2" [ngClass]="{'active':cargagas == 2}">
                                <span>Faixa 2</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 3" [ngClass]="{'active':cargagas == 3}">
                                <span>Faixa 3</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 4" [ngClass]="{'active':cargagas == 4}">
                                <span>Faixa 4</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 5" [ngClass]="{'active':cargagas == 5}">
                                <span>Faixa 5</span>
                            </a>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 1">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>278658</td>
                                    <td>ECO POSTO 27 LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2º Colocado</td>
                                    <td>277730</td>
                                    <td>FERNANDO AMORIM CALIXTO</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>3º Colocado</td>
                                    <td>24735</td>
                                    <td>AUTO POSTO ITARARE DE SAO VICENTE</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>4º Colocado</td>
                                    <td>277263</td>
                                    <td>RAFAEL FERNANDO DA SILVA</td>
                                    <td>SP</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>5º Colocado</td>
                                    <td>278065</td>
                                    <td>DANIELE APARECIDA CARVALHO</td>
                                    <td>SP</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>6º Colocado</td>
                                    <td>23151</td>
                                    <td>AUTO POSTO VILA NOVA LTDA.</td>
                                    <td>SP</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>7º Colocado</td>
                                    <td>278716</td>
                                    <td>P. DA S.M. COSTA - GAS</td>
                                    <td>MG</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>8º Colocado</td>
                                    <td>22239</td>
                                    <td>AUTO POSTO SHALON DA NOVE DE ABRIL</td>
                                    <td>SP</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>9º Colocado</td>
                                    <td>43108</td>
                                    <td>AUTO POSTO ANTONIO EMERICK</td>
                                    <td>SP</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>10º Colocado</td>
                                    <td>85659</td>
                                    <td>BONDI & FILHOS LTDA</td>
                                    <td>SP</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 2">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>90171</td>
                                    <td>VICTOR RUZGUS PEREIRA PINTO</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>2º Colocado</td>
                                    <td>279419</td>
                                    <td>ANGELA DIAS MARTINS</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>3º Colocado</td>
                                    <td>274842</td>
                                    <td>W. A. DE OLIVEIRA REAL GAS</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>4º Colocado</td>
                                    <td>284970</td>
                                    <td>CENTRAL GAS PARAGUACU LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>5º Colocado</td>
                                    <td>271792</td>
                                    <td>ROGERIO GIMENEZ ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>6º Colocado</td>
                                    <td>10130</td>
                                    <td>QUALYGAS COMERCIO DE GAS LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>7º Colocado</td>
                                    <td>96384</td>
                                    <td>JAQUELINE DA SILVA PINTO ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>8º Colocado</td>
                                    <td>94956</td>
                                    <td>ANTUNES E MORYAMA GAS LTDA ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>9º Colocado</td>
                                    <td>84606</td>
                                    <td>MARIA DE FATIMA JUSTINO - ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>10º Colocado</td>
                                    <td>262114</td>
                                    <td>LORENA FERNANDES PEREIRA</td>
                                    <td>GO</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 3">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>288295</td>
                                    <td>JJ DA SILVA RODRIGUES</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>2º Colocado</td>
                                    <td>9357</td>
                                    <td>P.T.R COMERCIAL GAS LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>3º Colocado</td>
                                    <td>263740</td>
                                    <td>J BIRIBA COMERCIO E DISTRIBUIDORA D</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>4º Colocado</td>
                                    <td>284398</td>
                                    <td>DEBORA REGINA CARNEIRO ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>5º Colocado</td>
                                    <td>4891</td>
                                    <td>IRMAOS ADATI COM DE GAS LTDA - EPP</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>6º Colocado</td>
                                    <td>32043</td>
                                    <td>REAL PARQUE COMERCIO GAS LTDA. ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>7º Colocado</td>
                                    <td>261485</td>
                                    <td>BARUTI DEPOSITO DE GAS LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>8º Colocado</td>
                                    <td>15215</td>
                                    <td>CAVALLERI & CAVALLERI LTDA -EPP</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>9º Colocado</td>
                                    <td>9502</td>
                                    <td>FELIPE COMERCIO DE GAS LTDA - ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>10º Colocado</td>
                                    <td>273222</td>
                                    <td>COMERCIO DE AGUA E GAS DAMASCENA EI</td>
                                    <td>MG</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 4">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>271819</td>
                                    <td>SAMPAIO COMERCIO DE GAS LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>2º Colocado</td>
                                    <td>257818</td>
                                    <td>ROSELI DE LIMA RAMOS XAVIER ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>3º Colocado</td>
                                    <td>76816</td>
                                    <td>MARIA SUZETTE FERREIRA MARTINS ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>4º Colocado</td>
                                    <td>19594</td>
                                    <td>PETROGAS COMERCIO DE GAS LIMITADA M</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>5º Colocado</td>
                                    <td>12023</td>
                                    <td>FIORI COMERCIO GAS LTDA ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>6º Colocado</td>
                                    <td>11886</td>
                                    <td>COMERCIO DE GAS E AGUA ITUPEVA LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>7º Colocado</td>
                                    <td>43473</td>
                                    <td>GARROCINO & SANTI LTDA-EPP</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>8º Colocado</td>
                                    <td>286065</td>
                                    <td>PUROGAS COMERCIO DE GAS LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>9º Colocado</td>
                                    <td>275835</td>
                                    <td>CAIO EDUARDO BALBINO DE OLIVEIRA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>10º Colocado</td>
                                    <td>264633</td>
                                    <td>GASDEL COMERCIO DE GAS LTDA - EPP</td>
                                    <td>SP</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 5">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>263900</td>
                                    <td>DEL COMERCIO DE GAS EIRELI EPP</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>2º Colocado</td>
                                    <td>4288</td>
                                    <td>COM. GAS MEIRELLES LTDA.</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>3º Colocado</td>
                                    <td>94485</td>
                                    <td>EMANUEL SHALON COMERCIO DE GAS LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>4º Colocado</td>
                                    <td>276943</td>
                                    <td>EDI OLIVEIRA COMERCIO DE GAS GUAIAN</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>5º Colocado</td>
                                    <td>26250</td>
                                    <td>LUCIA M.M. RODRIGUES GAS ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>6º Colocado</td>
                                    <td>278267</td>
                                    <td>J.O.S LIMA GAS</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>7º Colocado</td>
                                    <td>43839</td>
                                    <td>OMEGAS COM.GAS LTDA.</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>8º Colocado</td>
                                    <td>277348</td>
                                    <td>VALLE COMERCIO DE GAS OURINHOS LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>9º Colocado</td>
                                    <td>21217</td>
                                    <td>GAS MARILIA LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>10º Colocado</td>
                                    <td>271705</td>
                                    <td>XAROPINHO GAS LTDA</td>
                                    <td>MG</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-lg" *ngIf="region == 3">
                        <div class="step_secondary d-none">
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 1" [ngClass]="{'active':cargagas == 1}">
                                <span>Faixa 1</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 2" [ngClass]="{'active':cargagas == 2}">
                                <span>Faixa 2</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 3" [ngClass]="{'active':cargagas == 3}">
                                <span>Faixa 3</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 4" [ngClass]="{'active':cargagas == 4}">
                                <span>Faixa 4</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 5" [ngClass]="{'active':cargagas == 5}">
                                <span>Faixa 5</span>
                            </a>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 1">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>281577</td>
                                    <td>D B MILLER</td>
                                    <td>ES</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2º Colocado</td>
                                    <td>286631</td>
                                    <td>UNIAO DEPOSITO DE GAS LTDA</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>3º Colocado</td>
                                    <td>267474</td>
                                    <td>INTER GAS COMERCIO VAREJISTA DE AGU</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>4º Colocado</td>
                                    <td>278511</td>
                                    <td>VITORIA LILIANE OLIVEIRA</td>
                                    <td>RN</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>5º Colocado</td>
                                    <td>280810</td>
                                    <td>GLAUCINEIA BIAZATTI LACERDA</td>
                                    <td>ES</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>6º Colocado</td>
                                    <td>288529</td>
                                    <td>DISTRIBUIDORA DE GÁS CHAPADA LTDA</td>
                                    <td>BA</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>7º Colocado</td>
                                    <td>287758</td>
                                    <td>RODRIGUES GAS LTDA</td>
                                    <td>RN</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>8º Colocado</td>
                                    <td>286295</td>
                                    <td>ARAUJO COMERCIO VAREJISTA LTDA</td>
                                    <td>GO</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>9º Colocado</td>
                                    <td>259899</td>
                                    <td>POSTO MONTE BELO COMERCIO E COMBUST</td>
                                    <td>RN</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>10º Colocado</td>
                                    <td>288287</td>
                                    <td>SOS GAS LTDA</td>
                                    <td>GO</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 2">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>275166</td>
                                    <td>SUELEN DARIA ALVES DE MELO GOMES ME</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>2º Colocado</td>
                                    <td>284426</td>
                                    <td>FENIX COMERCIO DE GAS E AGUA LTDA</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>3º Colocado</td>
                                    <td>94354</td>
                                    <td>ELOHI DISTRIBUIDORA DE GAS LTDA</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>4º Colocado</td>
                                    <td>270060</td>
                                    <td>CW DISTRIBUIDORA DE GAS E AGUA EIRE</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>5º Colocado</td>
                                    <td>279790</td>
                                    <td>CLARA CLARIANY DUARTE NOGUEIRA</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>6º Colocado</td>
                                    <td>277248</td>
                                    <td>JULIO CESAR SILVA DA MATA</td>
                                    <td>BA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>7º Colocado</td>
                                    <td>254796</td>
                                    <td>AUTO POSTO INTERMARES LTDA</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>8º Colocado</td>
                                    <td>262971</td>
                                    <td>JOVEM GAS SATIRO DIAS EIRELI</td>
                                    <td>BA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>9º Colocado</td>
                                    <td>279485</td>
                                    <td>HIPERGAS OLIVEIRA COMERCIO E SERVIÇ</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>10º Colocado</td>
                                    <td>280746</td>
                                    <td>YURI GOUVEIA DE LIMA ALMEIDA</td>
                                    <td>GO</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 3">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>275250</td>
                                    <td>A M COMERCIO VAREJISTA DE GLP LTDA</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>2º Colocado</td>
                                    <td>76746</td>
                                    <td>DEGAN COMERCIO DE GAS LTDA ME</td>
                                    <td>ES</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>3º Colocado</td>
                                    <td>73518</td>
                                    <td>BENEVIDES COMERCIAL DE GAS LTDA ME</td>
                                    <td>BA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>4º Colocado</td>
                                    <td>259495</td>
                                    <td>BATISTA E ALBUQUERQUE COMERCIO DE G</td>
                                    <td>BA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>5º Colocado</td>
                                    <td>9690</td>
                                    <td>FONSECA MARTINS COMERCIO DE GAS LTD</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>6º Colocado</td>
                                    <td>94301</td>
                                    <td>GAS UNIAO LTDA</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>7º Colocado</td>
                                    <td>76692</td>
                                    <td>POSTO DE GASOLINA MAIS LTDA ME</td>
                                    <td>ES</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>8º Colocado</td>
                                    <td>270050</td>
                                    <td>W. G. MARINHO DE JESUS COMERCIO VAR</td>
                                    <td>BA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>9º Colocado</td>
                                    <td>251752</td>
                                    <td>ALCINDO COMERCIO VAREJISTA LTDA</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>10º Colocado</td>
                                    <td>280878</td>
                                    <td>AJ COMERCIO DE GAS LTDA</td>
                                    <td>RN</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 4">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>94049</td>
                                    <td>IDEAL COMERCIO DE GAS LTDA</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>2º Colocado</td>
                                    <td>83142</td>
                                    <td>DMD COMERCIAL DE GAS LTDA</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>3º Colocado</td>
                                    <td>275860</td>
                                    <td>A. C. MATIAS COMERCIAL DE GAS</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>4º Colocado</td>
                                    <td>271190</td>
                                    <td>DONIZETE ROCHA DA SILVA</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>5º Colocado</td>
                                    <td>269587</td>
                                    <td>F A J AZEVEDO</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>6º Colocado</td>
                                    <td>256759</td>
                                    <td>ALEXSANDRO MORAIS ALVES</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>7º Colocado</td>
                                    <td>274635</td>
                                    <td>JOSEFA DE SOUSA VASCONCELOS</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>8º Colocado</td>
                                    <td>22070</td>
                                    <td>COMERCIO POMBALENSE DE GAS LTDA.</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>9º Colocado</td>
                                    <td>280829</td>
                                    <td>R E R DISTRIBUIDORA DE GAS LTDA</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>10º Colocado</td>
                                    <td>264855</td>
                                    <td>B R D DE FARIAS</td>
                                    <td>RN</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 5">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>279793</td>
                                    <td>ARAXAM GAS COMERCIO E TRANSPORTE LT</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>2º Colocado</td>
                                    <td>278825</td>
                                    <td>J P DA SILVA LOPES - MATRIZ</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>3º Colocado</td>
                                    <td>269839</td>
                                    <td>DISTRIBUIDORA DE GAS CHAPADA LTDA</td>
                                    <td>BA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>4º Colocado</td>
                                    <td>260449</td>
                                    <td>MESTRE ALVARO COMERCIO DE GAS LTDA</td>
                                    <td>ES</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>5º Colocado</td>
                                    <td>43231</td>
                                    <td>GASMETRO COM.TRANSP.GAS LTDA.</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>6º Colocado</td>
                                    <td>70988</td>
                                    <td>RENDEGAS COMERCIO DE GAS EIRELI</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>7º Colocado</td>
                                    <td>89828</td>
                                    <td>TIBIRI GAS LTDA</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>8º Colocado</td>
                                    <td>288748</td>
                                    <td>POWER FLOR COPAENERGIA LTDA</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>9º Colocado</td>
                                    <td>278487</td>
                                    <td>SPEED GAS COMERCIO E TRANSPORTE DE</td>
                                    <td>RJ</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>10º Colocado</td>
                                    <td>269385</td>
                                    <td>DAVI COMERCIO DE GAS LTDA ME</td>
                                    <td>RN</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-lg" *ngIf="region == 4">
                        <div class="step_secondary d-none">
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 1" [ngClass]="{'active':cargagas == 1}">
                                <span>Faixa 1</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 2" [ngClass]="{'active':cargagas == 2}">
                                <span>Faixa 2</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 3" [ngClass]="{'active':cargagas == 3}">
                                <span>Faixa 3</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 4" [ngClass]="{'active':cargagas == 4}">
                                <span>Faixa 4</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="cargagas = 5" [ngClass]="{'active':cargagas == 5}">
                                <span>Faixa 5</span>
                            </a>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 1">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>279277</td>
                                    <td>PALOMA DUARTE URIAS DOS SANTOS</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>2º Colocado</td>
                                    <td>288731</td>
                                    <td>EDILSON DA COSTA LTDA</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>3º Colocado</td>
                                    <td>97292</td>
                                    <td>JOSE CLAUDIO LUZ</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>4º Colocado</td>
                                    <td>264993</td>
                                    <td>JUNIOR PINHEIRO GODOY-ME</td>
                                    <td>RS</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>5º Colocado</td>
                                    <td>253775</td>
                                    <td>GLECI MELO DA MOTTA</td>
                                    <td>RS</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>6º Colocado</td>
                                    <td>60229</td>
                                    <td>KLOPPENBURG MINIMERCADO LTDA</td>
                                    <td>RS</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>7º Colocado</td>
                                    <td>287950</td>
                                    <td>MINIMERCADO TRES NOVE DOIS LTDA</td>
                                    <td>RS</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>8º Colocado</td>
                                    <td>272775</td>
                                    <td>TIAGO MADRID DA SILVA</td>
                                    <td>RS</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>9º Colocado</td>
                                    <td>288361</td>
                                    <td>PABLO DE OLIVEIRA AIRES LTDA</td>
                                    <td>RS</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>10º Colocado</td>
                                    <td>286848</td>
                                    <td>GILMAR SOARES FERRAZ</td>
                                    <td>RS</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 2">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>250990</td>
                                    <td>JOSIMAR GUERRA VARGAS - ME</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>2º Colocado</td>
                                    <td>281618</td>
                                    <td>JUSSARA DE VASCONCELOS MESQUITA</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>3º Colocado</td>
                                    <td>274466</td>
                                    <td>BASTOS & SILVA COMERCIO DE GAS E AG</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>4º Colocado</td>
                                    <td>280985</td>
                                    <td>Y L J ESPINDOLA COMERCIO DE GAS LTD</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>5º Colocado</td>
                                    <td>256902</td>
                                    <td>TELEGAS CHUI LTDA - ME</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>6º Colocado</td>
                                    <td>285834</td>
                                    <td>COMERCIAL DE GAS SIQUEIRA EIRELI</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>7º Colocado</td>
                                    <td>287081</td>
                                    <td>CENTER GAS EIRELI</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>8º Colocado</td>
                                    <td>287566</td>
                                    <td>SUL GAS LTDA</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>9º Colocado</td>
                                    <td>62884</td>
                                    <td>ABASTECEDORA WALTZER LTDA</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>10º Colocado</td>
                                    <td>285444</td>
                                    <td>RIBEIRO DE PRADO COMERCIO DE GAS LT</td>
                                    <td>PR</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 3">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>19330</td>
                                    <td>KELLY SILVEIRA OLIVEIRA - EPP</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>2º Colocado</td>
                                    <td>6636</td>
                                    <td>VALMIR MARCON</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>3º Colocado</td>
                                    <td>269437</td>
                                    <td>JOSIANE BORBA DE FREITAS - ME</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>4º Colocado</td>
                                    <td>59897</td>
                                    <td>CRISTIANE ANDREIA CARRASCO OLIVEIRA</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>5º Colocado</td>
                                    <td>74595</td>
                                    <td>COMERCIO DE GAS TONINI LTDA</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>6º Colocado</td>
                                    <td>67216</td>
                                    <td>FRATELLI COMERCIO DE GÁS E TRASNPOR</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>7º Colocado</td>
                                    <td>277740</td>
                                    <td>HEVERTON RODRIGUES REBECHI</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>8º Colocado</td>
                                    <td>29374</td>
                                    <td>COMERCIAL DE GAS GASPEL LTDA</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>9º Colocado</td>
                                    <td>284182</td>
                                    <td>MOTTIM & MOTTIM DISTRIBUIDORA LTDA</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>10º Colocado</td>
                                    <td>263746</td>
                                    <td>COMERCIO E TRANSPORTE DE GAS PERUSS</td>
                                    <td>PR</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 4">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>278589</td>
                                    <td>I. DE BORTOLI PACHECO EIRELI</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>2º Colocado</td>
                                    <td>40383</td>
                                    <td>ANA CRISTINA ONGARATTO</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>3º Colocado</td>
                                    <td>97286</td>
                                    <td>PAULO PEREIRA PAIVA GAS - EIRELI</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>4º Colocado</td>
                                    <td>256292</td>
                                    <td>E.P FERNANDES DISTR DE AGUA E GAS</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>5º Colocado</td>
                                    <td>12007</td>
                                    <td>DISTRIB DE GAS SOARES LTDA</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>6º Colocado</td>
                                    <td>88998</td>
                                    <td>SCHINALVES TRANSPORTES E DISTRIBUID</td>
                                    <td>SC</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>7º Colocado</td>
                                    <td>60341</td>
                                    <td>GASPURO COMERCIO DE GAS EIRELI</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>8º Colocado</td>
                                    <td>264382</td>
                                    <td>M&M COMERCIO E TRANSPORTES LTDA - M</td>
                                    <td>SC</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>9º Colocado</td>
                                    <td>93827</td>
                                    <td>COELHO GAS LTDA. - ME</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>10º Colocado</td>
                                    <td>91050</td>
                                    <td>COMERCIO DE GAS FANTINEL LTDA.</td>
                                    <td>RS</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="cargagas == 5">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>17202</td>
                                    <td>COLOMBO GAS LTDA</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>2º Colocado</td>
                                    <td>263367</td>
                                    <td>MAURO KOSBY BRIAO - ME</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>3º Colocado</td>
                                    <td>28188</td>
                                    <td>GASTEM COM.TRANSP.GAS LAPA LTDA.</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>4º Colocado</td>
                                    <td>253261</td>
                                    <td>VANESSA GONÇALVES PAIVA DIST. DE GA</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>5º Colocado</td>
                                    <td>10778</td>
                                    <td>PIONEIRO COMERCIO DE GAS LTDA</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>6º Colocado</td>
                                    <td>31445</td>
                                    <td>ALBANO BORGES MARRINHAS ME</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>7º Colocado</td>
                                    <td>99303</td>
                                    <td>RJR COMERCIO DE GAS LTDA</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>8º Colocado</td>
                                    <td>254541</td>
                                    <td>P&S TRANSPORTE E DISTRIBUIÇÃO DE GA</td>
                                    <td>SC</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>9º Colocado</td>
                                    <td>20097</td>
                                    <td>COM.TRANSP. GAS BROD LTDA</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>10º Colocado</td>
                                    <td>37170</td>
                                    <td>COMERCIAL DE GAS CAINELLI LTDA</td>
                                    <td>RS</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>

                <div class="step mt-4">
                    <a href="javascript:void(0)" class="step-item active">
                        <span>KIT Desafio</span>
                    </a>
                </div>
                <div class="row justify-content-end">
                    <div class="col-lg-2">
                        <p class="prize_text">As revendas que ficaram em 1º colocado foram premiadas com o Kit Desafio,
                            de acordo com sua região e faixa, totalizando 20 revendas premiadas:</p>


                    </div>
                    <div class="col-lg">
                        <div class="step_secondary">
                            <a href="javascript:void(0)" class="step-item"
                               (click)="kitdesafio = 1" [ngClass]="{'active':kitdesafio == 1}">
                                <span>Região 1</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="kitdesafio = 2" [ngClass]="{'active':kitdesafio == 2}">
                                <span>Região 2</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="kitdesafio = 3" [ngClass]="{'active':kitdesafio == 3}">
                                <span>Região 3</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="kitdesafio = 4" [ngClass]="{'active':kitdesafio == 4}">
                                <span>Região 4</span>
                            </a>
                        </div>
                        <div class="table-responsive" *ngIf="kitdesafio == 1">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th scope="col"><span>FAIXA</span></th>
                                    <th scope="col"><span>POSIÇÂO</span></th>
                                    <th scope="col"><span>CÓDIGO</span></th>
                                    <th scope="col"><span>RAZÃO SOCIAL</span></th>
                                    <th scope="col"><span>UF</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>276087</td>
                                    <td>LONGO COMERCIO DE COMBUSTVEIS LTDA</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>284879</td>
                                    <td>STELIO GAS COMERCIO VAREJISTA DE GL</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>65066</td>
                                    <td>A. J. VALADARES PANTOJA</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>278479</td>
                                    <td>V. GONÇALVES PINHEIRO</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>262518</td>
                                    <td>H. L. PANTOJA JUNIOR COM DE GAS</td>
                                    <td>PA</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="kitdesafio == 2">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th scope="col"><span>FAIXA</span></th>
                                    <th scope="col"><span>POSIÇÂO</span></th>
                                    <th scope="col"><span>CÓDIGO</span></th>
                                    <th scope="col"><span>RAZÃO SOCIAL</span></th>
                                    <th scope="col"><span>UF</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>278658</td>
                                    <td>ECO POSTO 27 LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>90171</td>
                                    <td>VICTOR RUZGUS PEREIRA PINTO</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>288295</td>
                                    <td>JJ DA SILVA RODRIGUES</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>271819</td>
                                    <td>SAMPAIO COMERCIO DE GAS LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>263900</td>
                                    <td>DEL COMERCIO DE GAS EIRELI EPP</td>
                                    <td>SP</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="kitdesafio == 3">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th scope="col"><span>FAIXA</span></th>
                                    <th scope="col"><span>POSIÇÂO</span></th>
                                    <th scope="col"><span>CÓDIGO</span></th>
                                    <th scope="col"><span>RAZÃO SOCIAL</span></th>
                                    <th scope="col"><span>UF</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>281577</td>
                                    <td>D B MILLER</td>
                                    <td>ES</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>275166</td>
                                    <td>SUELEN DARIA ALVES DE MELO GOMES ME</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>275250</td>
                                    <td>A M COMERCIO VAREJISTA DE GLP LTDA</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>94049</td>
                                    <td>IDEAL COMERCIO DE GAS LTDA</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>279793</td>
                                    <td>ARAXAM GAS COMERCIO E TRANSPORTE LT</td>
                                    <td>GO</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="kitdesafio == 4">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th scope="col"><span>FAIXA</span></th>
                                    <th scope="col"><span>POSIÇÂO</span></th>
                                    <th scope="col"><span>CÓDIGO</span></th>
                                    <th scope="col"><span>RAZÃO SOCIAL</span></th>
                                    <th scope="col"><span>UF</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>279277</td>
                                    <td>PALOMA DUARTE URIAS DOS SANTOS</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>250990</td>
                                    <td>JOSIMAR GUERRA VARGAS - ME</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>19330</td>
                                    <td>KELLY SILVEIRA OLIVEIRA - EPP</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>278589</td>
                                    <td>I. DE BORTOLI PACHECO EIRELI</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>17202</td>
                                    <td>COLOMBO GAS LTDA</td>
                                    <td>PR</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-graydark py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h3 class="prize_subtitle mb-5 text-primary">
                        Time <span class="text-secondary">Preparado</span>
                    </h3>
                    <p class="prize_text">
                        Confira as revendas que tiveram os melhores desempenhos por equipe nos treinamentos da mecânica
                        <b class="text-primary">Time Preparado</b> de Outubro/22 à Fevereiro/23 e que serão premiados
                        com R$ 500,00 no  e com Kits uniformes de acordo com sua faixa.
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-9">
                    <div class="step_secondary">
                        <a href="javascript:void(0)" class="step-item"
                           (click)="timepreparado = 1" [ngClass]="{'active':timepreparado == 1}">
                            <span>Região 1</span>
                        </a>
                        <a href="javascript:void(0)" class="step-item"
                           (click)="timepreparado = 2" [ngClass]="{'active':timepreparado == 2}">
                            <span>Região 2</span>
                        </a>
                        <a href="javascript:void(0)" class="step-item"
                           (click)="timepreparado = 3" [ngClass]="{'active':timepreparado == 3}">
                            <span>Região 3</span>
                        </a>
                        <a href="javascript:void(0)" class="step-item"
                           (click)="timepreparado = 4" [ngClass]="{'active':timepreparado == 4}">
                            <span>Região 4</span>
                        </a>
                    </div>
                    <div class="table-responsive" *ngIf="timepreparado == 1">
                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th><span>Faixa </span></th>
                                <th><span>Posição </span></th>
                                <th><span>Código </span></th>
                                <th><span>Razão Social </span></th>
                                <th><span>UF </span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>1º Colocado</td>
                                <td>268835</td>
                                <td>DISTRIBUIDORA DE GAS BELEM EIRELI</td>
                                <td>PA</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>1º Colocado</td>
                                <td>284879</td>
                                <td>STELIO GAS COMERCIO VAREJISTA DE GL</td>
                                <td>PA</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>1º Colocado</td>
                                <td>65066</td>
                                <td>A. J. VALADARES PANTOJA</td>
                                <td>PA</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>1º Colocado</td>
                                <td>278479</td>
                                <td>V. GONCALVES PINHEIRO</td>
                                <td>MA</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>1º Colocado</td>
                                <td>286468</td>
                                <td>BELGAS LTDA</td>
                                <td>MA</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive" *ngIf="timepreparado == 2">
                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th><span>Faixa </span></th>
                                <th><span>Posição </span></th>
                                <th><span>Código </span></th>
                                <th><span>Razão Social </span></th>
                                <th><span>UF </span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>1º Colocado</td>
                                <td>16552</td>
                                <td>AUTO POSTO PONTAL LTDA.</td>
                                <td>GO</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>1º Colocado</td>
                                <td>90171</td>
                                <td>VICTOR RUZGUS PEREIRA PINTO</td>
                                <td>SP</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>1º Colocado</td>
                                <td>9502</td>
                                <td>FELIPE COMERCIO DE GAS LTDA - ME</td>
                                <td>SP</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>1º Colocado</td>
                                <td>257818</td>
                                <td>ROSELI DE LIMA RAMOS XAVIER ME</td>
                                <td>SP</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>1º Colocado</td>
                                <td>263900</td>
                                <td>DEL COMERCIO DE GAS EIRELI EPP</td>
                                <td>SP</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive" *ngIf="timepreparado == 3">
                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th><span>Faixa </span></th>
                                <th><span>Posição </span></th>
                                <th><span>Código </span></th>
                                <th><span>Razão Social </span></th>
                                <th><span>UF </span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>1º Colocado</td>
                                <td>287758</td>
                                <td>RODRIGUES GAS LTDA</td>
                                <td>RN</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>1º Colocado</td>
                                <td>275166</td>
                                <td>SUELEN DARIA ALVES DE MELO GOMES ME</td>
                                <td>PB</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>1º Colocado</td>
                                <td>275250</td>
                                <td>A M COMERCIO VAREJISTA DE GLP LTDA</td>
                                <td>GO</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>1º Colocado</td>
                                <td>264855</td>
                                <td>B R D DE FARIAS</td>
                                <td>RN</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>1º Colocado</td>
                                <td>278825</td>
                                <td>J P DA SILVA LOPES - MATRIZ</td>
                                <td>RN</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive" *ngIf="timepreparado == 4">
                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th><span>Faixa </span></th>
                                <th><span>Posição </span></th>
                                <th><span>Código </span></th>
                                <th><span>Razão Social </span></th>
                                <th><span>UF </span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>1º Colocado</td>
                                <td>97292</td>
                                <td>JOSE CLAUDIO LUZ</td>
                                <td>RS</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>1º Colocado</td>
                                <td>285834</td>
                                <td>COMERCIAL DE GAS SIQUEIRA EIRELI</td>
                                <td>RS</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>1º Colocado</td>
                                <td>284464</td>
                                <td>ADRIANA ANTUNES DE OLIVEIRA</td>
                                <td>PR</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>1º Colocado</td>
                                <td>278589</td>
                                <td>I. DE BORTOLI PACHECO EIRELI</td>
                                <td>PR</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>1º Colocado</td>
                                <td>263367</td>
                                <td>MAURO KOSBY BRIAO - ME</td>
                                <td>RS</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>

            <div class="row" *ngIf="!resalePrepared">
                <div class="col-lg-9">
                    <div class="table-responsive">
                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th><span>Região </span></th>
                                <th><span>Faixa </span></th>
                                <th><span>Posição </span></th>
                                <th><span>Código </span></th>
                                <th><span>Razão Social </span></th>
                                <th><span>UF </span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Região 1</td>
                                <td>1</td>
                                <td>1º Colocado</td>
                                <td>268835</td>
                                <td>PACHECO GÁS LTDA - ME</td>
                                <td>PA</td>
                            </tr>
                            <tr>
                                <td>Região 2</td>
                                <td>2</td>
                                <td>1º Colocado</td>
                                <td>260348</td>
                                <td>LEANO COMERCIO DE GAS E AGUA LTDA E</td>
                                <td>PA</td>
                            </tr>
                            <tr>
                                <td>Região 3</td>
                                <td>3</td>
                                <td>1º Colocado</td>
                                <td>260348</td>
                                <td>ASC COMERCIO DE GAS EIRELI ME</td>
                                <td>MA</td>
                            </tr>
                            <tr>
                                <td>Região 4</td>
                                <td>5</td>
                                <td>1º Colocado</td>
                                <td>260348</td>
                                <td>POSTO DE GAS SOL NASCENTE</td>
                                <td>SP</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-3 text-center">
                    <img src="assets/images/winners-2.png" alt="winners-1">
                </div>
            </div>
        </div>
    </div>
    <div class="py-5">
        <div class="container">
            <div class="row" style="row-gap: 30px">
                <div class="col-lg-4">
                    <h3 class="prize_subtitle">
                        Padronização<br>
                        <span>da Revenda</span>
                    </h3>
                    <p class="prize_text">
                        As revendas que comprovaram a padronização da fachada da revenda de acordo com o manual da marca SistechProganharam cupons para concorrerem aos sorteios de até 10 mil reais, de acordo com sua região e faixa, confira quem foram os sortudos premiados:
                    </p>


                    <div class="row justify-content-center my-1">
                    <h5 class="card-title graytext mb-0 textsmall"><strong>DATA DO SORTEIO</strong></h5> &nbsp;
                    <h5 class="card-text text-primary mb-0 textsmall"><strong>24 de Abril de 2023</strong></h5>
                    </div>


                </div>
                <div class="col-lg-8">
                    <div class="step_secondary">
                        <a href="javascript:void(0)" class="step-item"
                           (click)="region2 = 1" [ngClass]="{'active':region2 == 1}">
                            <span>Região 1</span>
                        </a>
                        <a href="javascript:void(0)" class="step-item"
                           (click)="region2 = 2" [ngClass]="{'active':region2 == 2}">
                            <span>Região 2</span>
                        </a>
                        <a href="javascript:void(0)" class="step-item"
                           (click)="region2 = 3" [ngClass]="{'active':region2 == 3}">
                            <span>Região 3</span>
                        </a>
                        <a href="javascript:void(0)" class="step-item"
                           (click)="region2 = 4" [ngClass]="{'active':region2 == 4}">
                            <span>Região 4</span>
                        </a>
                    </div>
                    <div class="table-responsive" *ngIf="region2 == 1">

                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th><span>Faixa </span></th>
                                <th><span>Código </span></th>
                                <th><span>Razão Social </span></th>
                                <th><span>UF </span></th>
                                <th><span>Valor </span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>268835</td>
                                <td>DISTRIBUIDORA DE GAS BELEM EIRELI</td>
                                <td>PA</td>
                                <td><strong class="text-primary">R$ 2 mil</strong></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>281487</td>
                                <td>H S B COMERCIO DE GAS EIRELI</td>
                                <td>PA</td>
                                <td><strong class="text-primary">R$ 4 mil</strong></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>279767</td>
                                <td>M A DA S DE SOUZA EIRELI</td>
                                <td>PA</td>
                                <td><strong class="text-primary">R$ 6 mil</strong></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>9713</td>
                                <td>MARDEM FERREIRA SOUSA</td>
                                <td>MA</td>
                                <td><strong class="text-primary">R$ 8 mil</strong></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>56278</td>
                                <td>PORTO GAS LTDA</td>
                                <td>MA</td>
                                <td><strong class="text-primary">R$ 10 mil</strong></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive" *ngIf="region2 == 2">
                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th><span>Faixa </span></th>
                                <th><span>Código </span></th>
                                <th><span>Razão Social </span></th>
                                <th><span>UF </span></th>
                                <th><span>Valor </span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>277263</td>
                                <td>RAFAEL FERNANDO DA SILVA</td>
                                <td>SP</td>
                                <td><strong class="text-primary">R$ 2 mil</strong></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>279419</td>
                                <td>ANGELA DIAS MARTINS</td>
                                <td>GO</td>
                                <td><strong class="text-primary">R$ 4 mil</strong></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>32043</td>
                                <td>REAL PARQUE COMERCIO GAS LTDA. ME</td>
                                <td>SP</td>
                                <td><strong class="text-primary">R$ 6 mil</strong></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>271819</td>
                                <td>SAMPAIO COMERCIO DE GAS LTDA</td>
                                <td>SP</td>
                                <td><strong class="text-primary">R$ 8 mil</strong></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>278267</td>
                                <td>J.O.S LIMA GAS</td>
                                <td>SP</td>
                                <td><strong class="text-primary">R$ 10 mil</strong></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive" *ngIf="region2 == 3">
                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th><span>Faixa </span></th>
                                <th><span>Código </span></th>
                                <th><span>Razão Social </span></th>
                                <th><span>UF </span></th>
                                <th><span>Valor </span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>287814</td>
                                <td>CHRISTIANO V. DE ANDRADE</td>
                                <td>AL</td>
                                <td><strong class="text-primary">R$ 2 mil</strong></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>280746</td>
                                <td>YURI GOUVEIA DE LIMA ALMEIDA</td>
                                <td>GO</td>
                                <td><strong class="text-primary">R$ 4 mil</strong></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>259495</td>
                                <td>BATISTA E ALBUQUERQUE COMERCIO DE G</td>
                                <td>BA</td>
                                <td><strong class="text-primary">R$ 6 mil</strong></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>68247</td>
                                <td>B.L LESSA-ME</td>
                                <td>AL</td>
                                <td><strong class="text-primary">R$ 8 mil</strong></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>70988</td>
                                <td>RENDEGAS COMERCIO DE GAS EIRELI</td>
                                <td>RN</td>
                                <td><strong class="text-primary">R$ 10 mil</strong></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive" *ngIf="region2 == 4">
                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th><span>Faixa </span></th>
                                <th><span>Código </span></th>
                                <th><span>Razão Social </span></th>
                                <th><span>UF </span></th>
                                <th><span>Valor </span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>280952</td>
                                <td>SILVA E SILVEIRA DISTRIBUIÇAO DE GA</td>
                                <td>RS</td>
                                <td><strong class="text-primary">R$ 2 mil</strong></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>274466</td>
                                <td>BASTOS & SILVA COMERCIO DE GAS E AG</td>
                                <td>RS</td>
                                <td><strong class="text-primary">R$ 4 mil</strong></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>286674</td>
                                <td>G. H. DE ANDRADE</td>
                                <td>RS</td>
                                <td><strong class="text-primary">R$ 6 mil</strong></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>39381</td>
                                <td>C FROHLICH & CIA LTDA</td>
                                <td>RS</td>
                                <td><strong class="text-primary">R$ 8 mil</strong></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>10778</td>
                                <td>PIONEIRO COMERCIO DE GAS LTDA</td>
                                <td>RS</td>
                                <td><strong class="text-primary">R$ 10 mil</strong></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-5">
        <div class="container">
            <div class="row" style="row-gap: 30px">
                <div class="col-lg-6">
                    <h3 class="prize_subtitle mb-5">Plataforma Aceleraí<br><span>e Multiplica</span></h3>
                    <p>Um privilégio para os melhores!</p>
                    <p class="prize_text">
                        Foram sorteadas 4 revendas (1 por região) com 1 super ingresso VIP para assistir uma das etapas
                        da Stock Car com tudo pago, 1 Kit incrível composto por por 1 boné, 1 camiseta e 1 sacochila
                        personalizadas. Além de R$ 500,00 para utilizar no dia da corrida.
                    </p>

                </div>
                <div class="col-lg-6">
                    <div class="card p-3 mb-4 text-center bg-secondary shadow-none border-0">
                        <div class="row justify-content-between">
                            <div class="col-auto">
                                <h5 class="card-title text-white mb-0"><strong>DATA DO SORTEIO</strong></h5>
                            </div>
                            <div class="col-auto">
                                <h5 class="card-text text-primary mb-0"><strong>9 de Novembro de 2023</strong></h5>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th><span>Região </span></th>
                                <th><span>Código </span></th>
                                <th><span>Razão Social </span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Região 1</td>
                                <td>260348</td>
                                <td>PACHECO GÁS LTDA - ME</td>
                            </tr>
                            <tr>
                                <td>Região 2</td>
                                <td>260348</td>
                                <td>LEANO COMERCIO DE GAS E AGUA LTDA E</td>
                            </tr>
                            <tr>
                                <td>Região 3</td>
                                <td>260348</td>
                                <td>ASC COMERCIO DE GAS EIRELI ME</td>
                            </tr>
                            <tr>
                                <td>Região 4</td>
                                <td>260348</td>
                                <td>POSTO DE GAS SOL NASCENTE</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-graydark py-5">
        <div class="container">
            <div class="position-relative">
                <h2 class="prize_title text-uppercase">Equipe <span>Revenda</span></h2>
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <h3 class="prize_subtitle text-primary">
                            Time <span class="text-secondary">Preparado</span>
                        </h3>
                        <p class="prize_text">
                            Confira as equipes que se destacaram na mecânica
                            <strong class="text-primary">Time Preparado</strong>
                            de Outubro/22 à Novembro/22 e Dezembro/22 à Fevereiro/23.
                        </p>
                    </div>
                </div>
                <div class="step_secondary-big">
                    <a href="javascript:void(0)" class="step-item"
                       (click)="dezembro = false" [ngClass]="{'active':!dezembro}">
                        <span>Outubro/2022 <span>à</span> Novembro/2022</span>
                    </a>
                    <a href="javascript:void(0)" class="step-item"
                       (click)="dezembro = true" [ngClass]="{'active':dezembro}">
                        <span>Dezembro/2022 <span>à</span> Fevereiro/2023</span>
                    </a>
                </div>
                <div class="row" *ngIf="!dezembro">

                    <div class="col-lg-10">
                        <div class="step_secondary">
                            <a href="javascript:void(0)" class="step-item"
                               (click)="novembroreg = 1" [ngClass]="{'active':novembroreg == 1}">
                                <span>Região 1</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="novembroreg = 2" [ngClass]="{'active':novembroreg == 2}">
                                <span>Região 2</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="novembroreg = 3" [ngClass]="{'active':novembroreg == 3}">
                                <span>Região 3</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="novembroreg = 4" [ngClass]="{'active':novembroreg == 4}">
                                <span>Região 4</span>
                            </a>
                        </div>
                        <div class="table-responsive" *ngIf="novembroreg == 1">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>268835</td>
                                    <td>DISTRIBUIDORA DE GAS BELEM EIRELI</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>281487</td>
                                    <td>H S B COMERCIO DE GAS EIRELI</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>287579</td>
                                    <td>PINHEIRO GAS LTDA</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>278479</td>
                                    <td>V. GONCALVES PINHEIRO</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>262518</td>
                                    <td>H. L. PANTOJA JUNIOR COM DE GAS</td>
                                    <td>PA</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="novembroreg == 2">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>277329</td>
                                    <td>R.M CONSTRUCAMPO EIRELI</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>274842</td>
                                    <td>W. A. DE OLIVEIRA REAL GAS</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>9502</td>
                                    <td>FELIPE COMERCIO DE GAS LTDA - ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>257818</td>
                                    <td>ROSELI DE LIMA RAMOS XAVIER ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>263900</td>
                                    <td>DEL COMERCIO DE GAS EIRELI EPP</td>
                                    <td>SP</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="novembroreg == 3">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>271284</td>
                                    <td>LORENA FELIPE SABINO</td>
                                    <td>GO</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>262971</td>
                                    <td>JOVEM GAS SATIRO DIAS EIRELI</td>
                                    <td>BA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>23425</td>
                                    <td>J M G DA SILVA GAS</td>
                                    <td>PE</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>264855</td>
                                    <td>B R D DE FARIAS</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>278487</td>
                                    <td>SPEED GAS COMERCIO E TRANSPORTE DE</td>
                                    <td>RJ</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="novembroreg == 4">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>279277</td>
                                    <td>PALOMA DUARTE URIAS DOS SANTOS</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>285834</td>
                                    <td>COMERCIAL DE GAS SIQUEIRA EIRELI</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>284464</td>
                                    <td>ADRIANA ANTUNES DE OLIVEIRA</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>97286</td>
                                    <td>PAULO PEREIRA PAIVA GAS - EIRELI</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>13945</td>
                                    <td>EDSON CARLO MENTA FILHO & CIA LTDA</td>
                                    <td>PR</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="dezembro">

                    <div class="col-lg-10">
                        <div class="step_secondary">
                            <a href="javascript:void(0)" class="step-item"
                               (click)="dezembroreg = 1" [ngClass]="{'active':dezembroreg == 1}">
                                <span>Região 1</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="dezembroreg = 2" [ngClass]="{'active':dezembroreg == 2}">
                                <span>Região 2</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="dezembroreg = 3" [ngClass]="{'active':dezembroreg == 3}">
                                <span>Região 3</span>
                            </a>
                            <a href="javascript:void(0)" class="step-item"
                               (click)="dezembroreg = 4" [ngClass]="{'active':dezembroreg == 4}">
                                <span>Região 4</span>
                            </a>
                        </div>
                        <div class="table-responsive" *ngIf="dezembroreg == 1">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>268835</td>
                                    <td>DISTRIBUIDORA DE GAS BELEM EIRELI</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>284879</td>
                                    <td>STELIO GAS COMERCIO VAREJISTA DE GL</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>65066</td>
                                    <td>A. J. VALADARES PANTOJA</td>
                                    <td>PA</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>278479</td>
                                    <td>V. GONCALVES PINHEIRO</td>
                                    <td>MA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>262831</td>
                                    <td>ACELLES OLIVEIRA NOBRE EIRELI - ME</td>
                                    <td>PI</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="dezembroreg == 2">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>278658</td>
                                    <td>ECO POSTO 27 LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>284970</td>
                                    <td>CENTRAL GAS PARAGUACU LTDA</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>9502</td>
                                    <td>FELIPE COMERCIO DE GAS LTDA - ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>257818</td>
                                    <td>ROSELI DE LIMA RAMOS XAVIER ME</td>
                                    <td>SP</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>263900</td>
                                    <td>DEL COMERCIO DE GAS EIRELI EPP</td>
                                    <td>SP</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="dezembroreg == 3">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>287758</td>
                                    <td>RODRIGUES GAS LTDA</td>
                                    <td>RN</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>275166</td>
                                    <td>SUELEN DARIA ALVES DE MELO GOMES ME</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>285532</td>
                                    <td>WAGNER NOBREGA DE OLIVEIRA PEDROSA</td>
                                    <td>PB</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>86701</td>
                                    <td>DISTRIBUIDORA DE GAS E AGUA BATISTA</td>
                                    <td>BA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>278825</td>
                                    <td>J P DA SILVA LOPES - MATRIZ</td>
                                    <td>RN</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" *ngIf="dezembroreg == 4">
                            <table class="table table-prize">
                                <thead>
                                <tr>
                                    <th><span>Faixa </span></th>
                                    <th><span>Posição </span></th>
                                    <th><span>Código </span></th>
                                    <th><span>Razão Social </span></th>
                                    <th><span>UF </span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>1º Colocado</td>
                                    <td>97292</td>
                                    <td>JOSE CLAUDIO LUZ</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>1º Colocado</td>
                                    <td>285834</td>
                                    <td>COMERCIAL DE GAS SIQUEIRA EIRELI</td>
                                    <td>RS</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>1º Colocado</td>
                                    <td>284464</td>
                                    <td>ADRIANA ANTUNES DE OLIVEIRA</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>1º Colocado</td>
                                    <td>278589</td>
                                    <td>I. DE BORTOLI PACHECO EIRELI</td>
                                    <td>PR</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>1º Colocado</td>
                                    <td>263367</td>
                                    <td>MAURO KOSBY BRIAO - ME</td>
                                    <td>RS</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>
