import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    GmrAdmin,
    GmrFindAllGQL,
    SearchTechnicalVisitGQL,
    SearchTechnicalVisitQuery, TechnicalVisitDto, TechnicalVisitGasStationInput, TechnicalVisitPage,
    TechnicalVisitSearchInput, UpdateTechnicalVisitGasStationGQL, User
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {UserService} from "../../service/user.service";

@Component({
    selector: 'app-technical-visit',
    templateUrl: './technical-visit.component.html',
    styleUrls: ['./technical-visit.component.scss']
})
export class TechnicalVisitComponent extends BaseComponent implements OnInit {
    gmrAll: GmrAdmin[] = [];
    filter: TechnicalVisitSearchInput = new TechnicalVisitSearchInput();
    searchTechnicalVisitQuery: QueryRef<SearchTechnicalVisitQuery>;
    technicalVisitPage: TechnicalVisitPage = new TechnicalVisitPage();
    technicalVisit: TechnicalVisitDto = new TechnicalVisitDto();
    gasStation: TechnicalVisitGasStationInput = new TechnicalVisitGasStationInput();
    user: User = new User();
    disableGme: boolean;

    constructor(public router: Router,
                public userService: UserService,
                private searchTechnicalVisitGQL: SearchTechnicalVisitGQL,
                private updateTechnicalVisitGasStationGQL: UpdateTechnicalVisitGasStationGQL,
                private gmrFindAllGQL: GmrFindAllGQL,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.user = data as User;
                    this.disableGme = !this.isNullOrUndefined(this.user.gmr.id);
                    if (this.disableGme) {
                        this.filter.gmr = this.user.gmr.id;
                    }
                    this.gmrFindAllGQL.watch().valueChanges.subscribe(({data}) => {
                        this.gmrAll = data.gmrFindAll as GmrAdmin[];
                    }, error => super.onError(error));
                    this.onSearch();
                }
            }, error: err => super.onError(err)
        });

    }

    onSearch() {
        this.searchTechnicalVisitQuery = this.searchTechnicalVisitGQL.watch({input: this.filter});
        this.searchTechnicalVisitQuery.valueChanges.subscribe(({data}) => {
            this.technicalVisitPage = data.searchTechnicalVisit as TechnicalVisitPage;
        }, error => super.onError(error));
    }

    onFilter() {
        this.searchTechnicalVisitQuery.refetch({input: this.filter}).then();
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.onFilter();
    }

    handlerDetail(item: TechnicalVisitDto) {
        this.technicalVisit = item;
        if (this.isNullOrUndefined(item.gasStation)) {
            this.showModal('modalGasStation');
            this.gasStation = new TechnicalVisitGasStationInput();
        } else {
            this.router.navigate(['/visita-tecnica/' + item.technicalVisitId]).then()
        }
    }

    changeGasStation() {
        if (!this.gasStation.gasStation) {
            this.gasStation.isFacade = true;
        }
        if (this.isNullOrUndefined(this.gasStation.gasStation)) {
            super.showMessage('Atenção', 'Escolha um tipo de revenda', 'warning');
            return;
        }
        if (this.isNullOrUndefined(this.gasStation.isFacade) || this.isNullOrUndefined(this.gasStation.isFleet)) {
            super.showMessage('Atenção', 'Responda todas as perguntas para prosseguir', 'warning');
            return;
        }
        this.gasStation.technicalVisitId = this.technicalVisit.technicalVisitId;
        this.gasStation.isClearStandardizationItem = false;
        this.updateTechnicalVisitGasStationGQL.mutate({input: this.gasStation}).subscribe(({data}) => {
            this.closeModal('modalGasStation');
            this.router.navigate(['/visita-tecnica/' + this.gasStation.technicalVisitId]).then();
        }, error => super.onError(error));
    }

    handlerGasStation(gas: boolean) {
        this.gasStation.gasStation = gas;
        this.gasStation.isFleet = null;
        this.gasStation.isFacade = null;
    }

    validateGas() {
        return this.isNullOrUndefined(this.gasStation.isFacade) || this.isNullOrUndefined(this.gasStation.isFleet) || this.isNullOrUndefined(this.gasStation.gasStation);
    }
}
