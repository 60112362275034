import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {
    FindAllRangeGQL,
    GmrAdmin, GmrFindAllGQL, GmrRegion, Range, RankingResale,
    RankingResaleDetailsGQL,
    RankingResaleDetailsPage, RankingResaleDetailsQuery,
    RankingResaleInput, RegionFindAllGQL,
    User
} from '../../../generated/graphql';
import moment from "moment";
import {QueryRef} from "apollo-angular";

declare var $: any;

@Component({
    selector: 'app-ranking-detail',
    templateUrl: './ranking-detail.component.html',
    styleUrls: ['./ranking-detail.component.scss']
})
export class RankingDetailComponent extends BaseComponent implements OnInit {
    stepTraining = 0;
    user: User = new User();
    rankingResaleInput: RankingResaleInput = new RankingResaleInput();

    rankingResalePage: RankingResaleDetailsPage = new RankingResaleDetailsPage();
    gmrFindAll: GmrAdmin[] = [];
    regionFindAll: GmrRegion[] = [];
    findAllRange: Range[] = [];
    handlerMasterBool: boolean = false;
    handlerCommercialBool: boolean = false;
    rankingResaleQuery: QueryRef<RankingResaleDetailsQuery>;

    constructor(public router: Router,
                public userService: UserService,
                public rankingResaleDetailsGQL: RankingResaleDetailsGQL,
                public gmrFindAllGQL: GmrFindAllGQL,
                public regionFindAllGQL: RegionFindAllGQL,
                public findAllRangeGQL: FindAllRangeGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.user = data as User;
                    this.configFilter();
                    this.rankingResaleFilter();
                }
            }, error: err => super.onError(err)
        });

        $(() => {
            $('#modalRankingInfo').modal('show');
        });

    }

    configFilter() {
        const indexMount = this.listOfMonths.map((e) => e.id).indexOf(moment().month() + 1);
        if (indexMount == -1) {
            this.rankingResaleInput.month = this.listOfMonths[4].id;
            this.rankingResaleInput.year = this.listOfMonths[4].year;
        } else {
            this.rankingResaleInput.month = this.listOfMonths[indexMount - 1].id; // abrir mes anterior
            this.rankingResaleInput.year = this.listOfMonths[indexMount - 1].year; // abrir mes anterior
        }
        this.handlerMasterBool = this.handlerMaster();
        this.handlerCommercialBool = this.handlerCommercial();
        this.rankingResaleInput.page = 1;
    }

    rankingResaleFilter() {
        this.regionFindAllGQL.watch().valueChanges.subscribe(({data}) => {
            this.regionFindAll = data.regionFindAll as GmrRegion[];
            // VALID IF USER HAS REGION
            if (!this.isNullOrUndefined(this.user.gmr?.region)) {
                this.rankingResaleInput.region = this.user.gmr!.region;
            } else {
                this.rankingResaleInput.region = this.regionFindAll[0]?.name;
            }
            this.findRange();
        }, error => super.onError(error));
    }

    findRange() {
        this.findAllRangeGQL.watch().valueChanges.subscribe(({data}) => {
            this.findAllRange = data.findAllRange as Range[];
            // VALID IF USER HAS RANGE
            if (!this.isNullOrUndefined(this.user.range?.id)) {
                this.rankingResaleInput.rangeId = this.user.range!.id;
            } else this.rankingResaleInput.rangeId = this.findAllRange[0].id;

            this.rankingResale();
        }, error => super.onError(error));
    }

    rankingResale() {
        this.rankingResaleQuery = this.rankingResaleDetailsGQL.watch({input: this.rankingResaleInput});
        this.rankingResaleQuery.valueChanges.subscribe(({data}) => {
            this.rankingResalePage = data.rankingResaleDetails as RankingResaleDetailsPage;
        }, error => super.onError(error));
    }

    handlerMaster() {
        return this.user.profile.id.toLowerCase() === 'ma';
    }

    handlerCommercial() {
        return this.handlerMaster() || this.user.profile.id.toLowerCase() === 'ec';
    }


    handlerTraining(l) {
        if (l.active) {
            this.rankingResaleInput.month = l.id;
            this.rankingResaleInput.year = l.year;
            this.onSearchPage(1);
        }
    }

    onSearchPage(event: number) {
        this.rankingResaleInput.page = event;
        this.rankingResaleQuery.refetch({input: this.rankingResaleInput}).then();
    }
}
