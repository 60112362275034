import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prizes-eq',
  templateUrl: './prizes-eq.component.html',
  styleUrls: ['./prizes-eq.component.scss']
})
export class PrizesEqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
