import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TechnicalVisitGasStationInput, UpdateTechnicalVisitGasStationGQL} from "../../../generated/graphql";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-modal-gas-station',
    templateUrl: './modal-gas-station.component.html',
    styleUrls: ['./modal-gas-station.component.scss']
})
export class ModalGasStationComponent extends BaseComponent implements OnInit {
    @Input() id: string = 'modalGasStation';
    @Input() gasStationInput: TechnicalVisitGasStationInput = new TechnicalVisitGasStationInput();
    @Output() eventSend: EventEmitter<any> = new EventEmitter<any>();


    constructor(public router: Router,
                private updateTechnicalVisitGasStationGQL: UpdateTechnicalVisitGasStationGQL,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
    }

    updateGasStation() {
        if (!this.gasStationInput.gasStation) {
            this.gasStationInput.isFacade = true;
        }
        if (this.isNullOrUndefined(this.gasStationInput.isFacade) || this.isNullOrUndefined(this.gasStationInput.isFleet)) {
            super.showMessage('Atenção', 'Responda todas as perguntas para prosseguir', 'warning');
            return;
        }
        this.updateTechnicalVisitGasStationGQL.mutate({input: this.gasStationInput}).subscribe(({data}) => {
            this.closeModal(this.id);
            this.eventSend.emit();
        }, error => super.onError(error));
    }

}
