import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    SendTechnicalVisitChatGQL,
    TechnicalVisitChat,
    TechnicalVisitChatInput, User,
} from "../../../generated/graphql";

declare var $: any;

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent extends BaseComponent implements OnInit {
    @Input()
    itemId?: string;
    @Input()
    status: string;
    @Input()
    chat: TechnicalVisitChat[] = [];
    @Output() chatChange: EventEmitter<TechnicalVisitChat[]> = new EventEmitter<TechnicalVisitChat[]>();

    constructor(public router: Router,
                private sendTechnicalVisitChatGQL: SendTechnicalVisitChatGQL,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
    }

    onSend(idMessage) {
        let input = $('#' + idMessage)[0];
        if (this.isNullOrUndefined(input.innerText)) {
            return;
        }
        if (this.status.toLowerCase() !== 'revisar') {
            return super.showMessage('Atenção', 'Mensagens não podemasdasd  ser enviadas com status pendente',);
        }
        let chatInput: TechnicalVisitChatInput = {
            technicalVisitItemId: this.itemId,
            message: input.innerText,
        };
        console.log(chatInput);
        this.sendTechnicalVisitChatGQL.mutate({input: chatInput}).subscribe(({data}) => {
            this.chatChange.emit(data.sendTechnicalVisitChat as TechnicalVisitChat[]);
            input.innerText = '';
        }, error => super.onError(error));
    }
}
