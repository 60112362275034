<a class="doubts" href="https://wa.me/5511939427843" target="_blank">
    <div class="doubts_whats">
        <svg xmlns="http://www.w3.org/2000/svg" width="25.565" height="25.565" viewBox="0 0 25.565 25.565">
            <path id="Icon_simple-whatsapp" data-name="Icon simple-whatsapp"
                  d="M18.639,15.32c-.321-.16-1.882-.924-2.173-1.029s-.5-.16-.717.16-.821,1.027-1.006,1.238-.372.224-.688.08a8.645,8.645,0,0,1-2.56-1.582,9.664,9.664,0,0,1-1.768-2.205c-.185-.32-.02-.5.138-.655.145-.144.321-.368.48-.557a2.7,2.7,0,0,0,.316-.528.586.586,0,0,0-.027-.558c-.08-.16-.716-1.726-.982-2.35s-.519-.543-.716-.543c-.183-.016-.4-.016-.608-.016a1.2,1.2,0,0,0-.849.382A3.556,3.556,0,0,0,6.368,9.793a6.233,6.233,0,0,0,1.3,3.276A14.2,14.2,0,0,0,13.1,17.846c.761.32,1.353.511,1.815.67a4.407,4.407,0,0,0,2,.129,3.278,3.278,0,0,0,2.148-1.519,2.637,2.637,0,0,0,.192-1.518c-.079-.144-.288-.224-.607-.368m-5.8,7.928h-.017A10.656,10.656,0,0,1,7.446,21.7l-.383-.228L3.068,22.509l1.071-3.883-.255-.4A10.565,10.565,0,0,1,20.338,5.194a10.427,10.427,0,0,1,3.1,7.446A10.578,10.578,0,0,1,12.854,23.17m9-19.5A12.934,12.934,0,0,0,12.831,0,12.665,12.665,0,0,0,1.807,19L0,25.565l6.748-1.76a12.849,12.849,0,0,0,6.082,1.541h.006A12.719,12.719,0,0,0,25.565,12.675a12.564,12.564,0,0,0-3.723-8.96"
                  fill="#fff"/>
        </svg>
    </div>
    <p class="doubts_title mb-0">Dúvidas?</p>
    <p class="doubts_text">Fale com<br>a gente.</p>
</a>
