import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {AuthInput, AuthType, ForgotPasswordGQL, LoginGQL, User} from "../../../generated/graphql";
import {UserService} from "../../service/user.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
    isForgot = 1;
    authInput: AuthInput = new AuthInput();
    authType: AuthType = new AuthType();
    isEyeBool: boolean = false;

    constructor(public router: Router,
                public loginGQL: LoginGQL,
                public service: UserService,
                public forgotPasswordGQL: ForgotPasswordGQL,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
    }

    addEventListener() {

    }

    onLogin() {
        const arrValidateFields = [
            {value: this.authInput.CpfCnpj, text: `CPF/CNPJ <br>`},
            {value: this.authInput.password, text: `Senha <br>`}
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos obrigatórios corretamente: <br> ${stringError}`);
            return;
        }

        this.loginGQL.mutate({input: this.authInput}).subscribe(({data}) => {
            this.authType = data.login as AuthType;
            if (this.isNullOrUndefined(this.authType.user.activatedAt)) {
                return this.showModal('isProfile');
            } else {
                this.saveLocalUser(this.authType);
            }
        }, error => super.onError(error));
    }

    handlerModalForgot() {
        this.showModal('isForgot')
        this.isForgot = 2;
    }

    handlerToken(user: User) {
        this.authType.user = user;
        console.log('this.authType');
        console.log(this.authType);
        this.saveLocalUser(this.authType);
    }

    saveLocalUser(data) {
        const token = data as AuthType;
        this.setToken(token.token);
        this.service.updateUser(token.user);
        this.service.getStatusAuthenticated();
        this.router.navigate(['/']).then();
    }


    onForgot() {
        if (super.isNullOrUndefined(this.authInput.CpfCnpj)) {
            this.showMessage('Atenção', `Preencha o campos CPF ou CNPJ corretamente`);
            return;
        }
        this.forgotPasswordGQL.mutate({cpfCnpj: this.authInput.CpfCnpj}).subscribe(({data}) => {
            this.closeModal('isForgot');
            this.showMessage('Sucesso', 'Enviamos um E-mail para a recuperação de senha', 'success');
        }, error => super.onError(error));
    }
}
