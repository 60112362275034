import {Component, EventEmitter, Input, OnChanges, OnInit, Output, AfterContentInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    CompanyBranch,
    RemoveTechnicalVisitItemImageGQL, StandardizationItem,
    Standarlization, TechnicalVisitGasStationInput,
    TechnicalVisitImage,
    TechnicalVisitItemRemoveInput, UpdateTechnicalVisitGasStationGQL
} from "../../../generated/graphql";
import {UploadService} from "../../service/upload.service";

@Component({
    selector: 'app-standardization',
    templateUrl: './standardization.component.html',
    styleUrls: ['./standardization.component.scss']
})
export class StandardizationComponent extends BaseComponent implements OnInit, OnChanges, AfterContentInit {
    @Input()
    standardizationPage: boolean = false;
    @Input()
    status: string;
    @Input()
    gasStation: boolean;
    @Input()
    standardization: any = new Standarlization();
    @Output() standardizationChange: EventEmitter<Standarlization> = new EventEmitter<Standarlization>();

    @Output() handlerChildren: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveStandardization: EventEmitter<any> = new EventEmitter<any>();


    typeImageId: string;
    technicalVisitId: string;
    file: File = null;
    validateUploadFile: boolean = false;
    gasStationInput: TechnicalVisitGasStationInput = new TechnicalVisitGasStationInput();

    constructor(public router: Router,
                private uploadService: UploadService,
                private removeTechnicalVisitItemImageGQL: RemoveTechnicalVisitItemImageGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngAfterContentInit() {

    }

    generateModel() {
        this.filterModel(this.standardization.resaleFacade);
        this.filterModel(this.standardization.resaleFleet);
        this.standardization.companyBranch.map((b) => {
            this.filterModel(b.resaleFacade);
            this.filterModel(b.resaleFleet);
        });
    }

    filterModel(standardizationItem: any) {
        standardizationItem.typeImage.map((x) => {
            const el = standardizationItem.items.filter((c) => c.typeImage === x.name);
            if (this.isNullOrUndefined(el)) {
                x.idPath = '';
                x.path = '';
                x.status = '';
            } else {
                x.idPath = el[0].id;
                x.path = el[0].path;
                x.status = el[0].status;
            }
        });
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
        if (!this.isNullOrUndefined(this.standardization)) {
            this.generateModel();
            this.validateUploadFile = this.status.toLowerCase() !== "pendente" && this.status.toLowerCase() !== "revisar";
        }
    }

    ngOnInit(): void {
    }

    validateUploadFileItem(status: string) {
        return this.validateUploadFile || (status == 'approved' || status == 'disapproved');
    }

    setCroppedImage(o: any) {
        this.handleUpload(o.file);
    }

    handleUpload(event) {
        super.finishMessageStandardization();
        return;

        const files = event;
        this.file = files[0];
        if (files.length > 0) {
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage('Atenção', 'Selecione uma imagem com as seguintes extensões: JPG, JPEG ou PNG',
                    'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.uploadFile();
        };
        reader.readAsDataURL(event[0]);
        // this.uploadImage();
    }

    handleUploadStandard(typeImage: string, technicalVisitId: string) {
        super.finishMessageStandardization();
        return;

        if (this.validateUploadFile) {
            return;
        }
        this.typeImageId = typeImage;
        this.technicalVisitId = technicalVisitId;
        this.showModal('modalCropStandardization');
    }

    uploadFile() {
        super.finishMessageStandardization();
        return;

        if (!this.isNullOrUndefined(this.file)) {
            this.uploadService.upload(this.file, 'technical-visit-image', this.technicalVisitId, this.typeImageId).subscribe(data => {
                this.handlerChildren.emit();
            }, error => super.onError(error));
        }
    }

    removeFileFleet(x: any) {
        super.finishMessageStandardization();
        return;

        const input: TechnicalVisitItemRemoveInput = {
            technicalVisitItemImageId: x.id,
            nameImage: x.typeImage,
        }
        this.actionRemove(input);
    }

    removeFile(x: any) {
        super.finishMessageStandardization();
        return;

        const input: TechnicalVisitItemRemoveInput = {
            technicalVisitItemImageId: x.idPath,
            nameImage: x.path,
        }
        this.actionRemove(input);
    }

    actionRemove(input: TechnicalVisitItemRemoveInput) {
        super.finishMessageStandardization();
        return;

        super.confirmMessage('Atenção', 'Deseja realmente deletar esse arquivo?', () => {
            this.removeTechnicalVisitItemImageGQL.mutate({input}).subscribe(({data}) => {
                this.handlerChildren.emit();
                this.showMessage('Sucesso', 'Arquivo deletado com sucesso', 'success');
            }, error => super.onError(error));
        });

    }

    handlerGasStation(c: CompanyBranch, gasStation: boolean) {
        super.finishMessageStandardization();
        return;

        if (this.validateUploadFile) {
            return;
        }
        this.gasStationInput = new TechnicalVisitGasStationInput();
        this.gasStationInput.technicalVisitId = c.technicalVisitId;
        this.gasStationInput.gasStation = gasStation;
        this.gasStationInput.isClearStandardizationItem = true;
        super.confirmMessage('Atenção', 'Isso apagará os arquivos dessa revenda, deseja continuar ?', () => {
            this.showModal('modalGasStationInternal');
        });
    }
}
