import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as svg4everybody from 'svg4everybody/dist/svg4everybody';
import {BaseComponent} from './base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit {

  constructor(public router: Router,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit(): void {
    svg4everybody();
    // super.switchLanguage('pt');
    if (localStorage.getItem('culture') === 'pt-BR') {
      this.switchLanguage('pt');
    }
    if (localStorage.getItem('culture') === 'en-US') {
      this.switchLanguage('en');
    }
    if (localStorage.getItem('culture') === 'es-ES') {
      this.switchLanguage('es');
    }
    if (this.isNullOrUndefined(localStorage.getItem('culture'))) {
      super.switchLanguage('pt');
    }
    $(() => {
      $(document).on('show.bs.modal', '.modal', function() {
        $(this).appendTo('body');
        const zIndex = 1040 + 10 * $('.modal:visible').length;
        $(this).css('z-index', zIndex);
        setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack'));
      });
    });
  }

  ngAfterViewInit(): void {
  }
}
