import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    CategoryDownload,
    CategoryDownloadGQL, ClickDownloadGQL, CreateBannerAccessInput, CreateDownloadAccessInput, Download,
    DownloadByCategoryGQL,
    DownloadByCategoryQuery
} from '../../../generated/graphql';
import {QueryRef} from "apollo-angular";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-downloads',
    templateUrl: './downloads.component.html',
    styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent extends BaseComponent implements OnInit {

    section: string;
    categoryDownload: CategoryDownload[] = [];
    downloadByCategoryQuery: QueryRef<DownloadByCategoryQuery>;
    downloads: Download[] = [];
    image_url: string;

    constructor(public router: Router,
                public categoryDownloadGQL: CategoryDownloadGQL,
                public downloadByCategoryGQL: DownloadByCategoryGQL,
                public clickDownloadGQL: ClickDownloadGQL,
                public translate: TranslateService) {
        super(router, translate)
    }

    ngOnInit(): void {
        this.image_url = environment.image_url;

        this.categoryDownloadGQL.watch().valueChanges.subscribe(({data}) => {
            this.categoryDownload = data.categoryDownload as CategoryDownload[];

            this.section = this.categoryDownload[0].id;
            this.downloadByCategoryQuery = this.downloadByCategoryGQL.watch({id: this.section});
            this.downloadByCategoryQuery.valueChanges.subscribe(({data: data2}) => {
                this.downloads = data2.downloadByCategory as Download[];
            })
        }, error => super.onError(error));
    }

    handlerSvg(s: string) {
        switch (s) {
            case 'manuais':
            case 'cartazete':
                return 'photo';
            case 'video':
                return 'video';
            case 'regulamento':
                return 'regulament';
            default:
                return 'download';

        }
    }

    selectCategory(id: string) {
        if (this.section != id) {
            this.section = id;
            this.downloadByCategoryQuery.refetch({id: this.section}).then();
        }
    }

    downloadPath(path: string, id?: string) {
        const d: CreateDownloadAccessInput = {downloadId: id};
        this.clickDownloadGQL.mutate({input: d}).subscribe(({data}) => {
            if (!this.isNullOrUndefined(path)) {
                document.location.href = this.image_url + 'download/archive/' + path;
            }
        }, error => super.onError(error));

    }
}
