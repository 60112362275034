import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {User} from '../../../generated/graphql';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';

declare var $: any;

@Component({
  selector: 'app-prizes',
  templateUrl: './prizes.component.html',
  styleUrls: ['./prizes.component.scss']
})
export class PrizesComponent extends BaseComponent implements OnInit {

  user: User = new User();

  constructor(public router: Router,
              public translate: TranslateService,
              public userService: UserService
  ) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.userService.user.subscribe({
      next: data => {
        if (!this.isNullOrUndefined(data.id)) {
          this.user = data as User;
        }
      }, error: err => super.onError(err)
    });
  }

    scrollToPanel(s: string) {
      $('html, body').stop().animate({
        scrollTop: $(s).offset().top - 30
      }, 'slow');
    }
}
