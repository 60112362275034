import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../../generated/graphql';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {
    isAuthenticated = false;

    private tokenDataSource = new BehaviorSubject<string>(null);
    token = this.tokenDataSource.asObservable();

    private userDataSource = new BehaviorSubject<User>(new User());
    user = this.userDataSource.asObservable();

    constructor(http: HttpClient) {
        super(http);
    }

    updateToken(token: string) {
        localStorage.setItem('token', token);
    }

    getStatusAuthenticated() {
        const token = localStorage.getItem('token') as string;
        if (token != null) {
            this.isAuthenticated = true;
        } else {
            this.isAuthenticated = false;
        }
        return this.isAuthenticated;
    }

    search(page, filter) {
        const url = `${this.BASE_URL}/user/search/${page}`;
        return this.http.post(url, filter);
    }

    getMe() {
        const url = `${this.BASE_URL}/user/me`;
        return this.http.get(url).subscribe({
            next: data => {
                this.userDataSource.next(data as User);
                this.getUserLocal();
            }
        });
    }

    updateUser(user: User, tokenChanged: boolean = false, t: string = null) {
        if (tokenChanged) {
            this.updateToken(t);
            this.tokenDataSource.next(t);
        }
        this.userDataSource.next(user);
    }

    getUserLocal(): User {
        return this.userDataSource.value;
    }

}
