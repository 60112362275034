<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">RANKING TIME PREPARADO</p>
        <div class="container">
            <h2 class="page_header_title">RANKING TIME PREPARADO</h2>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="false">
    <div class="wait-panel">
        <h3>Em Breve</h3>
    </div>
</div>

<ng-container *ngIf="true">

    <div class="container">
        <div class="row mt-3">
            <div class="col-2">
                <div [routerLink]="'/time-preparado'" class="btn-back btn btn-outline-secondary d-flex">
                    <svg-icon src="assets/svg/back.svg"></svg-icon>
                    Voltar
                </div>
            </div>
            <div class="offset-md-2 col-md">
                <div class="input-material select mb-3">
                    <select [disabled]="!handlerMasterBool" class="form-control"
                            [(ngModel)]="this.rankingResaleInput.region"
                            id="region" name="region">
                        <option *ngFor="let x of regionFindAll" [value]="x.name">{{x.name}}</option>
                    </select>
                    <label class="disabled" for="region">Regiao</label>
                </div>
            </div>
            <div class="col-md">
                <div class="input-material select mb-3">
                    <select [disabled]="!handlerCommercialBool" class="form-control" id="range"
                            name="range"
                            [(ngModel)]="this.rankingResaleInput.rangeId">
                        <option *ngFor="let x of findAllRange" [value]="x.id">{{x.name}}</option>
                    </select>
                    <label class="disabled" for="range">Faixa</label>
                </div>
            </div>
            <div class="col-md-2" *ngIf="this.handlerCommercialBool">
                <a (click)="onSearchPage(1)" class="btn btn-block btn-secondary">Buscar</a>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-9">
                <div class="position-relative my-4">
                    <div class="row h-100 mx-n1 justify-content-between align-content-center" style="row-gap: 30px">
                        <ng-container *ngFor="let x of listOfMonths;let i = index">
                            <div class="px-1 position-relative" (click)="handlerTraining(x)">
                                <div class="home-training_btn" [ngClass]="{'active':x.id == rankingResaleInput.month}">
                                    <p class="home-training_lbl">{{x.name}}</p>
                                    <p class="home-training_lbl home-training_lbl-sm">{{x.date}}</p>
                                </div>
                                <svg-icon class="home-training_arrow home-training_arrow-ranking"
                                          [ngClass]="{'active':x.id == rankingResaleInput.month}"
                                          src="assets/svg/arrow-training.svg"></svg-icon>
                            </div>
                            <div class="px-1 position-relative" (click)="handlerTraining(rankingTotalizer[0])" *ngIf="i === 1">
                                <div class="home-training_btn" [ngClass]="{'active': 99 == rankingResaleInput.month}">
                                    <p class="home-training_lbl">Período</p>
                                    <p class="home-training_lbl home-training_lbl-sm">OUT à NOV/2022</p>
                                </div>
                                <svg-icon class="home-training_arrow home-training_arrow-ranking"
                                          [ngClass]="{'active': 99 == rankingResaleInput.month}"
                                          src="assets/svg/arrow-training.svg"></svg-icon>
                            </div>
                            <a class="px-1 position-relative" (click)="handlerTraining(rankingTotalizer[1])" *ngIf="i === 4">
                                <div class="home-training_btn" [ngClass]="{'active': 98 === rankingResaleInput.month}">
                                    <p class="home-training_lbl">Período</p>
                                    <p class="home-training_lbl home-training_lbl-sm">DEZ/2022 à FEV/2023</p>
                                </div>
                                <svg-icon class="home-training_arrow home-training_arrow-ranking"
                                          [ngClass]="{'active': 98 == rankingResaleInput.month}"
                                          src="assets/svg/arrow-training-gray.svg"></svg-icon>
                            </a>
                            <a class="px-1 position-relative" (click)="handlerTraining(rankingTotalizer[2])" *ngIf="i === 4">
                                <div class="home-training_btn" [ngClass]="{'active': 97 === rankingResaleInput.month}">
                                    <p class="home-training_lbl">Final</p>
                                    <p class="home-training_lbl home-training_lbl-sm">OUT/2022 à FEV/2023</p>
                                </div>
                                <svg-icon class="home-training_arrow home-training_arrow-ranking"
                                          [ngClass]="{'active': 97 == rankingResaleInput.month}"
                                          src="assets/svg/arrow-training-gray.svg"></svg-icon>
                            </a>
                        </ng-container>
                    </div>
                    <div class="home-training_bar home-training_bar-ranking">&nbsp;</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="dark-card">
                    <span>ACUMULADO</span>
                    <b>{{rankingResalePage.accumulatorScore}}</b>
                </div>
            </div>
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th class="text-primary" scope="col">Posição</th>
                            <th class="text-primary" scope="col">GMR</th>
                            <th class="text-primary text-center" scope="col">Faixa</th>
                            <th class="text-primary" scope="col">Revenda</th>
                            <th class="text-primary text-center" scope="col">Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="isNullOrUndefined(this.rankingResalePage.resales)">
                            <td class="text-center" colspan="8">Em Breve</td>
                        </tr>
                        <tr *ngFor="let x of this.rankingResalePage.resales|
                        paginate:{id:'rankingDetail',itemsPerPage: rankingResalePage.PageSize,
                        currentPage: rankingResalePage.currentPage, totalItems: rankingResalePage.totalCount }">
                            <td>{{x.position}}º</td>
                            <td>{{x.gmr}}</td>
                            <td class="text-center">{{x.rangeId}}</td>
                            <td>{{x.companyName}}</td>
                            <td class="text-center">{{x.total}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-right">
                    <app-pagination-custom
                            [id]="'rankingDetail'"
                            (pageChange)="onSearchPage($event)"
                    ></app-pagination-custom>
                </div>
            </div>
        </div>
    </div>

</ng-container>
