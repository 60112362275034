<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg mb-0">Perguntas frequentes</p>
        <div class="container">
            <h2 class="page_header_title">Perguntas <span>frequentes</span></h2>
        </div>
    </div>
</div>

<div class="container">
    <div class="row justify-content-center">
        <div class="col-10">
            <div class="search_bar">
                <form autocomplete="off" class="row justify-content-center">
                    <div class="col-8">
                        <div class="input-material mb-0">
                            <input [(ngModel)]="filter" type="text" class="form-control" maxlength="100" id="new_search"
                                   name="new_search" autocomplete="off" required/>
                            <label for="new_search">Pesquisar</label>
                            <svg-icon class="right" src="assets/svg/search.svg"></svg-icon>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row row-faq">
        <div class="col-12">
            <!--<div class="mb-3 mbg" *ngFor="let o of getFaqFiltered() ">
                <a class="faq-question" (click)="handlerCollapse('request-' + o.id)" href="javascript:void(0)">
                    <span>{{o.question}}</span>
                    <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                </a>
                <div id="request-{{o.id}}" class="collapse">
                    <p class="faq-anwser">{{o.anwser}}</p>
                </div>
            </div>-->
            <h3>CAMPANHA</h3>
            <div class="faq-group">
                <p class="pb-2 empty d-none">Nenhum resultado encontrado</p>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-1')" href="javascript:void(0)">
                        <span>1.	Onde encontro informações detalhadas sobre a campanha?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-1" class="collapse">
                        <p class="faq-anwser">No regulamento. Acesse a aba REGULAMENTO no rodapé do site, lá você encontrará todas as informações da campanha e tem a possibilidade de fazer o download do arquivo para guardar e consultar a hora que quiser.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-2')" href="javascript:void(0)">
                        <span>2.	Qual o período de participação na campanha   SistechPro?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-2" class="collapse">
                        <p class="faq-anwser">O período de participação da campanha inicia no dia 01/10/2022 e se encerra no dia 28/02/2022.</p>
                    </div>
                </div>
            </div>

            <h3>SITE</h3>
            <div class="faq-group">
                <p class="pb-2 empty d-none">Nenhum resultado encontrado</p>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-3')" href="javascript:void(0)">
                        <span>3.	Se a equipe cadastrar os dados pessoais de forma errada, como realizar a correção?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-3" class="collapse">
                        <p class="faq-anwser">Basta acessar o campo “MEU PERFIL” localizado na parte superior da tela do site e realizar a correção. Caso o dado errado seja o CPF do funcionário, somente o revendedor conseguirá corrigir através do seu perfil de revenda em “CADASTRE SUA EQUIPE”.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-4')" href="javascript:void(0)">
                        <span>4.	Onde encontro os arquivos para me auxiliar na campanha, como manuais e vídeos da campanha?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-4" class="collapse">
                        <p class="faq-anwser">Todos os arquivos de manuais e vídeos da campanha estão disponíveis em “Download” no rodapé do site, lá você encontrará todas as informações da campanha e tem a possibilidade de fazer o download do arquivo para guardar e consultar a hora que quiser.</p>
                    </div>
                </div>
            </div>

            <h3>CADASTRO</h3>
            <div class="faq-group">
                <p class="pb-2 empty d-none">Nenhum resultado encontrado</p>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-5')" href="javascript:void(0)">
                        <span>5.	Como realizo o cadastro da equipe de revenda?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-5" class="collapse">
                        <p class="faq-anwser">O cadastro deve ser feito no site www.desafioliquigas.com.br na página “CADASTRE SUA EQUIPE”. É necessário validar todos os funcionários após finalizar o cadastro. Os funcionários que não estiverem cadastrados e validados no programa não conseguirão acessar o site da campanha e não terão direito às premiações.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-6')" href="javascript:void(0)">
                        <span>6.	Como a equipe da revenda acessa o site da campanha?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-6" class="collapse">
                        <p class="faq-anwser">Após o revendedor cadastrar e validar a equipe, o funcionário receberá um e-mail informando que foi validado. Em seguida, basta acessar o site www.desafioliquigas.com.br e efetuar o login com seu CPF e senha (os 4 primeiros dígitos do CPF), confirmar ou corrigir os dados pessoais  aceitar os termos do regulamento e política de privacidade. Feito isso, o cadastro estará concluído e pronto para participar da campanha.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-7')" href="javascript:void(0)">
                        <span>7.	Não consigo realizar o cadastro da minha equipe, o que fazer?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-7" class="collapse">
                        <p class="faq-anwser">Deverá ser comunicado formalmente através do e-mail sac@sistechpro.com.br para que o time responsável analise o ocorrido.</p>
                    </div>
                </div>
            </div>

            <h3>MECÂNICA RANKING POR DESEMPENHO</h3>
            <div class="faq-group">
                <p class="pb-2 empty d-none">Nenhum resultado encontrado</p>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-8')" href="javascript:void(0)">
                        <span>8.	Quais são os critérios do ranking por desempenho?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-8" class="collapse">
                        <!--<p class="faq-anwser"></p>-->
                        <p>Os Revendedores participantes  serão avaliados e, conforme o caso, recompensados pela atuação em dois  diferentes grupos de métrica e pontuação: <br>
                            Desempenho = ΣΔV + ΣAd + VT +  ME <br>
                            Eles correspondem  respectivamente à <strong>Variação de Vendas + Adimplência + Visita Técnica +  Engajamento. </strong></p>
                        <p>Onde: <br>
                            <strong>ΔV | </strong>Variação de vendas: 0 a 110 pontos (mensal) = 550 pts  (máx) <br>
                            <strong>Ad | </strong>Adimplência: 0 ou 75 pontos (mensal) = 375 pts (máx) <br>
                            <strong>VT | </strong>Visita técnica: 0 a 400 pontos (programa) sendo: </p>
                        <ol>
                            <li>A) Gestão Informatizada: 0 ou 50 pontos = 50 pts (máx) </li>
                            <li>B) Treinamento: 0 ou 50 pontos = 50 pts (máx) </li>
                            <li>C) Segurança nas instalações: 0 ou 50 pontos = 50 pts  (máx) </li>
                            <li>D) Padronização da Fachada: 0 ou 200 pontos – 200 pts  (máx) </li>
                            <li>E) Padronização da Frota: 0 ou 50 pontos –50 pts (máx) </li>
                            <li>&nbsp;</li>
                        </ol>
                        <p><strong>ME | </strong>Mecânica de Engajamento: 0 a 35 pontos (mensal)  = 175 pts (máx)</p>

                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-9')" href="javascript:void(0)">
                        <span>9.	Em caso de empate no ranking por desempenho, qual será o critério de desempate?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-9" class="collapse">
                        <p class="faq-anwser">Em caso de empate entre os Revendedores, mensalmente a SistechProadotará como critério de desempate a maior pontuação referente ao item Variação de Vendas (ΣΔV), sem o limite de 110% (cento e dez por cento). Persistindo o empate, será adotado como critério de desempate quem obtiver a maior nota em Visita Técnica (VT) e, se ainda assim persistir, será considerado como critério de desempate quem obtiver a maior nota em Adimplência (Ad). </p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-10')" href="javascript:void(0)">
                        <span>10.	Como e quando será divulgado o ranking por desempenho?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-10" class="collapse">
                        <p class="faq-anwser">O ranking será divulgado e atualizado mensalmente no site da campanha, na página “RANKING”, após a apuração dos resultados.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-11')" href="javascript:void(0)">
                        <span>11.	Qual será o prêmio da revenda e quando será entregue?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-11" class="collapse">
                        <div class="table-responsive">
                            <table border="1" cellspacing="0" cellpadding="0">
                            <tr>
                                <td colspan="7"><p align="center"><strong>CARGA DE GÁS    (CRÉDITO EM CARTEIRA)</strong></p></td>
                            </tr>
                            <tr>
                                <td><p align="center"><strong>PREMIAÇÃO</strong></p></td>
                                <td><p align="center"><strong>FAIXA 1</strong></p></td>
                                <td><p align="center"><strong>FAIXA 2</strong></p></td>
                                <td><p align="center"><strong>FAIXA 3</strong></p></td>
                                <td><p align="center"><strong>FAIXA 4</strong></p></td>
                                <td><p align="center"><strong>FAIXA 5</strong></p></td>
                                <td><p align="center"><strong>Regiões</strong></p></td>
                            </tr>
                            <tr>
                                <td><p align="center">1º lugar</p></td>
                                <td><p align="center">R$ 8.000,00</p></td>
                                <td><p align="center">R$ 12.000,00</p></td>
                                <td><p align="center">R$ 18.000,00</p></td>
                                <td><p align="center">R$ 22.500,00</p></td>
                                <td><p align="center">R$ 28.000,00</p></td>
                                <td><p align="center">4</p></td>
                            </tr>
                            <tr>
                                <td><p align="center">2º lugar</p></td>
                                <td><p align="center">R$ 7.000,00</p></td>
                                <td><p align="center">R$ 10.000,00</p></td>
                                <td><p align="center">R$ 15.500,00</p></td>
                                <td><p align="center">R$ 20.000,00</p></td>
                                <td><p align="center">R$ 23.500,00</p></td>
                                <td><p align="center">4</p></td>
                            </tr>
                            <tr>
                                <td><p align="center">3º lugar</p></td>
                                <td><p align="center">R$ 6.000,00</p></td>
                                <td><p align="center">R$ 8.000,00</p></td>
                                <td><p align="center">R$ 13.500,00</p></td>
                                <td><p align="center">R$  18.000,00</p></td>
                                <td><p align="center">R$ 21.500,00</p></td>
                                <td><p align="center">4</p></td>
                            </tr>
                            <tr>
                                <td><p align="center">4º ao 6º lugar</p></td>
                                <td><p align="center">R$ 4.000,00</p></td>
                                <td><p align="center">R$ 6.000,00</p></td>
                                <td><p align="center">R$ 11.500,00</p></td>
                                <td><p align="center">R$  16.000,00</p></td>
                                <td><p align="center">R$ 19.500,00</p></td>
                                <td><p align="center">4</p></td>
                            </tr>
                            <tr>
                                <td><p align="center">7º ao 10º  lugar</p></td>
                                <td><p align="center">R$ 2.000,00</p></td>
                                <td><p align="center">R$ 4.000,00</p></td>
                                <td><p align="center">R$ 9.000,00</p></td>
                                <td><p align="center">R$  12.500,00</p></td>
                                <td><p align="center">R$ 16.000,00</p></td>
                                <td><p align="center">4</p></td>
                            </tr>
                        </table>
                        </div>
                        <p>O valor do crédito será calculado  proporcionalmente ao período de contribuição do Revendedor ao Fundo de Marketing. A proporcionalidade é  calculada com base nos 12 meses anterior ao início do Programa.</p>
                        <p>Além disso, os 1º colocados de  cada faixa por região também ganharão um KIT Desafio composto por:</p>
                        <div class="table-responsive">
                            <table border="1" cellspacing="0" cellpadding="0">
                            <tr>
                                <td><p align="center"><strong>KIT    DESAFIO</strong></p></td>
                                <td><p align="center"><strong>FAIXA    1</strong></p></td>
                                <td><p align="center"><strong>FAIXA    2</strong></p></td>
                                <td><p align="center"><strong>FAIXA    3</strong></p></td>
                                <td><p align="center"><strong>FAIXA    4</strong></p></td>
                                <td><p align="center"><strong>FAIXA    5</strong></p></td>
                                <td><p align="center"><strong>QTD    PRÊMIOS</strong></p></td>
                            </tr>
                            <tr>
                                <td><p align="center">IMÃS </p></td>
                                <td><p align="center">300 </p></td>
                                <td><p align="center">1.000 </p></td>
                                <td><p align="center">2.000 </p></td>
                                <td><p align="center">6.000 </p></td>
                                <td><p align="center">12.000 </p></td>
                                <td><p align="center">85.200 </p></td>
                            </tr>
                            <tr>
                                <td><p align="center">PANOS DE PRATO </p></td>
                                <td><p align="center">100 </p></td>
                                <td><p align="center">400 </p></td>
                                <td><p align="center">800 </p></td>
                                <td><p align="center">1.200 </p></td>
                                <td><p align="center">2.500 </p></td>
                                <td><p align="center">20.000 </p></td>
                            </tr>
                            <tr>
                                <td><p align="center">AVENTAL </p></td>
                                <td><p align="center">50 </p></td>
                                <td><p align="center">100 </p></td>
                                <td><p align="center">150 </p></td>
                                <td><p align="center">300 </p></td>
                                <td><p align="center">600 </p></td>
                                <td><p align="center">4.800 </p></td>
                            </tr>
                            <tr>
                                <td><p align="center">SACOLA </p></td>
                                <td><p align="center">50 </p></td>
                                <td><p align="center">100 </p></td>
                                <td><p align="center">150 </p></td>
                                <td><p align="center">300 </p></td>
                                <td><p align="center">600 </p></td>
                                <td><p align="center">4.800 </p></td>
                            </tr>
                        </table>
                        </div>
                        <p>A entrega das premiações será realizada em até 180 dias após  a divulgação do resultado do ranking final da campanha.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-12')" href="javascript:void(0)">
                        <span>12.	Qual será o prêmio da minha equipe e quando será entregue?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-12" class="collapse">
                        <p class="faq-anwser">Os funcionários das 200 revendas premiadas no ranking final  ganharão R$ 300,00 cada, desde que cadastrados e validados na campanha até 15/02/2023. o pagamento da premiação para a Equipe da Revenda será feito apenas via crédito . O prazo para recebimento da premiação é até 60 (sessenta) dias corridos após divulgação dos resultados de cada mecânica, desde que o ganhador da Equipe tenha feito o cadastro no  e esteja apto ao recebimento.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-13')" href="javascript:void(0)">
                        <span>13.	Não concordo com a minha faixa de revenda ou pontuação, o que devo fazer?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-13" class="collapse">
                        <p class="faq-anwser">Caso o revendedor discorde da classificação de sua faixa, ele poderá encaminhar um e-mail até 14/10/2022 para sac@sistechpro.com.br, com as justificativas técnicas para a sua reclassificação, que serão analisadas pela SistechProaté o dia 28/02/2022. Não haverá recurso sobre a decisão final da SistechPro.</p>
                        <p class="faq-anwser">Se o revendedor não concordar com sua pontuação, deverá encaminhar um e-mail para sac@sistechpro.com.br com as devidas justificativas para análise.</p>
                    </div>
                </div>
            </div>

            <h3>MECÂNICA EXTRA – TIME PREPARADO</h3>
            <div class="faq-group">
                <p class="pb-2 empty d-none">Nenhum resultado encontrado</p>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-14')" href="javascript:void(0)">
                        <span>14.	Quais são os critérios de avaliação da mecânica extra – Time Preparado?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-14" class="collapse">
                        <p class="faq-anwser">No período de outubro/2022 a fevereiro/2022, serão considerados para avaliação dos membros da Equipe cadastrados no Programa os itens abaixo descritos, de acordo com o mês de realização:</p>
                        <div class="table-responsive">
                            <table border="1" cellspacing="0" cellpadding="0">
                            <tr>
                                <td rowspan="2"><p align="center">&nbsp;</p>
                                    <p align="center"><strong>ITEM</strong><strong> </strong></p></td>
                                <td colspan="5"><p align="center"><strong>PONTUAÇÃO MÁXIMA</strong><strong> </strong></p></td>
                            </tr>
                            <tr>
                                <td><p align="center"><strong>PONTOS</strong>
                                    <strong>Outubro/2022</strong><strong> </strong></p></td>
                                <td><p align="center"><strong>PONTOS</strong>
                                    <strong>Novembro/2022</strong><strong> </strong></p></td>
                                <td><p align="center"><strong>PONTOS</strong>
                                    <strong>Dezembro/2022</strong><strong> </strong></p></td>
                                <td><p align="center"><strong>PONTOS</strong>
                                    <strong>Janeiro/2023</strong><strong> </strong></p></td>
                                <td><p align="center"><strong>PONTOS</strong>
                                    <strong>Fevereiro/2023</strong><strong> </strong></p></td>
                            </tr>
                            <tr>
                                <td><p align="center">ASSISTIR AO VÍDEO<br>
                                    (INDIVIDUAL)</p></td>
                                <td><p align="center">10</p></td>
                                <td><p align="center">10</p></td>
                                <td><p align="center">10</p></td>
                                <td><p align="center">10</p></td>
                                <td><p align="center">10</p></td>
                            </tr>
                            <tr>
                                <td><p align="center">ACERTAR O QUIZ<br>
                                    (INDIVIDUAL)</p></td>
                                <td><p align="center">10</p></td>
                                <td><p align="center">10</p></td>
                                <td><p align="center">10</p></td>
                                <td><p align="center">10</p></td>
                                <td><p align="center">10</p></td>
                            </tr>
                            <tr>
                                <td><p align="center">ASSISTIR AO VÍDEO E RESPONDER AO QUIZ<br>
                                    (GRUPO – toda Equipe cadastrada da<br>
                                    Revenda)*</p></td>
                                <td><p align="center">15</p></td>
                                <td><p align="center">15</p></td>
                                <td><p align="center">15</p></td>
                                <td><p align="center">15</p></td>
                                <td><p align="center">15</p></td>
                            </tr>
                            <tr>
                                <td><p align="center">SUBTOTAL**</p></td>
                                <td><p align="center">35</p></td>
                                <td><p align="center">35</p></td>
                                <td><p align="center">35</p></td>
                                <td><p align="center">35</p></td>
                                <td><p align="center">35</p></td>
                            </tr>
                        </table>
                        </div>
                        <p>* caso  todos os funcionários da revenda assistam e respondam o quiz (mesmo que não  acertem).<br>
                            ** Cada funcionário cadastrado fará o máximo de 35 pontos ao mês.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-15')" href="javascript:void(0)">
                        <span>15.	Em caso de empate no ranking da mecânica extra – Time Preparado, qual será o critério de desempate?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-15" class="collapse">
                        <p>Em caso de empate na mecânica extra -  Time Preparado, serão adotados os seguintes  critérios de desempate:</p>
                        <p>  1º critério:  maior acerto individual no quiz 2º critério:  maior acerto do quiz em grupo<br>
                            3º critério: a maior Variação de Vendas (ΣΔV)), sem o  limite de 110% (cento e dez por cento).</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-16')" href="javascript:void(0)">
                        <span>16.	Como e quando será divulgado o da mecânica extra – Time Preparado? </span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-16" class="collapse">
                        <p class="faq-anwser">Os ganhadores da mecânica extra – Time Preparado serão divulgados de acordo com cada período, no site da campanha, após a apuração dos resultados. </p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-17')" href="javascript:void(0)">
                        <span>17.	Qual será o prêmio da EQUIPE DE REVENDA na mecânica extra – Time Preparado e quando será entregue?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-17" class="collapse">
                        <p>Serão  premiadas dentro de cada uma das 4 regiões as 5 Revendas que possuírem o maior percentual de participação, sendo  uma por Faixa, totalizando 20 Revendas, que terão seu time contemplado. Cada  um dos funcionários da Revenda cadastrado no Programa   receberá a seguinte premiação, de acordo com o mês de contemplação:</p>
                        <p><strong>Crédito na  carteira digital </strong></p>
                        <p><strong>OUTUBRO/2022  A NOVEMBRO/2022:</strong> Crédito de R$ 200,00 na carteira digital do  , por   funcionário cadastrado e validado.</p>
                        <p><strong>DEZEMBRO/2022  A FEVEREIRO/2023:</strong> Crédito de R$ 300,00 na carteira digital do  , por   funcionário cadastrado e validado.</p>
                        <p>Para  receber o crédito a equipe precisa baixar o app  e realizar o cadastro com o CPF informado no site do  Programa.</p>
                        <p>O prazo para recebimento da premiação é até 60  (sessenta) dias corridos após divulgação dos resultados de cada mecânica,  desde que o ganhador da Equipe tenha feito o cadastro no  e esteja apto  ao recebimento.</p>

                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-18')" href="javascript:void(0)">
                        <span>18.	Qual será o prêmio da REVENDA na mecânica extra – Time Preparado e quando será entregue?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-18" class="collapse">
                        <p>Serão  premiadas dentro de cada uma das 4 regiões as 5 Revendas que possuírem o maior percentual de participação, sendo  uma por Faixa, totalizando 20 Revendas. A premiação -para cada revenda consiste  em:<strong></strong></p>
                        <p><strong>- Crédito  de R$500,00 na carteira digital :</strong> Para receber o crédito o Revendedor  precisa baixar o app  e  realizar o cadastro com o CPF informado no site do Programa. </p>
                        <p><strong>- KIT uniforme: </strong>composto por boné, camiseta e calça  modelo entregador, todos com nova marca. A quantidade de Kits de uniformes será entregue conforme a Faixa da Revenda, de acordo  com tabela abaixo:</p>
                        <div class="table-responsive">
                            <table border="1" cellspacing="0" cellpadding="0">
                            <tr>
                                <td colspan="2" valign="top"><p align="center"><strong>KITS UNIFORMES</strong></p></td>
                            </tr>
                            <tr>
                                <td valign="top"><p align="center">FAIXA 1</p></td>
                                <td valign="top"><p align="center">3 KITS</p></td>
                            </tr>
                            <tr>
                                <td valign="top"><p align="center">FAIXA 2</p></td>
                                <td valign="top"><p align="center">6 KITS</p></td>
                            </tr>
                            <tr>
                                <td valign="top"><p align="center">FAIXA 3</p></td>
                                <td valign="top"><p align="center">9 KITS</p></td>
                            </tr>
                            <tr>
                                <td valign="top"><p align="center">FAIXA 4</p></td>
                                <td valign="top"><p align="center">12 KITS</p></td>
                            </tr>
                            <tr>
                                <td valign="top"><p align="center">FAIXA 5</p></td>
                                <td valign="top"><p align="center">24 KITS</p></td>
                            </tr>
                        </table>
                        </div>
                    </div>
                </div>
            </div>

            <h3>VISITA TÉCNICA</h3>
            <div class="faq-group">
                <p class="pb-2 empty d-none">Nenhum resultado encontrado</p>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-19')" href="javascript:void(0)">
                        <span>19.	Quem será responsável por inserir as fotos na Visita Técnica no site?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-19" class="collapse">
                        <p class="faq-anwser">O Revendedor e o Consultor Comercial poderão fazer o upload de fotos de cada critério.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-20')" href="javascript:void(0)">
                        <span>20.	Como e o que deve ser comprovado na Visita Técnica?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-20" class="collapse">
                        <p>Para  atendimento na Visita Técnica, a Revenda ou o comercial responsável pela Revenda deverá acessar o site <a href="#" target="_blank">#</a><u> </u>, acessar a página &ldquo;Visita  Técnica&rdquo; e informar se a revenda possui ou não fachada (ex: posto de  combustível ou supermercado). Feito isso, deverá seguir a orientação para envio das fotos/arquivos  de imagens que comprovem  o atendimento a cada um dos itens avaliados abaixo:</p>
                        <p><strong>Gestão Informatizada:</strong> A Revenda pontuará se comprovar através  de fotos ou print que possui controles informatizados da Lista de Clientes ou  do Fluxo de Caixa através de algum sistema ou planilha. Não será aceito  controles manuais e é necessário que no arquivo conste alguma informação de  identificação da Revenda, como a razão social e/ou CNPJ.</p>
                        <p><strong>Treinamento:</strong> A revenda  pontuará se possuir certificado  de treinamento/ cursos com validade de até 1 ano da data de inserção no site. <br>
                            <strong>Segurança nas instalações:</strong> A revenda  pontuará caso comprove a delimitação  da área de armazenamento de botijões por meio de pintura no piso ou cerca/  gradil metálico. Se houver botijões fora da área de armazenamento, não pontua. </p>
                        <p><strong>Padronização da Fachada:</strong> A Revenda pontuará caso comprove através de fotos padronização da fachada da Revenda segundo o manual de  identidade visual da marca SistechPro. As fotos devem considerar pintura de  muros, portões, gradil e placa de fachada (testeira). Todos os itens não podem  conter a antiga marca da SistechPro, seja com endosso BR Petrobras, seja com a  grafia antiga.</p>
                        <p><strong>Padronização da Frota: </strong>A Revenda pontuará caso comprove através de fotos padronização de toda a frota de veículos da Revenda segundo  o manual de identidade visual da marca SistechPro. Importante que nas imagens também  apareçam as placas dos veículos, para que fique evidente a padronização de toda  a frota, caso a Revenda possua mais de um veículo de aparência similar.</p>
                        <p>Apenas  para Padronização: Caso a Revenda matriz (PAI) tenha filial(ais) atreladas ao  grupo que compõe o Fundo de Marketing, deverá ser considerada a comprovação de  cada Revenda individualmente.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-21')" href="javascript:void(0)">
                        <span>21.	Quantos pontos recebo pela comprovação de cada item da Visita Técnica?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-21" class="collapse">
                        <p>Na Visita  Técnica os pontos serão distribuídos da seguinte forma:</p>
                        <p>Gestão Informatizada: 0 ou 50 pontos - sendo  50 pontos o máximo de  atingimento.  </p>
                        <p>  Treinamento:  0 ou 50 pontos = sendo  50 pontos o máximo de  atingimento</p>
                        <p>  Segurança nas instalações: 0 ou 50 pontos = sendo  50 pontos o máximo de  atingimento</p>
                        <p>  Padronização da Fachada: 0 ou 200 pontos = sendo  200 pontos o máximo de atingimento</p>
                        <p>  Padronização da Frota: 0 ou 50 pontos - sendo 50  pontos o máximo de atingimento</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-22')" href="javascript:void(0)">
                        <span>22.	Quando os pontos da Visita Técnica serão somados no ranking por desempenho?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-22" class="collapse">
                        <p class="faq-anwser">A pontuação relativa à visita técnica somente será contabilizada no ranking final.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-23')" href="javascript:void(0)">
                        <span>23.	Não consigo inserir as fotos no sistema de Visita Técnica, o que devo fazer?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-23" class="collapse">
                        <p class="faq-anwser">Verifique se a extensão do arquivo são uma dessas: "*.jpg" ou png". Certifique-se de que o tamanho da imagem não ultrapasse o limite de 60MB cada. Caso o problema persista, entre em contato com sac@sistechpro.com.br.</p>
                    </div>
                </div>
            </div>

            <h3>PADRONIZAÇÃO</h3>
            <div class="faq-group">
                <p class="pb-2 empty d-none">Nenhum resultado encontrado</p>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-24')" href="javascript:void(0)">
                        <span>24.	Como devo padronizar minha revenda e frota?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-24" class="collapse">
                        <p class="faq-anwser">Os manuais de padronização estão disponíveis em “Download” no rodapé do site, lá você encontrará todas as informações da campanha e tem a possibilidade de fazer o download do arquivo para guardar e consultar a hora que quiser.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-25')" href="javascript:void(0)">
                        <span>25.	Como ganho cupons para concorrer  na mecânica extra - Padronização?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-25" class="collapse">
                        <p>As Revendas que comprovarem a padronização da fachada ganham 1  cupom. As revendas que comprovarem a padronização da fachada + frota (desde que  com nova padronização e sem itens da marca antiga) ganharão um cupom adicional.  Outro acelerador que dará um cupom extra para as revendas é o prazo de  inclusão: aquelas que incluírem as fotos até 11/12/2022 receberão um cupom  extra. </p>
                        <p>Todas as fotos estão sujeitas a avaliação de um auditor responsável que  determinará a aprovação ou reprovação considerando os manuais de padronização  da marca, sendo que os cupons só serão gerados em caso de aprovação das fotos  enviadas.</p>
                        <p><strong>Atenção: Só concorrem ao sorteio de  padronização as revendas que possuem fachadas. Revendas sem fachada (exemplo de  postos de combustíveis e supermercados) não concorrem ao sorteio.</strong></p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-26')" href="javascript:void(0)">
                        <span>26.	Como devo comprovar na “Mecânica Extra – Padronização” a padronização da minha revenda e frota?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-26" class="collapse">
                        <p>Para  comprovar a padronização da revenda e frota, basta acessar a área de &ldquo;Visita  Técnica&rdquo;, informar se é uma revenda com ou sem fachada. Caso a revenda não  possua fachada, (em caso de postos e supermercados por exemplo), não concorrerá  ao prêmio da selecionar a revenda na qual quer comprovar a padronização. Feito  isso, basta inserir as fotos da revenda e frota:</p>
                        <p><strong>Revendas com fachada:</strong> fotos que comprovem a correta padronização da fachada da Revenda  segundo o manual de identidade visual da marca SistechPro. As fotos devem considerar  pintura de muros, portões, gradil e placa de fachada (testeira). Todos os itens  não podem conter a antiga marca da SistechPro, seja com endosso BR Petrobras,  seja com a grafia antiga.</p>
                        <p><strong>Frota das revendas:</strong> Inserir até 20 Imagens que comprovem a correta  padronização da frota da Revenda segundo o manual de identidade visual da marca  SistechPro, podendo ser pick-up, moto ou caminhão na parte frontal e lateral.  Importante que nas imagens também apareçam as placas dos veículos, para que  fique evidente a padronização de toda a frota, caso a Revenda possua mais de um  veículo de aparência similar. Todos os itens não podem conter a  antiga marca da SistechPro, seja com endosso BR Petrobras, seja com a grafia  antiga.</p>
                        <p>Caso a  Revenda matriz (PAI) tenha filial(ais) atreladas ao grupo que compõe o Fundo de  Marketing, deverá ser considerada a comprovação de cada Revenda  individualmente.</p>
                    </div>
                </div>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-27')" href="javascript:void(0)">
                        <span>27.	Quais são os prêmios que vou concorrer na” Mecânica Extra – Padronização” ao comprovar a padronização da minha revenda e frota?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-27" class="collapse">
                        <p>Todas as  Revendas que possuem fachada e que comprovarem a padronização poderão concorrer  ao sorteio, independentemente de colocação. O valor da premiação será definido  de acordo com a divisão por Faixa conforme volume de compras de GLP, de acordo  com a tabela abaixo:</p>
                        <div class="table-responsive">
                            <table border="1" cellspacing="0" cellpadding="0">
                            <tr>
                                <td valign="top"><p align="center"><strong>FAIXA</strong></p></td>
                                <td valign="top"><p align="center"><strong>PRÊMIO</strong></p></td>
                            </tr>
                            <tr>
                                <td valign="top"><p align="center">FAIXA 1</p></td>
                                <td valign="top"><p align="center">R$ 2.000,00</p></td>
                            </tr>
                            <tr>
                                <td valign="top"><p align="center">FAIXA 2</p></td>
                                <td valign="top"><p align="center">R$ 4.000,00</p></td>
                            </tr>
                            <tr>
                                <td valign="top"><p align="center">FAIXA 3</p></td>
                                <td valign="top"><p align="center">R$ 6.000,00</p></td>
                            </tr>
                            <tr>
                                <td valign="top"><p align="center">FAIXA 4</p></td>
                                <td valign="top"><p align="center">R$ 8.000,00</p></td>
                            </tr>
                            <tr>
                                <td valign="top"><p align="center">FAIXA 5</p></td>
                                <td valign="top"><p align="center">R$ 10.000,00</p></td>
                            </tr>
                        </table>
                        </div>
                        <p>Serão contempladas 20 revendas no total, sendo 1 (uma)  Revenda para cada Faixa e cada Região.</p>

                    </div>
                </div>
            </div>

            <h3>CANAIS DE COMUNICAÇÃO</h3>
            <div class="faq-group">
                <p class="pb-2 empty d-none">Nenhum resultado encontrado</p>
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-28')" href="javascript:void(0)">
                        <span>28.	Quais os canais de comunicação da campanha?</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-28" class="collapse">
                        <p class="faq-anwser">Em caso de dúvidas, escreva para o e-mail: sac@sistechpro.com.br ou entre em contato com o WhatsApp 11 93942-7843 para falar direto com a equipe do   SistechPro.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
